import UserType from 'models/User/UserTypeModel';
import { BaseUser } from 'redux/v3/baseUsers/types';

import { DeepPartial } from './types';

export const roleCheck = (
  user: DeepPartial<BaseUser>,
  role: UserType | UserType[],
  isExcludedByStuff?: boolean
) => {
  if (
    user.type === UserType.STAFF ||
    (Array.isArray(user.types) && user.types.includes(UserType.STAFF))
  ) {
    return !isExcludedByStuff;
  }

  if (Array.isArray(role)) {
    return role.some((r) => user.type === r || user.types?.includes(r));
  }

  return user.type === role || user.types?.includes(role);
};
