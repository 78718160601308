import React, { useState } from 'react';

import { BodyLBold } from 'styles/v3/variables';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { RequestStatus } from 'models/Request/RequestModel';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { MultiValue, SingleValue } from 'react-select';
import * as S from './styles';
import { FilterModalBodyProps, InvitationFiltersType } from './types';

export const FilterModalBody = ({ filters, handleChange }: FilterModalBodyProps) => {
  const genders = ['MALE', 'FEMALE', 'OTHER'];
  const membershipTypes = ['COMMUNITY', 'OFFICIAL'];
  const documentStatusTypes = Object.keys(RequestStatus);

  const ageDivisionOptions = () => {
    const SIZE = 30;
    const MIN_AGE = 5;

    const currentYear = new Date().getFullYear();

    return Array.from({ length: SIZE }, (_, i) => ({
      label: `${currentYear - i - MIN_AGE}`,
      value: currentYear - i - MIN_AGE,
    }));
  };

  const [selectedFilters, setSelectedFilters] = useState<InvitationFiltersType>(
    filters
  );

  const handleChangeMultiSelect = (
    type: keyof InvitationFiltersType,
    value: MultiValue<OptionsType> | SingleValue<OptionsType>
  ) => {
    if (Array.isArray(value)) {
      setSelectedFilters((prev) => ({
        ...prev,
        [type]: value.map((v) => v.value),
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleChange(selectedFilters);
  };

  return (
    <S.Container>
      <S.FiltersForm onSubmit={handleSubmit}>
        <BodyLBold className='title'>Description</BodyLBold>
        <Select
          placeholder='Gender'
          name='gender'
          isMulti
          options={genders.map((g) => ({ label: g, value: g }))}
          onChange={(e) => handleChangeMultiSelect('gender', e)}
          defaultValue={filters.gender.map((g) => ({ label: g, value: g }))}
        />
        <Select
          placeholder='Age'
          name='age'
          isMulti
          options={ageDivisionOptions()}
          onChange={(e) => handleChangeMultiSelect('age', e)}
          defaultValue={filters.age.map((g) => ({ label: g.toString(), value: g }))}
        />
        <S.HR />
        <BodyLBold className='title'>Management</BodyLBold>
        <Select
          placeholder='Membership type'
          name='membershipType'
          isMulti
          options={membershipTypes.map((g) => ({ label: g, value: g }))}
          onChange={(e) => handleChangeMultiSelect('membership', e)}
          defaultValue={filters.membership.map((g) => ({ label: g, value: g }))}
        />
        <Select
          placeholder='Document Status'
          name='documentStatus'
          isMulti
          options={documentStatusTypes.map((g) => ({ label: g, value: g }))}
          onChange={(e) => handleChangeMultiSelect('documentStatus', e)}
          defaultValue={filters.documentStatus.map((g) => ({ label: g, value: g }))}
        />
        <FilledButton className='btn-apply'>APPLY</FilledButton>
      </S.FiltersForm>
    </S.Container>
  );
};
