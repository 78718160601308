import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(16)};
  color: ${COLORS.grey500};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toRem(10)} ${toRem(30)} ${toRem(24)} ${toRem(30)};
`;

export const FooterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${toRem(10)};
`;

export const WarningPlayersOtherGender = styled.div`
  display: flex;
  padding: ${toRem(24)};
  justify-content: space-between;
  border-radius: ${toRem(24)};
  border: 1px solid ${COLORS.brandSecondary};
  background: rgba(151, 71, 255, 0.1);
`;

export const WarningPlayerTitle = styled.div`
  display: flex;
  column-gap: ${toRem(15)};
  .i-icon svg {
    width: ${toRem(32)};
    height: ${toRem(32)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.brandSecondary};
    }
    path[fill-rule='evenodd'] {
      fill: ${COLORS.brandSecondary};
    }
  }
`;

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px 0;
  background-color: #2f302b;
  border-radius: 12px;
  color: ${COLORS.grey400};
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px 0;

  .anticon {
    font-size: 32px !important; // Due to restrictions
  }
`;
