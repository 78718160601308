import { ClubView } from 'admin/models/club/Club';
import { Gender } from 'admin/models/Enums';
import { RequestStatus } from 'models/Request/RequestModel';
import UserType from 'models/User/UserTypeModel';

export const getReadableGender = (gender?: string) => {
  switch (gender) {
    case Gender.MALE:
      return 'Boy';
    case Gender.FEMALE:
      return 'Girl';
    default:
      return 'Other';
  }
};

export const getPermissionType = (
  club: ClubView,
  userId: string,
  userType?: UserType
) => {
  if (club.directorIds?.includes(userId)) {
    return UserType.CLUB_DIRECTOR;
  }

  if (club.coachIds?.includes(userId)) {
    return UserType.COACH;
  }

  if (club.teamManagers?.map((t) => t.userId).includes(userId)) {
    return UserType.TOURNAMENT_DIRECTOR;
  }

  if (club.playerIds?.includes(userId)) {
    return UserType.PLAYER;
  }

  return userType || UserType.PLAYER;
};

export const getDocumentStatus = (
  documentStatus?: RequestStatus,
  ageStatus?: RequestStatus,
  userType?: UserType
): RequestStatus => {
  if (!userType) {
    return RequestStatus.APPROVED;
  }

  if (userType === UserType.PLAYER) {
    return ageStatus || RequestStatus.DECLINED;
  }

  return documentStatus || RequestStatus.DECLINED;
};
