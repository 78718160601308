import React, { ChangeEvent, FC } from 'react';

import * as S from './styles';

interface IInputCheckboxProps {
  value?: string | number;
  name: string;
  id: string;
  checked?: boolean;
  disabled?: boolean;
  theme?: 'primary' | 'light';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputCheckbox: FC<IInputCheckboxProps> = ({
  id,
  theme = 'primary',
  disabled = false,
  ...props
}) => (
  <S.Container className={disabled ? 'disabled' : ''}>
    <S.Item>
      <S.CheckboxButton type='checkbox' id={id} $theme={theme} {...props} />
      <S.CheckboxButtonLabel htmlFor={id} $theme={theme} />
    </S.Item>
  </S.Container>
);

export default InputCheckbox;
