import React, { FC, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ClubService from 'services/v3/Clubs/ClubService';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';

import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { notification } from 'components/v3/Notification/notification';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';

import { BodyLBold } from 'styles/v3/variables';

import { DocumentsProps } from '../Documents/types';

import * as S from './styles';

interface IActionsProps extends DocumentsProps {
  onClose: () => void;
  onRemoveFromClub?: () => void;
}

export const Actions: FC<IActionsProps> = ({
  user,
  onClose,
  onRemoveFromClub,
}) => {
  const { clubId } = useParams<{ clubId: string }>();

  const { data: currentClub } = useGetClubById(clubId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userRoles = useMemo(() => {
    if (!currentClub || !user) {
      return {
        isCoach: false,
        isPlayer: false,
        isTeamManager: false,
        teamManagerId: user.id,
      };
    }

    const userIds = [user.id, ...(user.userIds || [])];

    const { playerIds = [], coachIds = [], teamManagers = [] } = currentClub;

    const foundManagers = teamManagers.filter((p) =>
      userIds.includes(p.userId)
    );

    return {
      isPlayer: playerIds.filter((p) => userIds.includes(p)).length > 0,
      isCoach: coachIds.filter((p) => userIds.includes(p)).length > 0,
      isTeamManager: foundManagers.length > 0,
      teamManagerId: foundManagers[0]?.userId || user.id,
    };
  }, [user, currentClub]);

  const handleRemovePlayer = async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        if (userRoles.isCoach) {
          await request('COACH', user.id);
        }
        if (userRoles.isPlayer) {
          await request('PLAYER', user.id);
        }
        if (userRoles.isTeamManager) {
          await request('TEAM_MANAGER', userRoles.teamManagerId);
        }

        setIsLoading(false);
        onClose();
      } catch (error) {
        setIsLoading(false);

        notification.error({
          duration: 2,
          message: 'Error',
          description: error || 'Something went wrong',
        });
      }
    }
  };

  const handleRemove = () => {
    confirm({
      message: 'Are you sure that you want to delete this user?',
      onOk: handleRemovePlayer,
    });
  };

  const request = async (
    userType: 'PLAYER' | 'COACH' | 'TEAM_MANAGER',
    id: string
  ) => {
    await ClubService.updateClubMembersPool(clubId, {
      userType,
      actionType: 'REMOVE',
      memberId: id,
    });

    onRemoveFromClub?.();
  };

  return (
    <S.ButtonWrapper>
      <BodyLBold className='title'>Actions</BodyLBold>
      <OutlinedButton isUpper color='white-dark' onClick={handleRemove}>
        Remove from club
      </OutlinedButton>
    </S.ButtonWrapper>
  );
};
