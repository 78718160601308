import React, {
  FC,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { Divider } from 'antd';
import { Attention } from '@icon-park/react';
import { debounce } from 'lodash';

import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';

import { Spinner } from 'components/v3/Spinner/Spinner';
import FilledButton from 'components/v3/Buttons/FilledButton';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';

import { BodyM, TitleH4 } from 'styles/v3/variables';

import { Filters } from './components/Filters/Filters';
import { AdvancedFilters } from './components/AdvancedFilters/AdvancedFilters';
import { UserInfoApply } from './components/UserInfoAppy';
import { ViewUsersSelected } from './components/ViewUsersSelected';

import { IFiltering } from './interfaces/filtering.interface';
import { SelectedModalState } from './enums/selected-modal-state.enum';

import * as S from './styles';

interface IRosterSidebarSelectionProps {
  userType: UserType;
  members: User[];
  isLoading: boolean;
  selectedUserIds: string[];
  isOtherPlayerGender: boolean;
  savedUserIds: string[];
  filtering: IFiltering;
  setFiltering: Dispatch<SetStateAction<IFiltering>>;
  setSelectedUserIds: Dispatch<SetStateAction<string[]>>;
  onUpdateUsers: () => Promise<void>;
  onMembersPoolFilter: (filtering: IFiltering) => void;
}

const RosterSidebarSelection: FC<IRosterSidebarSelectionProps> = ({
  userType,
  members,
  isLoading,
  selectedUserIds,
  isOtherPlayerGender,
  savedUserIds,
  filtering,
  setFiltering,
  setSelectedUserIds,
  onUpdateUsers,
  onMembersPoolFilter,
}) => {
  const [openSideModal, setOpenSideModal] = useState<SelectedModalState>(
    SelectedModalState.DEFAULT
  );

  const excludedSelectedMembers = useMemo(() => {
    return members.filter((member) => !savedUserIds.includes(member.id));
  }, [members, savedUserIds]);

  const selectedMembers = useMemo(() => {
    return members.filter((member) => selectedUserIds.includes(member.id));
  }, [members, selectedUserIds]);

  const handleSelected = useCallback(
    (memberId: string) => {
      if (selectedUserIds?.find((id) => id === memberId)) {
        const updateUserIds = selectedUserIds?.filter((id) => id !== memberId);

        setSelectedUserIds(updateUserIds);
      } else {
        setSelectedUserIds((prevState) => [...prevState, memberId]);
      }
    },
    [selectedUserIds, setSelectedUserIds]
  );

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setFiltering((prevState) => ({
        ...prevState,
        search: value,
      }));

      onMembersPoolFilter({
        ...filtering,
        search: value,
      });
    }, 300),
    [filtering]
  );

  const handleFilteringApply = useCallback(() => {
    setOpenSideModal(SelectedModalState.DEFAULT);

    onMembersPoolFilter(filtering);
  }, [filtering, onMembersPoolFilter]);

  return (
    <>
      {openSideModal === SelectedModalState.SELECTED && (
        <ViewUsersSelected
          users={selectedMembers}
          userType={userType}
          handleUpdateUsers={onUpdateUsers}
          handleSelected={handleSelected}
          handleGoBack={() => setOpenSideModal(SelectedModalState.DEFAULT)}
        />
      )}
      {openSideModal === SelectedModalState.FILTERS && (
        <AdvancedFilters
          filtering={filtering}
          setFiltering={setFiltering}
          onGoBack={() => {
            setOpenSideModal(SelectedModalState.DEFAULT);
          }}
          onFilteringApply={handleFilteringApply}
        />
      )}
      {openSideModal === SelectedModalState.DEFAULT && (
        <S.Container>
          <Filters
            defaultSearchValue={filtering.search}
            onSearchChange={handleSearchChange}
            onAdvancedFiltersOpen={() =>
              setOpenSideModal(SelectedModalState.FILTERS)
            }
          />
          {isOtherPlayerGender && (
            <S.WarningPlayersOtherGender>
              <S.WarningPlayerTitle>
                <Attention />
                <BodyM $color='white'>
                  Some players have selected an incorrect gender option. Ask
                  them to correct it to either Male or Female.
                </BodyM>
              </S.WarningPlayerTitle>
            </S.WarningPlayersOtherGender>
          )}
          {!isLoading &&
            excludedSelectedMembers.map((user) => (
              <UserInfoApply
                key={user.id}
                user={user}
                isUserSelected={
                  !!selectedUserIds.find(
                    (userSelected) => userSelected === user.id
                  )
                }
                handleSelected={handleSelected}
              />
            ))}
          {!isLoading && !excludedSelectedMembers.length && (
            <S.EmptyState>
              <BodyM>
                {userType === UserType.COACH && 'Coaches are not found'}
                {userType === UserType.PLAYER && 'Players are not found'}
              </BodyM>
            </S.EmptyState>
          )}
          {isLoading && (
            <S.Loading>
              <Spinner />
            </S.Loading>
          )}
          <Divider style={{ margin: 0, marginTop: 100 }} />
          <S.Footer>
            <S.FooterTitle>
              <TitleH4>
                {
                  members.filter((member) =>
                    selectedUserIds.includes(member.id)
                  ).length
                }{' '}
                {userType} selected
              </TitleH4>
              <TextButtonWithIcon
                customIcon='Right'
                color='grey400'
                align='flex-end'
                isUpper
                isBold={false}
                onClick={() => setOpenSideModal(SelectedModalState.SELECTED)}
              >
                {userType === UserType.COACH ? 'View Coaches' : 'View players'}
              </TextButtonWithIcon>
            </S.FooterTitle>
            <FilledButton onClick={onUpdateUsers} isUpper isBold={false}>
              {userType === UserType.COACH
                ? 'Add Coaches To Team'
                : 'Add Players To Roster'}
            </FilledButton>
          </S.Footer>
        </S.Container>
      )}
    </>
  );
};

export default RosterSidebarSelection;
