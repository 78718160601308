import React, { FC, useState, useEffect, useMemo } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, LocalTwo, RightSmallUp, Close } from '@icon-park/react';
import { useQueryClient } from '@tanstack/react-query';

import {
  EventInvitesStatus,
  EventInvitesTypes,
} from 'models/v3/EventInvite/EventInvitesModel';
import { RequestStatus } from 'models/Request/RequestModel';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { ApplicationState } from 'redux/store';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import { AllEventInvites } from 'services/v3/Event/types';
import EventService from 'services/v3/Event/EventService';

import { notification } from 'components/v3/Notification/notification';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import {
  PaymentAndValueProps,
  PaymentAndValue,
} from 'components/v3/Cards/PaymentAndValue';

import { useGetMyRefereeApplications } from 'hooks/v3/referees/useGetMyRefereeApplications/useGetMyRefereeApplications';
import { useGetEventInvites } from 'hooks/v3/event/useGetEventInvites/useGetEventInvites';
import { useProfile } from 'hooks/v3/profile/useProfile';

import ConvertUtil from 'util/ConvertUtil';
import ResponseUtil from 'util/ResponseUtil';

import {
  TitleH1,
  TitleH4,
  BodyXL,
  BodyL,
  BodyM,
  BodyS,
} from 'styles/v3/variables';

import moment from 'moment';
import useGetVenues from 'hooks/v3/event/useGetVenues/useGetVenues';
import Loading from 'components/v3/Loading/Loading';
import { InformationScreenProps } from './InformationScreen';

import {
  RefereeApplicationContainer,
  RefereeApplicationConditionsContainer,
  ContainerInformationCondition,
  EventInfoContainer,
  EventInformationAndImage,
  EventImage,
  GeneralInfoContainer,
  AgreeConditionsContainer,
  RefereeUploadSafeSportContainer,
  InvitesButtonsWrapper,
} from './styles';

const RefereeApplication: FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { eventData, loading } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const searchParams = new URLSearchParams(location.search);

  const { updateCurrentAccount, isAnAdult, currentUser } = useProfile();

  const [acceptTerms, setAcceptTerms] = useState(false);

  const { data: myRefereeApplications } = useGetMyRefereeApplications(
    eventId
  );

  const { data: venuesData } = useGetVenues(eventId);

  const invite = searchParams.get('invite') ?? '';

  const { data: eventInvites } = useGetEventInvites(
    eventId,
    EventInvitesTypes.EVENT_TO_REFEREE,
  );

  const lastApplications = useMemo(() => {
    if (!myRefereeApplications?.length) return undefined;

    const sorted = [...myRefereeApplications];

    sorted.sort((a, b) => {
      return (
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      );
    });

    return sorted[0];
  }, [myRefereeApplications]);

  const alreadyApplied = useMemo(() => {
    return (
      lastApplications &&
      ![RequestStatus.DECLINED, RequestStatus.EXPIRED].includes(
        lastApplications.status
      )
    );
  }, [lastApplications]);

  const myInvite = useMemo<AllEventInvites | undefined>(() => {
    if (!invite || !eventInvites?.length) return undefined;

    return eventInvites.find(
      (i) =>
        i?.invite?.status !== EventInvitesStatus.DENIED &&
        (currentUser.id === i.invite.receiverId ||
          currentUser?.userIds?.includes(i.invite.receiverId))
    );
  }, [invite, eventInvites, currentUser]);

  const hasSafesPortUploaded = useMemo(() => {
    return !!currentUser?.extraInfo?.certificate;
  }, [currentUser]);

  const { logo, name, description, startDate, endDate, data } = eventData;

  const startAt = moment.utc(startDate as string).format('MMM DD yy');
  const endAt = moment.utc(endDate as string).format('MMM DD yy');

  // TODO Temporary only 1st venue usable. Will change after multi venues for events implemented
  const venueInfo =
    venuesData && venuesData[0]
      ? `${venuesData[0]?.address?.country} ${venuesData[0]?.address?.city}`
      : '';

  const paymentsAndValues: PaymentAndValueProps[] = [
    {
      title: 'Single Referee',
      value: Number(data?.generalRules?.refereeConditions?.refereeSolo) ?? 0,
    },
    {
      title: 'Referee Pair',
      value: Number(data?.generalRules?.refereeConditions?.refereePair) ?? 0,
    },
    {
      title: 'Score Keeper',
      value: Number(data?.generalRules?.refereeConditions?.scoreKeeper) ?? 0,
    },
  ];

  const handleDenyInvite = () => {
    if (myInvite?.invite?.id) {
      EventService.denyInvite(myInvite.invite.id).then(() => {
        queryClient.invalidateQueries([
          'event-invites',
          eventId,
          EventInvitesTypes.EVENT_TO_REFEREE,
        ]).then(() =>  history.push('/v3/home'));
      });
    }
  };

  const handleApplyToThisEvent = async () => {
    B2bEventService.postRefereeApplication(eventId)
      .then(() => {
        const stateToInformation: InformationScreenProps = {
          title: 'Your application is now pending.',
          description:
            'The event manager will review your application soon. With any updates, we will notify you.',
          eventIcon: logo ?? '',
          eventId,
        };
        updateCurrentAccount();
        history.push('/v3/referee-application-status', stateToInformation);
      })
      .catch((err) => {
        if (err) {
          const formattedErrors = ResponseUtil.formatInvalidRequestResponse(
            err
          );
          const invalidFields = formattedErrors.details.map((f) => f.field);
          const desc = invalidFields.length
            ? `Invalid fields: ${invalidFields.join(', ')}`
            : '';
          notification.error({
            message:
              'Please complete your profile before applying as a referee',
            description: desc,
          });
        } else {
          notification.error({
            message: 'Error applying as a referee, please try again',
          });
        }
      });
  };

  const handleUploadSafeSport = () => {
    history.push(`/v3/user/${currentUser.id}/documents?type=referee`);
  };

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(eventId));
  }, [dispatch]);

  useEffect(() => {
    const allowRefereesToSignUp =
      data?.generalRules?.refereeConditions?.allowRefereesToSignUp;

    if (data && !myInvite && !allowRefereesToSignUp) {
      history.push(`/v3/event/${eventId}`);
    }
  }, [data, myInvite, eventId]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <RefereeApplicationContainer>
          <RefereeApplicationConditionsContainer>
            <TextButtonWithIcon
              color='light'
              align='flex-end'
              reverse
              icon='back'
              onClick={() => history.replace(`/v3/event/${eventId}${history.location.search ?? ''}`)}
            >
              Go back
            </TextButtonWithIcon>
            <TitleH1 $color='brandPrimary'>Conditions</TitleH1>
            <ContainerInformationCondition>
              <BodyXL>Pay per Game</BodyXL>
              <BodyM $color='grey400'>
                Every payment will be done by the Event Manager.
              </BodyM>
              {paymentsAndValues.map((payment) => (
                <PaymentAndValue
                  key={`key_payment_${payment.title}`}
                  title={payment.title}
                  value={payment.value}
                />
              ))}
              {data?.generalRules?.refereeConditions?.requirements && (
                <>
                  <BodyXL>Requirements</BodyXL>
                  <BodyM $color='grey400'>
                    {data?.generalRules?.refereeConditions?.requirements}
                  </BodyM>
                </>
              )}
              {data?.generalRules?.refereeConditions?.travelConditions && (
                <>
                  <BodyXL>Travel Conditions</BodyXL>
                  <BodyM $color='grey400'>
                    {data?.generalRules?.refereeConditions?.travelConditions}
                  </BodyM>
                </>
              )}
            </ContainerInformationCondition>
          </RefereeApplicationConditionsContainer>
          <EventInfoContainer>
            <EventInformationAndImage>
              <EventImage>
                <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
              </EventImage>
              <TitleH4>{name}</TitleH4>
              <BodyS $color='grey300'>{description}</BodyS>
            </EventInformationAndImage>
            <GeneralInfoContainer>
              <BodyL $isUpper>General Info</BodyL>
              <BodyM $isUpper style={{ display: 'flex', alignItems: 'center' }}>
                <Calendar /> {startAt} - {endAt}
              </BodyM>
              <BodyM $isUpper style={{ display: 'flex', alignItems: 'center' }}>
                <LocalTwo />
                <div>{venueInfo}</div>
              </BodyM>
            </GeneralInfoContainer>
            {alreadyApplied ? (
              <BodyL $color='brandPrimary'>
                You already applied to this event.
              </BodyL>
            ) : (
              <>
                <AgreeConditionsContainer>
                  <InputCheckbox
                    id='accept_terms'
                    name='accept_terms'
                    checked={acceptTerms}
                    onChange={() => setAcceptTerms(!acceptTerms)}
                  />
                  <BodyM $color='grey100'>
                    I agree with the conditions provided for being a referee in
                    this event.
                  </BodyM>
                </AgreeConditionsContainer>
                {myInvite ? (
                  <>
                    <FilledButtonWithIcon
                      isUpper
                      onClick={handleApplyToThisEvent}
                      color={!acceptTerms ? 'white-dark' : 'primary'}
                      disabled={!acceptTerms || (!hasSafesPortUploaded && isAnAdult)}
                    >
                      ACCEPT INVITE
                    </FilledButtonWithIcon>
                    <FilledButtonWithIcon
                      customIcon={<Close />}
                      className='btn-deny-invite'
                      color='dark-white'
                      isUpper
                      onClick={handleDenyInvite}
                    >
                      DENY INVITE
                    </FilledButtonWithIcon>
                  </>
                ) : (
                  <InvitesButtonsWrapper>
                    <FilledButtonWithIcon
                      disabled={!acceptTerms || (!hasSafesPortUploaded && isAnAdult)}
                      color={!acceptTerms ? 'white-dark' : 'primary'}
                      isUpper
                      onClick={handleApplyToThisEvent}
                    >
                      Apply as Referee
                    </FilledButtonWithIcon>
                  </InvitesButtonsWrapper>
                )}
              </>
            )}
            {!hasSafesPortUploaded && isAnAdult && (
              <RefereeUploadSafeSportContainer>
                <BodyL $color='supportError'>
                  To apply as a Referee, you need to upload your SafeSport.
                </BodyL>
                <OutlinedButtonWithIcon
                  customIcon={<RightSmallUp />}
                  color='white-dark'
                  isUpper
                  onClick={handleUploadSafeSport}
                >
                  Upload Safesport
                </OutlinedButtonWithIcon>
              </RefereeUploadSafeSportContainer>
            )}
            {!alreadyApplied && !isAnAdult && (
              <BodyL>
                To apply to this event, your age must be 13 years or older
              </BodyL>
            )}
          </EventInfoContainer>
        </RefereeApplicationContainer>
      )}
    </>
  );
};

export default withRouter(RefereeApplication);
