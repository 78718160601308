import React, { useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';

import Loading from 'components/v3/Loading/Loading';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import useScreenType from 'hooks/useScreenType';
import { EventHeader, EventMain } from './components';

import * as S from './styles';

const Event = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const history = useHistory();
  const { isMobile } = useScreenType();

  const { data: response, isLoading } = useGetEvent(eventId);

  const handleGoBack = () => {
    const routerPath = history.location.search;
    if (!routerPath || routerPath?.includes('created-by-me'))
      history.push('/v3/events?tab=discover-events');
    else if (routerPath.includes('my-events')) {
      history.push('/v3/events?tab=my-events');
    } else if (routerPath?.includes('?tab=home')) {
      history.push('/v3/home-page');
    }
  };

  useEffect(() => {
    if (!eventId) {
      history.goBack();
    }
  }, [history, eventId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {!isMobile && (
        <TextButtonWithIcon
          reverse
          icon='back'
          color='light'
          align='flex-end'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
      )}
      <S.Container>
        <EventHeader
          logoSrc={response?.data.logo}
          backgroundSrc={response?.data.data?.eventInfo?.header}
        />
        <EventMain event={response?.data} />
      </S.Container>
    </>
  );
};

export default withRouter(Event);
