export enum LayoutsName {
  Default = 'default',
  Base = 'base',
  Print = 'print',
  EditEvent = 'editEvent',
  DraftEvent = 'draftEvent',
  SetupEvent = 'setupEvent',
  Profile = 'profile',
  NoMenu = 'no-menu',
  MenuEmpty = 'menu-empty',
  SidebarModal = 'sidebar-modal',
  EventDashboard = 'eventDashboard',
  BaseDesktop = 'base-desktop',
  Admin = 'admin',
  AdminNone = 'admin-none',
  None = 'none',
  Error404 = 'error-404',
}
