import { AgeRulesEnums } from 'pages/V3/setupEvent/Divisions/constants';

import { IFiltering } from './components/Modal/RosterSidebarSelection/interfaces/filtering.interface';

export const AGE_RULES_VALUES = {
  [AgeRulesEnums.EQUAL]: 0,
  [AgeRulesEnums.YOUNGER]: 5, // + 5 years
  [AgeRulesEnums.YOUNGER_WITH_MORE_ONE_YEAR]: 1, // + 1 years
  [AgeRulesEnums.YOUNGER_WITH_MORE_TWO_YEAR]: 2, // + 2 years
};

export const INITIAL_FILTRATING: IFiltering = {
  gender: 'default',
  birthYear: 'default',
  membershipType: 'default',
  documentStatus: 'default',
  search: '',
};
