import moment from 'moment';

import { RequestStatus } from 'models/Request/RequestModel';
import UserType from 'models/User/UserTypeModel';

import { BaseUser } from 'redux/v3/baseUsers/types';

import DateUtil from 'util/DateUtil';
import { DeepPartial } from 'util/types';

import { AGE_RULES } from './configs';

export const validateMissingUserInfo = (
  user?: DeepPartial<BaseUser>,
  isAnAdult?: boolean,
): string[] => {
  const errors: string[] = [];

  if (!user) {
    return [];
  }

  const userTypes = () => {
    return [...(user.types || []), user.type || []];
  };

  const add = (value: string) => {
    if (!errors.includes(value)) {
      errors.push(value);
    }
  };

  const checkLocalAgePermission = (role: UserType) => {
    const birthDate = moment().diff(moment(user.birthDate as string, 'YYYY-MM-DD'), 'years');

    return birthDate >= AGE_RULES[role];
  }

  // Photo validation
  if (!user.photo) {
    add('Photo is not uploaded');
  }

  if (user.photoVerified !== RequestStatus.APPROVED) {
    add('Photo is not verified');
  }

  // Frist/Last name validation
  if (!user.firstName || user.firstName.length < 3) {
    add('First Name too short');
  }

  if (!user.lastName || user.lastName.length < 3) {
    add('Last Name too short');
  }

  // Safe sport document validation
  if (user.safesportValidUntil && user.extraInfo?.certificate) {
    if (DateUtil.isExpired(user.safesportValidUntil)) {
      add('SafeSport document is expired');
    }
  }

  // Age document validation
  if (!user.ageProof) {
    add('Age document is not uploaded');
  }

  if (
    userTypes().includes(UserType.PLAYER) &&
    user.ageVerified !== RequestStatus.APPROVED
  ) {
    add('Age document is not verified');
  }

  // SafeSport document validation
  if (
    userTypes().includes(UserType.REFEREE) ||
    userTypes().includes(UserType.COACH)
  ) {
    if (user.documentationVerified !== RequestStatus.APPROVED) {
      add('SafeSport document is not verified');
    }
  }

  // Age validation
  if (userTypes().includes(UserType.REFEREE) && !checkLocalAgePermission(UserType.REFEREE)) {
    add(`You must be at least ${AGE_RULES[UserType.REFEREE]} years old to be eligible for the role of Referee`);
  }

  if (userTypes().includes(UserType.CLUB_DIRECTOR) && !checkLocalAgePermission(UserType.CLUB_DIRECTOR)) {
    add(`You must be at least ${AGE_RULES[UserType.CLUB_DIRECTOR]} years old to be eligible for the role of Club Director`);
  }

  if (userTypes().includes(UserType.TEAM_MANAGER) && !checkLocalAgePermission(UserType.TEAM_MANAGER)) {
    add(`You must be at least ${AGE_RULES[UserType.TEAM_MANAGER]} years old to be eligible for the role of Team Manager`);
  }

  if (userTypes().includes(UserType.COACH) && !checkLocalAgePermission(UserType.COACH)) {
    add(`You must be at least ${AGE_RULES[UserType.COACH]} years old to be eligible for the role of Coach`);
  }

  // Address validation
  if (isAnAdult && !user.address) {
    add('Address is not completed');
  }

  // Legal Guardian validation
  if (!isAnAdult) {
    const guardian = user.guardian || user.guardian1;

    if (!guardian?.address) {
      add('Incomplete guardian address');
    }

    if (!guardian?.firstName || !guardian?.lastName) {
      add('Incomplete guardian name');
    }
  }

  return errors;
};
