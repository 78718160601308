import { Tooltip } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import RosterService from '../../../services/Rosters/RosterService';
import RosterServiceV3 from '../../../services/v3/Rosters/RosterService';
import { color } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';

interface IAppProps {
  user: any;
  isLocked?: boolean;
  isPlayer?: boolean;
  missingMembership?: boolean;
}

const RosterEditPlayerRow: React.FunctionComponent<IAppProps> = (props) => {
  const { user, isPlayer, isLocked, missingMembership } = props;
  const routeParams: { rosterId: string } = useParams();
  const [errorsJerseyNumber, setErrorsJerseyNumber] = React.useState({
    error: false,
    message: '',
  });
  const queryClient = useQueryClient();

  const removePlayer = async (idPlayer: string) => {
    try {
      await RosterServiceV3.removePlayer(routeParams.rosterId, idPlayer);
      await queryClient.invalidateQueries([
        '/get-roster-detailed-id',
        routeParams.rosterId,
      ]);
    } catch (e) {
      console.warn(e);
    }
  };

  const updateJerseyNumber = (event: any) => {
    let eventValue = event.target.value.replace(/[^\d]/, '');

    const val = eventValue;
    const max = 999;
    if (val > max) {
      eventValue = '';
      setErrorsJerseyNumber({ error: true, message: 'Max number is 999' });
      return;
    }

    RosterService.updatePlayerNumber({
      rosterId: routeParams.rosterId,
      playerId: event.target.getAttribute('data-playerid') || '',
      number: Number(eventValue),
    }).then((response) => {
      if (!response.success) {
        setErrorsJerseyNumber({
          error: true,
          message: response.message || 'Error',
        });
      } else {
        setErrorsJerseyNumber({ error: false, message: '' });
        queryClient.invalidateQueries(['/get-roster-detailed-id']);
      }
    });
  };

  const defaultNumber = React.useMemo(() => {
    if (!user?.number) return undefined;

    return user?.number;
  }, [user?.number]);

  return (
    <Row key={user.id}>
      {missingMembership ? (
        <Tooltip
          placement='topLeft'
          title={() => (
            <>
              <div>
                <b>Membership</b> doesn&lsquo;t match the requirements
              </div>
            </>
          )}
        >
          <div
            className='photo'
            style={{
              backgroundImage: `url(${ConvertUtil.getMinioUrl(
                user.photo,
                100
              )})`,
            }}
          >
            <div className='notification-circle'>!</div>
          </div>
        </Tooltip>
      ) : (
        <div
          className='photo'
          style={{
            backgroundImage: `url(${ConvertUtil.getMinioUrl(user.photo, 100)})`,
          }}
        />
      )}

      <div className='name'>{`${user.firstName} ${user.lastName}`}</div>

      <div className='year'>
        {isPlayer && new Date(user.birthDate).getUTCFullYear()}
      </div>
      <div className='number'>
        {isPlayer && (
          <Tooltip
            placement='left'
            title={errorsJerseyNumber.message}
            visible={errorsJerseyNumber.error}
            key={`tooltip-${user.id}-${defaultNumber}`}
          >
            <input
              disabled={isLocked}
              readOnly={isLocked}
              tabIndex={0}
              className={errorsJerseyNumber.error ? 'error' : ''}
              placeholder='#'
              type='number'
              min={0}
              max={999}
              data-playerid={user.id}
              defaultValue={defaultNumber ?? user.number}
              onBlur={updateJerseyNumber}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  updateJerseyNumber(event);
                }
              }}
            />
          </Tooltip>
        )}
      </div>
      {!isLocked && (
        <div
          role='button'
          tabIndex={-1}
          className='action'
          onClick={() => removePlayer(user.id)}
          onKeyDown={() => removePlayer(user.id)}
        >
          <div className='bt-action i-icon-trash' />
        </div>
      )}
    </Row>
  );
};

const Row = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 1fr 0.5fr 0.5fr;
  align-items: center;
  margin: 10px 0;
  font-weight: 600;
  .photo {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 20px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    position: relative;
    .notification-circle {
      position: absolute;
      top: 40px;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      font-size: 8px;
      text-align: center;
      background-color: #ffd904;
      top: 30px;
      left: 25px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      color: #000;
      font-weight: 700;
    }
  }
  .name,
  .year {
    padding: 0 10px;
  }
  .year {
    text-align: center;
  }
  .number {
    input {
      width: 80px;
      border: none;
      padding: 5px 10px;
      background-color: ${color.greyDark};
      border-radius: 5px;

      &.error {
        border: 1px solid ${color.orange};
      }
    }
  }
  .action {
    margin-left: auto;
  }
`;

export default RosterEditPlayerRow;
