import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Camera, IdCardH, IdCardV, PreviewOpen } from '@icon-park/react';

import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { RequestStatus } from 'models/Request/RequestModel';
import { RosterStatus } from 'services/v3/Rosters/enums';

import { useUpdatePlayerNumber } from 'hooks/v3/rosters/useUpdatePlayerNumber/useUpdatePlayerNumber';
import { useUpdateEditedRosterStatus } from 'hooks/v3/rosters/useUpdateEditedRosterStatus/useUpdateEditedRosterStatus';
import { AGE_RULES } from 'hooks/v3/profile/useProfile/configs';

import {
  IUserRosterError,
  UserWithJersey,
} from 'pages/V3/Roster/RosterEditPage/types';
import IconButton from 'components/v3/Buttons/IconButton';
import Logo from 'components/v3/Elements/Logo/Logo';

import ConvertUtil from 'util/ConvertUtil';

import { BodyXL, BodyM, COLORS, BodyS } from 'styles/v3/variables';

import { ErrorTooltip } from '../../ErrorTooltip/ErrorTooltip';

import {
  ContainerUser,
  UserInformationContent,
  UserIconName,
  UserImage,
  UserMemberType,
  UserDocumentsAndMore,
  UserDocuments,
  UserDocument,
  JerseyField,
} from './styles';

interface IPlayerOfCoachInformationProps {
  user: UserWithJersey;
  rosterId?: Maybe<string>;
  error?: IUserRosterError;
  isLockedRoster: boolean;
  rosterStatus?: RosterStatus;
  handleOpenUserInfoModal: (user: User) => void;
}

export const PlayerOfCoachInformation: FC<IPlayerOfCoachInformationProps> = ({
  user,
  rosterId,
  error,
  isLockedRoster,
  rosterStatus,
  handleOpenUserInfoModal,
}) => {
  const {
    mutateAsync: updatePlayerNumber,
    isLoading,
  } = useUpdatePlayerNumber();
  const {
    mutateAsync: updateEditedRosterStatus,
  } = useUpdateEditedRosterStatus();

  const [jerseyValue, setJerseyValue] = useState<Maybe<number>>(null);

  const errorMessages = useMemo(() => {
    const messages = [];

    const userRole = user.type === UserType.PLAYER ? 'Player' : 'Coach';

    if (error?.documents) {
      messages.push(
        `Every ${userRole} has submitted their required documents, and their documents have been approved`
      );
    }

    if (error?.jerseyNumber) {
      messages.push('Every Player has a jersey number');
    }

    if (error?.officialMembership) {
      messages.push(`Every ${userRole} has an Official Membership`);
    }

    if (error?.ageRule && user.type === UserType.COACH) {
      messages.push(
        `Every coach must be ${AGE_RULES.COACH} years old or older`
      );
    }

    return messages;
  }, [error]);

  const getDocColorByStatus = useCallback((status?: RequestStatus) => {
    switch (status) {
      case RequestStatus.APPROVED:
        return COLORS.green;
      case RequestStatus.PENDING:
        return '#67e4ff';
      case RequestStatus.DECLINED:
      case RequestStatus.EXPIRED:
        return COLORS.supportError;
      default:
        return COLORS.grey400;
    }
  }, []);

  const documents = useMemo(
    () => [
      {
        icon: Camera,
        text: 'PH',
        excludeRoles: [],
        color: getDocColorByStatus(user.photoVerified),
      },
      {
        icon: IdCardH,
        text: 'AV',
        excludeRoles: [
          UserType.CLUB_DIRECTOR,
          UserType.COACH,
          UserType.REFEREE,
          UserType.STAFF,
          UserType.TEAM_MANAGER,
          UserType.TOURNAMENT_DIRECTOR,
        ],
        color: getDocColorByStatus(user.ageVerified),
      },
      {
        icon: IdCardV,
        text: 'SS',
        excludeRoles: [UserType.PLAYER],
        color: getDocColorByStatus(user.documentationVerified),
      },
    ],
    [user, getDocColorByStatus]
  );

  const handleChangeJerseyNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const sanitizedValue = value.replace(/[^0-9]/g, '');

      setJerseyValue(Number(sanitizedValue));
    },
    []
  );

  const handleStoreJerseyNumber = useCallback(() => {
    if (
      rosterId &&
      !isLoading &&
      user.jersey !== jerseyValue &&
      jerseyValue !== null
    ) {
      updatePlayerNumber({
        number: jerseyValue,
        playerId: user.id,
        rosterId,
      })
        .then(() => {
          if (rosterStatus === RosterStatus.Submitted) {
            updateEditedRosterStatus(rosterId);
          }
        })
        .catch(() => {
          setJerseyValue(user.jersey ?? null);
        });
    }

    if (user.jersey === jerseyValue) {
      setJerseyValue(user.jersey);
    }
  }, [rosterId, rosterStatus, jerseyValue, isLoading, user.jersey]);

  useEffect(() => {
    setJerseyValue(user.jersey ?? null);
  }, [user.jersey]);

  return (
    <ContainerUser>
      <UserInformationContent>
        <UserIconName>
          <UserImage>
            <img
              src={ConvertUtil.getMinioUrl(user.photo)}
              alt='User Profile pic'
            />
          </UserImage>
          <BodyXL>
            {user.firstName} {user.lastName}
          </BodyXL>
        </UserIconName>
        <UserMemberType>
          <Logo
            width={20}
            height={20}
            minimal
            color={
              user.membership?.type === 'OFFICIAL' ? 'brandPrimary' : 'grey600'
            }
          />
          <BodyM
            $color={user.membership?.type === 'OFFICIAL' ? 'grey50' : 'grey300'}
          >
            {user.membership?.type === 'OFFICIAL'
              ? 'Official Member'
              : 'Community Member'}
          </BodyM>
        </UserMemberType>
        <ErrorTooltip
          visible={!!errorMessages.length}
          textContents={errorMessages}
        />
      </UserInformationContent>
      <UserDocumentsAndMore>
        <UserDocuments>
          {documents.map((document) => {
            const Icon = document.icon;

            if (user.type && document.excludeRoles.includes(user.type)) {
              return null;
            }

            return (
              <UserDocument>
                <Icon style={{ color: document.color }} />
                <BodyS>{document.text}</BodyS>
              </UserDocument>
            );
          })}
        </UserDocuments>
        {user.type === UserType.PLAYER && (
          <JerseyField
            id='jersey'
            label='Jersey'
            placeholder='Jersey'
            value={jerseyValue ?? ''}
            disabled={isLoading || isLockedRoster}
            error={error?.jerseyNumber}
            onChange={handleChangeJerseyNumber}
            onBlur={handleStoreJerseyNumber}
          />
        )}
        <IconButton
          icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: COLORS.grey900 }}
          onClick={() => handleOpenUserInfoModal(user)}
        />
      </UserDocumentsAndMore>
    </ContainerUser>
  );
};
