import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Attention } from '@icon-park/react';

import { useProfile } from 'hooks/v3/profile/useProfile';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { BodyM, TitleH4 } from 'styles/v3/variables';

import * as S from './styles';

export const AccountStatus: FC = () => {
  const { missingInformation, currentUser } = useProfile();

  const history = useHistory();

  const handleCompleteAccount = () => {
    history.push(`/v3/user/${currentUser?.id}/info`);
  };

  return (
    <S.Container>
      <S.Box>
        <S.TitleWrapper>
          <Attention />
          <TitleH4>COMPLETE YOUR ACCOUNT</TitleH4>
        </S.TitleWrapper>
        <S.ListItems>
          {missingInformation.map((item) => (
            <BodyM key={item}>- {item}</BodyM>
          ))}
        </S.ListItems>
        <BodyM>
          To be a part of an event, you need to complete your account.{' '}
        </BodyM>
      </S.Box>
      <FilledButton
        onClick={handleCompleteAccount}
        color='secondary'
        className='btn-complete'
      >
        COMPLETE NOW
      </FilledButton>
    </S.Container>
  );
};
