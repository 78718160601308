import React, { memo, FC, useCallback, useMemo } from 'react';

import { GameSource } from 'models/v3/Games/game-source.enum';
import { IMatchEventList } from 'services/v3/Match/interfaces';

import { getTableContentData } from 'pages/V3/EventManager/components/Games/adapters/getTableContentData';
import { useFormTableColums } from 'pages/V3/EventManager/components/Games/hooks/useFormTableColums';

import { Table } from 'components/v3/Table/Table';
import InputSelect, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputSearch from 'components/v3/Forms/InputSearch/InputSearch';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { BodyXLBold } from 'styles/v3/variables';

import * as S from './styles';

interface ITableContentProps {
  source: GameSource;
  type: string;
  matches: any[];
  searchText: string;
  total?: number;
  current: number;
  divisions: OptionsType[];
  selectedDivision: OptionsType | undefined;
  eventId: string;
  onSourceChange: (val: GameSource) => void;
  onPageChange: (pageToFetch: number) => void;
  onFilterByDivision: (selectedDivision: any) => void;
  onSearchChange: (searchText: string) => void;
  onFiltersReset: () => void;
}

export const TableContent: FC<ITableContentProps> = memo(
  ({
    source,
    type,
    searchText,
    matches,
    total,
    current,
    divisions,
    selectedDivision,
    eventId,
    onSourceChange,
    onPageChange,
    onFilterByDivision,
    onSearchChange,
    onFiltersReset,
  }) => {
    const currentSource = useMemo(
      () =>
        source === GameSource.BRACKET || source === GameSource.CONSOLATION
          ? 'BRACKET'
          : 'POOL',
      [source]
    );

    const filteredMatches = useMemo(() => {
      if (!type) {
        return matches.filter(
          (match: IMatchEventList) => match.sourceType === currentSource
        );
      }

      return matches?.filter(
        (match: IMatchEventList) =>
          match.status === type && match.sourceType === currentSource
      );
    }, [type, matches, currentSource]);

    const handleDivisionFilter = useCallback(
      (selectedDivision) => {
        onFilterByDivision(selectedDivision);
      },
      [onFilterByDivision]
    );

    const handleSearchChange = useCallback(
      ({ target }) => {
        onSearchChange(target?.value);
      },
      [onSearchChange]
    );

    const tableContentData = getTableContentData(filteredMatches);
    const columns = useFormTableColums({ eventId });

    return (
      <S.StyledWrapper>
        <S.StyledHeader>
          {/* ENG-145 Mb in annother component - HEADER */}
          <BodyXLBold
            onClick={() => onSourceChange(GameSource.POOL)}
            style={{
              color: source === GameSource.POOL ? '#CAFC01' : '#D3D4D0',
            }}
          >
            Pool Games
          </BodyXLBold>
          <BodyXLBold
            onClick={() => onSourceChange(GameSource.BRACKET)}
            style={{
              color: source === GameSource.BRACKET ? '#CAFC01' : '#D3D4D0',
            }}
          >
            Bracket Games
          </BodyXLBold>
        </S.StyledHeader>
        <S.FiltersWrapper>
          <InputSelect
            id='select'
            placeholder='Select Age Division'
            options={divisions}
            hideSelectedOptions={false}
            value={selectedDivision}
            onChange={handleDivisionFilter}
          />
          <InputSearch
            id='search'
            value={searchText}
            onChange={handleSearchChange}
          />
          <FilledButton color='white-dark' onClick={onFiltersReset}>
            Clear
          </FilledButton>
        </S.FiltersWrapper>
        {!!total && (
          <S.TableWrapper>
            {/* ENG-145 Mb in annother component - Game Card */}
            <Table
              scroll={{ x: '50%' }}
              columns={columns}
              dataSource={tableContentData}
              pagination={{
                position: ['bottomCenter'],
                total,
                current,
                onChange: onPageChange,
              }}
            />
          </S.TableWrapper>
        )}
      </S.StyledWrapper>
    );
  }
);

TableContent.displayName = 'TableContent';
