import UserType from 'models/User/UserTypeModel';

import { Gender } from 'admin/models/Enums';

import { OptionsType } from 'components/v3/Forms/Select/Select';

export const USER_ROLES_OPTIONS: OptionsType[] = [
  {
    label: 'Player',
    value: UserType.PLAYER,
  },
  {
    label: 'Coach',
    value: UserType.COACH,
  },
  {
    label: 'Club Director',
    value: UserType.CLUB_DIRECTOR,
  },
  {
    label: 'Referee',
    value: UserType.REFEREE,
  },
  {
    label: 'Team manager',
    value: UserType.TEAM_MANAGER,
  },
];

export const GENDER_OPTIONS: OptionsType[] = [
  {
    label: 'Male',
    value: Gender.MALE,
  },
  {
    label: 'Female',
    value: Gender.FEMALE,
  },
];
