import React, { FC } from 'react';
import { Filter as FilterIcon } from '@icon-park/react';

import InputText from 'components/v3/Forms/InputText/InputText';

import { BodyMBold } from 'styles/v3/variables';

import * as S from './styles';

interface IFiltersProps {
  defaultSearchValue?: string;
  onSearchChange: (value: string) => void;
  onAdvancedFiltersOpen: () => void;
}

export const Filters: FC<IFiltersProps> = ({
  defaultSearchValue = '',
  onSearchChange,
  onAdvancedFiltersOpen,
}) => {
  return (
    <S.Container>
      <S.FilterWrapper onClick={onAdvancedFiltersOpen}>
        <BodyMBold $isUpper $color='grey400'>
          Filters
        </BodyMBold>
        <FilterIcon />
      </S.FilterWrapper>
      <InputText
        id='search'
        placeholder='Search'
        icon='Search'
        defaultValue={defaultSearchValue}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </S.Container>
  );
};
