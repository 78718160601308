import React, { useEffect, useMemo, useState, useCallback, FC } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  Plus as PlusIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@icon-park/react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { Country, State } from 'country-state-city';

import VenueServices from 'services/Venue/VenueApi';
import {
  sideModalHasBackButton,
  sideModalSetTitle,
  sideModalCloseModal,
} from 'redux/sideModal/actions';

import FilledButton from 'components/v3/Buttons/FilledButton';
import { OptionsType } from 'components/v3/Forms/Select/Select';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';

import { timezones } from 'util/timezones';

import { BodyL, BodyM, BodyXLBold } from 'styles/v3/variables';

import { NewVenueForm } from './NewVenueForm';
import { EditVenueForm } from './EditVenueForm';
import { MyVenueView, CurrentPageType } from './types';

import * as S from './styles';

const Venues: FC = () => {
  const dispatch = useDispatch();

  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const [venues, setVenues] = useState<MyVenueView[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<Maybe<MyVenueView>>(null);
  const [currentPage, setCurrentPage] = useState<CurrentPageType>('Venues');
  const [stateOptions, setStateOptions] = useState<OptionsType<string>[]>([]);

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const { backButtonClickCount, hasBackButton } = useSelector(
    (state: ApplicationState) => state.sideModal
  );

  const selectedVenuesCount = useMemo(
    () => venues.filter((v) => v.preSelect).length,
    [venues]
  );

  const countriesList = useMemo(() => {
    const countries = Country.getAllCountries().map((i) => ({
      label: `${i.name} ${i.flag}`,
      value: i.isoCode,
    }));

    return countries;
  }, []);

  const defaultSelectedCountry = useMemo(() => {
    return countriesList.find(
      (country) => country.value === selectedVenue?.address?.country
    );
  }, [countriesList, selectedVenue]);

  const timezoneOptions = useMemo(() => {
    return timezones.map((timezone) => ({
      label: `${timezone.locations} - ${timezone.value}`,
      value: timezone.value,
    }));
  }, []);

  const loadVenues = useCallback(async () => {
    try {
      const { data, success } = await VenueServices.listEventVenues(eventId);

      if (success && data) {
        const updatedVenues =
          data.map((v) => {
            const selected =
              !!eventData?.venues?.find((e) => e?.venueId === v.id) ||
              !!venues.find((e) => e?.id === v.id && e.preSelect);

            return {
              ...v,
              selected,
              preSelect: selected,
            };
          }) ?? [];

        setVenues(updatedVenues);
      }
    } catch (e) {
      console.error('error loading venues', e);
    }
  }, [eventData, eventId]);

  const eventVenues = useMemo(() => {
    return (
      venues.filter((v) =>
        eventData?.venues?.find((e) => e?.venueId === v.id)
      ) ?? []
    );
  }, [eventData, venues]);

  const handleNewPage = () => {
    setCurrentPage('New Venues');
    dispatch(sideModalHasBackButton(true));
  };

  const handleMyVenues = () => {
    setCurrentPage('Your Venues');
    dispatch(sideModalHasBackButton(true));
  };

  const handleChangeCountry = useCallback((option?: OptionsType<string>) => {
    if (option?.value) {
      const statesOfCountry = State.getStatesOfCountry(option.value).map(
        (i) => ({
          label: i.name,
          value: i.isoCode,
        })
      );

      setStateOptions(statesOfCountry);
    }
  }, []);

  const handleSelectVenue = (venue: MyVenueView) => {
    setVenues(
      venues.map((v) => ({
        ...v,
        preSelect: v.id === venue.id ? !v.preSelect : v.preSelect,
      }))
    );
  };

  const handleDeleteVenue = (venueId: string) => {
    confirm({
      message: 'Are you sure that you want to delete this venue?',
      onOk: () => {
        const newVenues = venues.filter((v) => v.id !== venueId);
        handleSaveSelectedVenues(newVenues, false);
      },
    });
  };

  const handleSaveSelectedVenues = async (
    updatedVenues?: MyVenueView[],
    isEnabledToCloseSidebar = true
  ) => {
    const newVenues =
      updatedVenues ??
      venues.map((v) => ({
        ...v,
        selected: v.preSelect,
      }));

    setVenues(newVenues);

    const payload = {
      venues: newVenues
        .filter((v) => v.preSelect)
        .map((venue) => ({
          venueId: venue.id,
          subVenues:
            eventData.venues?.find((ev) => ev?.venueId === venue.id)
              ?.subVenues ?? [],
        })),
    };

    await mutateAsync({ id: eventId, data: payload });

    setCurrentPage('Venues');
    dispatch(sideModalHasBackButton(false));

    if (isEnabledToCloseSidebar) {
      dispatch(sideModalCloseModal());
    }
  };

  const handleLoadVenue = async (venueId: string) => {
    const { data, success } = await VenueServices.find(venueId);
    if (success) {
      setSelectedVenue(data);
    }
  };

  const handleLoadEditVenuePage = (venueId: string) => {
    setCurrentPage('Edit Venue');
    dispatch(sideModalHasBackButton(true));
    handleLoadVenue(venueId);
  };

  useEffect(() => {
    if (defaultSelectedCountry) {
      handleChangeCountry(defaultSelectedCountry);
    }
  }, [defaultSelectedCountry]);

  useEffect(() => {
    loadVenues();
  }, [eventData, loadVenues]);

  useEffect(() => {
    if (currentPage === 'Venues') {
      dispatch(sideModalHasBackButton(false));
    }

    dispatch(sideModalSetTitle(currentPage));
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (hasBackButton && backButtonClickCount > 0) {
      if (currentPage === 'Your Venues') {
        setCurrentPage('Venues');
      } else if (currentPage === 'New Venues') {
        setCurrentPage('Your Venues');
      } else if (currentPage === 'Edit Venue') {
        setCurrentPage('Venues');
      }
    }

    return () => {
      setSelectedVenue(null);
    };
  }, [backButtonClickCount, hasBackButton]);

  return (
    <S.Container>
      {currentPage === 'Venues' && (
        <S.VenuesWrapper>
          <S.VenuesList>
            {eventVenues.map((venue) => (
              <S.VenuesListItem key={venue.id}>
                <S.Content>
                  <BodyXLBold>{venue.name}</BodyXLBold>
                  <BodyM>{venue.abbreviation}</BodyM>
                  <BodyM>{venue.availableCourt} Courts</BodyM>
                </S.Content>
                <S.Content>
                  <S.Content
                    onClick={() => handleDeleteVenue(venue.id)}
                    className='button-container'
                  >
                    <DeleteIcon className='eventIcon delete' size='24px' />
                  </S.Content>
                  <S.Content
                    onClick={() => handleLoadEditVenuePage(venue.id)}
                    className='button-container'
                  >
                    <EditIcon className='eventIcon' size='24px' />
                  </S.Content>
                </S.Content>
              </S.VenuesListItem>
            ))}
          </S.VenuesList>
          <FilledButton
            className='add-venue-button'
            onClick={handleMyVenues}
            color='dark-white'
            isUpper
          >
            Add Venue
            <PlusIcon size='20px' />
          </FilledButton>
        </S.VenuesWrapper>
      )}
      {currentPage === 'New Venues' && (
        <NewVenueForm
          countriesOptions={countriesList}
          stateOptions={stateOptions}
          timezoneOptions={timezoneOptions}
          setCurrentPage={setCurrentPage}
          setVenues={setVenues}
          onCountryChange={handleChangeCountry}
        />
      )}
      {currentPage === 'Edit Venue' && (
        <EditVenueForm
          countriesOptions={countriesList}
          stateOptions={stateOptions}
          timezoneOptions={timezoneOptions}
          selectedVenue={selectedVenue}
          setCurrentPage={setCurrentPage}
          setVenues={setVenues}
          onCountryChange={handleChangeCountry}
        />
      )}
      {currentPage === 'Your Venues' && (
        <S.MyVenuesWrapper>
          <FilledButton
            className='add-venue-button'
            onClick={handleNewPage}
            color='dark-white'
            isUpper
          >
            New Venue
          </FilledButton>
          <S.MyVenuesList>
            {venues?.map((venue) => (
              <S.MyVenuesListItem
                onClick={() => handleSelectVenue(venue)}
                key={venue.id}
                $selected={venue.preSelect}
              >
                {venue.preSelect ? <CheckIcon size='24px' /> : <div />}
                <BodyL>{venue?.name}</BodyL>
              </S.MyVenuesListItem>
            ))}
          </S.MyVenuesList>
          <FilledButton
            isUpper
            disabled={selectedVenuesCount === 0}
            color='primary'
            isLoading={isLoading}
            onClick={() => handleSaveSelectedVenues()}
          >
            Select {!!selectedVenuesCount && selectedVenuesCount}
          </FilledButton>
        </S.MyVenuesWrapper>
      )}
    </S.Container>
  );
};

export default withRouter(Venues);
