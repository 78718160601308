import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Club } from '../../redux/club/types';
import { ApplicationState } from '../../redux/store';

export default function usePrimaryClub(): Club | undefined {
  const clubs = useSelector((state: ApplicationState) => state.club);
  const user = useSelector((state: ApplicationState) => state.currentUser);

  const [primaryClub, setPrimaryClub] = useState<Club>();
  useEffect(() => {
    // If primary Club is set
    if (user.data.playerInfo?.primaryClubId) {
      // Get Club that has the same ID from user's PrimaryClubId
      const club = clubs.data.clubs.find(
        (clubItem) => clubItem.id === user.data.playerInfo?.primaryClubId
      );
      // Check if Primary Club is in Club's list, otherwise Get first
      setPrimaryClub(club || clubs.data.clubs[0]);
    } else if (clubs.data.clubs[0]) {
      setPrimaryClub(clubs.data.clubs[0]);
    }
  }, [clubs.data.clubs, user.data.playerInfo]);

  return primaryClub;
}
