import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import EventService from 'services/Event/EventService';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';

import EventCardSelection from 'components/base/EventCardSelection/EventCardSelection';
import LoadingIcon from 'components/base/LoadingIcon/LoadingIcon';
import PageContainer from 'components/base/PageContainer/PageContainer';

import { roleCheck } from 'util/RoleCheck';

import { EventView } from '../../models/event/Event';

const TournamentSelection: FC = () => {
  const user = useSelector((state: ApplicationState) => state.currentUser);

  const [tournaments, setTornaments] = useState<EventView[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTornaments([]);

    let tournamentUserSearch;

    if (roleCheck(user.data, UserType.STAFF)) {
      tournamentUserSearch = {};
    }

    if (user.data && roleCheck(user.data, UserType.TOURNAMENT_DIRECTOR, true)) {
      tournamentUserSearch = {
        tournamentDirectors: [user.data.id],
      };
    }

    if (user.data && roleCheck(user.data, UserType.REFEREE, true)) {
      tournamentUserSearch = {
        referees: [user.data.id],
      };
    }

    if (tournamentUserSearch) {
      EventService.search({
        body: tournamentUserSearch,
        urlParams: new URLSearchParams({
          size: '100',
        }),
      }).then((res) => {
        if (res.success) {
          setTornaments(
            res.data.content.filter(
              (event) =>
                event.eventType === 'TOURNAMENT' || event.eventType === 'CAMP'
            )
          );
        }
        setLoading(false);
      });
    }
  }, [user.data]);

  return (
    <PageContainer
      title='TOURNAMENT SELECTION'
      titleStyle={{ textAlign: 'center' }}
    >
      <div className='group-box'>
        <div style={{ marginBottom: '40px' }}>
          <h3 className='line-after'>Active Tournaments</h3>
          <div className='box-grey' style={{ marginBottom: '10px' }}>
            <HeaderTable className='flex'>
              <div
                style={{
                  width: 'calc(30% + 100px)',
                  minWidth: 'calc(200px)',
                }}
              >
                Event
              </div>
              <div style={{ width: '10%', minWidth: '80px' }}> From Date</div>
              <div style={{ width: '10%', minWidth: '80px' }}>To Date</div>
            </HeaderTable>
            {loading && <LoadingIcon />}
            {tournaments
              .filter(
                (tournament) => new Date(tournament.endDate) >= new Date()
              )
              .map((tournament) => {
                return (
                  <Link
                    key={tournament.id}
                    to={`/admin-panel/tournaments/${tournament.id}`}
                  >
                    <div>
                      <EventCardSelection event={tournament} />
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
        <div>
          <h3>Past Tournaments</h3>
          <div className='box-grey'>
            <HeaderTable className='flex'>
              <div
                style={{
                  width: 'calc(30% + 100px)',
                  minWidth: 'calc(200px)',
                }}
              >
                Event
              </div>
              <div style={{ width: '10%', minWidth: '80px' }}> From Date</div>
              <div style={{ width: '10%', minWidth: '80px' }}>To Date</div>
            </HeaderTable>
            {loading && <LoadingIcon />}
            {tournaments
              .filter((tournament) => new Date(tournament.endDate) < new Date())
              .map((tournament) => {
                return (
                  <Link
                    key={tournament.id}
                    to={`/admin-panel/tournaments/${tournament.id}`}
                  >
                    <div>
                      <EventCardSelection event={tournament} />
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

const HeaderTable = styled.div`
  padding: 10px;
  font-weight: 600;
  color: #ccc;
`;

export default TournamentSelection;
