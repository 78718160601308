import { AgeDivision, NewAgeDivision, SpecialDivision } from '../AgeDivision';
// eslint-disable-next-line import/no-cycle
import { VenueView } from './Venue';

export interface EventCreation {
  name: string;
  membershipIds: string[];
  baseEventId: string;
  eventType: string;
  edition: string;
  startDate: Date;
  endDate: Date;
  price: number;
  ageDivisions: AgeDivision[];
  specialDivisions?: SpecialDivision[];
  venues: VenueView[];
  logo: string;
  description: string;
  link: string;
  eventRules?: EventRules;
  draft: boolean;
  referees: string[];
  coaches: string[];
}

export interface EventView {
  id: string;
  name: string;
  description: string;
  baseEventId: string;
  membershipIds: string[];
  eventType: string;
  edition: string;
  startDate: Date;
  endDate: Date;
  price: number;
  venues: VenueView[];
  logo: string;
  ageDivisions?: NewAgeDivision[];
  specialDivisions?: SpecialDivision[];
  eventRules?: EventRules;
  link?: string;
  multiplier: number;
  draft: boolean;
  referees: string[];
  coaches: string[];
  tournamentDirectors: string[];
  maximumCoachesNumber: number;
  maximumManagersNumber: number;
  maximumPlayersNumber: number;
  minimumCoachesNumber: number;
  minimumManagersNumber: number;
  minimumPlayersNumber: number;
  data?: EventData;
  ownerEmail?: string;
  owner?: boolean;
  eventCategory?: string;
}

export interface IAddressEvent {
  address: {
    city: string,
    country: string,
    line1: string,
    line2: string,
    state: string,
    zipCode: string,
  };
  id: string;
  name: string;
}

export type EventListView = {
  id: string,
  name: string,
  eventType: string,
  membershipIds: string[],
  edition: string,
  startDate: Date,
  endDate: Date,
  venues: IAddressEvent[],
  logo: string,
  description: string,
  link: string,
  draft: boolean,
  data?: EventData,
};

export type EvenListWithRosterUsersView = EventListView & {
  myIds: string[],
  playersIds: string[],
  coachesIds: string[],
  referees?: string[],
};

export interface SubVenue {
  name: string;
  abbreviation: string;
}
export interface EventRules {
  multipleTeams: boolean;
  multipleClubs: boolean;
  multipleAgeDivisions: 'YES' | 'YES_SAME_CLUB' | 'YES_DIFFERENT_CLUB';
}

export interface Tiebreakers {
  id: string;
  name: string;
  description: string;
}

export interface EventData {
  eventInfo?: EventInfo;
  generalRules?: EventGeneralRules;
  matchSettings?: EventMatchSettings;
  courts?: Array<string>;
  tieBreakers?: Tiebreakers[];
  multipliers?: EventMultipliers[];
  paymentInfo?: PaymentInfo;
  eventPublished?: EventPublishedStatus;
}

export enum EventPublishedStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export interface PaymentInfo {
  rosterFeeProductId?: string;
  stripeConnectedAccountId?: string;
  rosterFeePriceId?: string;
}

export interface EvenProduct {
  productId: string;
  name: string;
  price: number;
  priceId: string;
  applicationPercentageFee: number;
  coupons: EvenProductStripeCoupon[];
  enabled: boolean;
}

export interface EvenProductStripeCoupon {
  stripeCouponId: string;
  stripePromotionCodeId?: string;
  type: 'PERCENTAGE_OFF' | 'AMOUNT_OFF';
  percentageOff?: number;
  amountOff?: number;
  promotionCode: string;
  expiresAt: Date;
  maxRedemptions: number;
  createdAt: Date;
}

export interface EventInfo {
  poolName?: string;
  teamIdentifier?: string;
  header?: string;
  emailContact?: string;
  phoneContact?: string;
}

export interface EventGeneralRules {
  rosterLockDate?: Date;
  allowPlayersOnDifferentTeams?: boolean;
  allowPlayersOnDifferentClubs?: boolean;
  playerAgeDivisionPermission?: string;
  allowCoachesOnDifferentTeams?: boolean;
  allowCoachesOnDifferentClubs?: boolean;
  coachAgeDivisionPermission?: string;
  allowMultipleTeamsSameClubAndSameAge?: boolean;
  refereeConditions: RefereeConditions;
}

export interface EventMatchSettings {
  pointsToWin?: number;
  pointsToDraw?: number;
  pointsToLose?: number;
  pointsToShutout?: number;
  recordForForfeit?: number;
  poolGamesLength?: number;
  bracketGamesLength?: number;
  maxTimeBetweenGames?: number;
  minTimeBetweenGames?: number;
  maxGamesPerTeam?: number;
}

export interface EventMultipliers {
  title?: string;
  textContent?: string;
  multiplierValue?: number;
}

export interface RefereeConditions {
  allowRefereesToSignUp?: boolean;
  paymentCurrency?: string;
  refereeSolo?: string;
  refereePair?: string;
  scoreKeeper?: string;
  travelConditions?: string;
  requirements?: string;
}
