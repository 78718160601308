import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { ApplicationState } from 'redux/store';

import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import { useGetUsersStats } from 'hooks/v3/users/useGetUserStats/useGetUserStats';

import { Table } from 'components/v3/Table/Table';
import RightDrawer from 'components/v3/RightDrawer';

import DateUtil from 'util/DateUtil';

import { Filters } from './components/Filters/Filters';
import { columns } from './columns';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';

export const MembersPoolTable = () => {
  const { clubId } = useParams<{ clubId: string }>();

  const { data: currentClub } = useGetClubById(clubId);
  const {
    data: membersResponse,
    mutateAsync: fetchMembersPool,
  } = useGetClubMembersPool();

  const members = useMemo(() => {
    return membersResponse?.data ?? [];
  }, [membersResponse]);

  const { data: usersStats } = useGetUsersStats(
    members?.map((m) => m.id) || []
  );

  const [filterMember, setFilterMember] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState<User | undefined>(
    undefined
  );

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const handleFilter = useCallback((text: string) => {
    setFilterMember(text);
  }, []);

  const getPermissionType = useCallback(
    (userId: string, permission?: UserType) => {
      if (currentClub?.directorIds?.includes(userId)) {
        return UserType.CLUB_DIRECTOR;
      }

      if (currentClub?.coachIds?.includes(userId)) {
        return UserType.COACH;
      }

      if (currentClub?.teamManagers?.map((t) => t.userId).includes(userId)) {
        return UserType.TOURNAMENT_DIRECTOR;
      }

      if (currentClub?.playerIds?.includes(userId)) {
        return UserType.PLAYER;
      }

      return permission || UserType.PLAYER;
    },
    [currentClub]
  );

  const membersData = useMemo(() => {
    if (!members?.length || !currentClub) return [];

    return members
      .map((member) => ({
        id: member.id,
        name: `${member.firstName} ${member.lastName}`,
        permission: getPermissionType(member.id, member.type),
        xp:
          usersStats?.find(
            (user: { userId: string }) => user.userId === member.id
          )?.xp || 0,
        age: DateUtil.getYearFromDateString(member.birthDate?.toString()),
        gender: member.gender,
        membership: member.membership?.type || 'COMMUNITY',
        photo: member.photo,
      }))
      .filter((member) =>
        !filterMember
          ? true
          : member.name.toLowerCase().includes(filterMember.toLowerCase())
      );
  }, [
    members,
    selectedUserIds,
    filterMember,
    currentClub,
    usersStats,
    getPermissionType,
  ]);

  const handleCloseModal = useCallback(() => {
    setCurrentMember(undefined);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    fetchMembersPool({ clubId });
  }, [clubId]);

  return (
    <>
      <Filters handleChange={handleFilter} />
      <Table columns={columns} dataSource={membersData} />
      <RightDrawer
        isOpen={isOpen && !!currentMember}
        handleCloseModal={handleCloseModal}
        headerComponent={<ModalHeader photo={currentMember?.photo} />}
      >
        {currentMember && <ModalBody user={currentMember} />}
      </RightDrawer>
    </>
  );
};
