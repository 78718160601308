import React, { useMemo } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { isArray } from 'lodash';

import { useGetMyClubs } from 'hooks/v3/clubs/useGetMyClubs/useGetMyClubs';
import { useProfile } from 'hooks/v3/profile/useProfile';

import TabBar from 'components/v3/Elements/TabBar/TabBar';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import ClubCard from 'components/v3/Cards/ClubCard/ClubCard';
import Loading from 'components/v3/Loading/Loading';
import { TooltipAppears } from 'components/TooltipAppears/TooltipAppears';

import DateUtil from 'util/DateUtil';

import { BodyL, BodyLBold, TitleH2 } from 'styles/v3/variables';

import * as S from './styles';

const EmptyState = () => (
  <S.EmptyState>
    <BodyL $color='grey400'>
      You are not part of any Club. Try find the perfect club for you in the{' '}
      <BodyLBold>Find Clubs</BodyLBold> tab or create your own Club.
    </BodyL>
  </S.EmptyState>
);

const Clubs = () => {
  const history = useHistory();

  const { data: clubs, isLoading: clubLoading } = useGetMyClubs();
  const { currentUser, isLoading: userLoading } = useProfile();

  const mainClubId = currentUser.mainClub ?? '';

  const findMainClubByClubId = clubs.find((c) => c.id === mainClubId);

  const isEnabledClubCreation = useMemo(() => {
    if (typeof currentUser.birthDate === 'string') {
      return DateUtil.getUserAge(currentUser.birthDate) >= 16;
    }

    return false;
  }, [currentUser]);

  const handleRedirectClub = (clubId: string, isVerified: boolean) => {
    if (isVerified) {
      history.push(`/v3/clubs/${clubId}/dashboard`);
    } else {
      history.push(`/v3/clubs/${clubId}/pending`);
    }
  };

  return (
    <>
      <S.PageTitle $maxWidth={1044}>
        <TitleH2 $isUpper>Clubs</TitleH2>
      </S.PageTitle>
      <S.Wrapper $maxWidth={1044}>
        <S.WrapperHeader>
          <TabBar
            items={[
              {
                name: 'my clubs',
                link: '/v3/clubs',
                path: '/v3/clubs',
              },
              {
                name: 'find clubs',
                link: '/v3/clubs/find',
                path: '/v3/clubs/find',
              },
            ]}
          />
          <TooltipAppears
            enabled={!isEnabledClubCreation}
            title='To create a club, you must be 16 years old or older'
          >
            <TextButtonWithIcon
              align='flex-end'
              color='light'
              customIcon='Plus'
              onClick={() => history.push('/v3/clubs/new')}
            >
              New Club
            </TextButtonWithIcon>
          </TooltipAppears>
        </S.WrapperHeader>
        {!clubLoading || userLoading ? (
          <S.WrapperBody $empty={clubs.length === 0} $gridTemplateColumns='5,1'>
            {findMainClubByClubId && (
              <ClubCard
                key={findMainClubByClubId.id}
                club={findMainClubByClubId}
                isMainClub
                onClick={() =>
                  handleRedirectClub(
                    findMainClubByClubId.id,
                    findMainClubByClubId.clubVerified !== 'PENDING'
                  )
                }
              />
            )}
            {isArray(clubs) && clubs.length > 0 ? (
              clubs.map((club) => {
                const isVerified = club.clubVerified !== 'PENDING';
                if (findMainClubByClubId?.id === club.id) return false;
                return (
                  <ClubCard
                    key={club.id}
                    club={club}
                    isMainClub={findMainClubByClubId?.id === club.id}
                    onClick={() => handleRedirectClub(club.id, isVerified)}
                  />
                );
              })
            ) : (
              <EmptyState />
            )}
          </S.WrapperBody>
        ) : (
          <Loading />
        )}
      </S.Wrapper>
    </>
  );
};

export default withRouter(Clubs);
