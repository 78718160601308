import React, { FC, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';

interface INotFoundProps {
  auth?: Keycloak.KeycloakInstance | undefined;
}

const NotFound: FC<INotFoundProps> = memo(({ auth }) => {
  const history = useHistory();

  const isAuthenticated = useMemo(() => !!auth?.authenticated, []);

  const handleLoginRedirect = useCallback(() => {
    history.replace('/v3/login');
  }, []);

  const handleHomeRedirect = useCallback(() => {
    history.replace('/v3/home-page');
  }, []);

  return (
    <S.Container>
      <S.Title>404</S.Title>
      <S.Subtitle>Oops! Error 404 - Page Not Found</S.Subtitle>
      {!isAuthenticated && (
        <S.RedirectButton onClick={handleLoginRedirect}>
          Sign in to Vault
        </S.RedirectButton>
      )}
      {isAuthenticated && (
        <S.RedirectButton onClick={handleHomeRedirect}>
          Go to home
        </S.RedirectButton>
      )}
    </S.Container>
  );
});

NotFound.displayName = 'NotFound';

export default NotFound;
