import { AxiosRequestConfig } from 'axios';

import { ConfigApi } from 'services/config.api';
import APIServicesV3 from 'services/util/ApiServicesV3';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import PageResponse from 'services/util/PageResponse';
import { ClubView } from 'admin/models/club/Club';
import { RequestWithClubAndUser } from 'models/Request/RequestModel';
import { JoinClubRole } from 'models/v3/Club/ClubModel';
import { ApproveOrRejectRequestsProps, GetMyRequestsToClubProps, UserRequest, defaultGetMyRequestsToClubProps } from './types';

class RequestService {
  public static async getUserRequestToClub(
    clubId?: string,
    userId?: string
  ): Promise<ServiceResponseV3<UserRequest>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/requests/club/${clubId}/user/${userId}`,
      method: 'GET',
    };
    return APIServicesV3.request<UserRequest>(axiosConfig);
  }

  public static async requestJoin(
    body: any
  ): Promise<ServiceResponseV3<PageResponse<ClubView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/requests',
      method: 'POST',
      data: body,
    };
    return APIServicesV3.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async requestJoinAndCreateRole(
    clubId: string,
    role: JoinClubRole
  ): Promise<ServiceResponseV3<PageResponse<ClubView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/requests/join/${clubId}/${role}`,
      method: 'POST',
    };
    return APIServicesV3.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async getMyRequestsToClub({
    clubId,
    status = [],
    perPage = defaultGetMyRequestsToClubProps.perPage,
    page = defaultGetMyRequestsToClubProps.page,
  }: GetMyRequestsToClubProps): Promise<
    ServiceResponseV3<PageResponse<RequestWithClubAndUser>>
  > {
    if (!clubId)
      return ServiceResponseV3.success<PageResponse<RequestWithClubAndUser>>(
        new PageResponse([], false, false, 0, 0, 0),
        'Club not found'
      );
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/requests/my/${clubId}`,
      params: {
        status,
        page,
        size: perPage,
      },
      method: 'GET',
    };
    return APIServicesV3.request<PageResponse<RequestWithClubAndUser>>(
      axiosConfig
    );
  }

  public static async fetchClubApplications(
    clubId: string,
    params: URLSearchParams
  ): Promise<ServiceResponseV3<PageResponse<RequestWithClubAndUser>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/requests/club/${clubId}`,
      method: 'GET',
      params,
    };

    return APIServicesV3.request<PageResponse<RequestWithClubAndUser>>(
      axiosConfig
    );
  }

  public static async approveOrRejectRequests(
    data: ApproveOrRejectRequestsProps
  ) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/requests/process-requests`,
      method: 'POST',
      data,
    };
    return APIServicesV3.request(axiosConfig);
  }
}

export default RequestService;
