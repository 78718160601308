import { RequestStatus } from 'models/Request/RequestModel';

import { MembershipTypes } from 'services/v3/User/types';

import { Gender } from 'admin/models/Enums';

export const GENDER_OPTIONS = [
  { value: 'default', label: 'All Genders' },
  { value: Gender.MALE, label: 'MALE' },
  { value: Gender.FEMALE, label: 'FEMALE' },
];

export const MEMHERSHIP_TYPES_OPTIONS = [
  { value: 'default', label: 'All Membership Types' },
  { value: MembershipTypes.Community, label: 'Community' },
  { value: MembershipTypes.Official, label: 'Official' },
];

export const DOCUMENT_STATUS_OPTIONS = [
  { value: 'default', label: 'All Document Status' },
  { value: RequestStatus.APPROVED, label: 'APPROVED' },
  { value: RequestStatus.PENDING, label: 'PENDING' },
];
