import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';

import { accountFetchAccounts } from 'redux/account/actions';
import { myClubsFetchRequest } from 'redux/club/actions';
import { membershipsFetchRequest } from 'redux/memberships/actions';
import { ApplicationState } from 'redux/store';
import { userFetchRequest, userUpdateActive } from 'redux/user/actions';

import ConvertUtil from 'util/ConvertUtil';
import TokenUtil from 'util/TokenUtil';
import { getUserId, setUserId } from 'util/UsersUtil';
import { roleCheck } from 'util/RoleCheck';

import * as S from './styles';

const SideMenuFull: FC = () => {
  const userData = useSelector((state: ApplicationState) => state.account);

  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = getUserId();

    dispatch(myClubsFetchRequest());
    dispatch(membershipsFetchRequest());

    if (userId) {
      dispatch(userFetchRequest(userId));
    }

    dispatch(accountFetchAccounts());
  }, [dispatch]);

  const selectUser = (userId: string) => {
    const userTemp: User | undefined = userData.data.accounts?.find(
      (account) => account.id === userId
    );

    setUserId(userId);

    if (userId) {
      dispatch(userFetchRequest(userId));
    }

    if (userTemp) {
      dispatch(userUpdateActive(userTemp));
      dispatch(myClubsFetchRequest());

      TokenUtil.refreshToken();

      history.push('/home');
    }
  };

  const goToUpgradeMembership = () => {
    if (!roleCheck(currentUser, UserType.CLUB_DIRECTOR, true)) {
      history.push('/membership-upgrade');
    }
  };

  const authorWrapperClasses = useMemo(() => {
    if (!roleCheck(currentUser, UserType.REFEREE, true)) {
      return 'item link';
    }

    return 'item referee';
  }, [currentUser]);

  return (
    <S.SideMenuWrapper
      countSubItens={userData.data?.accounts?.length}
      className={userData.data?.accounts ? 'open' : ''}
    >
      {!roleCheck(currentUser, UserType.REFEREE, true) && (
        <div
          className={
            !roleCheck(currentUser, UserType.CLUB_DIRECTOR, true)
              ? 'item link'
              : 'item'
          }
          onClick={goToUpgradeMembership}
          onKeyDown={goToUpgradeMembership}
          role='button'
          aria-label='Upgrade Membership'
          tabIndex={0}
        >
          <div className='wrapper'>
            <div className='text'>
              <div
                style={{
                  width: '140px',
                  textAlign: 'center',
                  marginLeft: '-20px',
                }}
              >
                {!roleCheck(currentUser, UserType.CLUB_DIRECTOR, true) && (
                  <>UPGRADE YOUR MEMBERSHIP</>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={authorWrapperClasses}>
        <div className='wrapper'>
          <div className='avatar'>
            <div className='avatar-img'>
              <img
                alt=''
                src={ConvertUtil.getMinioUrl(
                  currentUser.avatar || currentUser.photo,
                  100
                )}
              />
            </div>
          </div>
          <div className='text'>{currentUser.firstName}</div>
        </div>
        {userData.data?.accounts &&
          !roleCheck(currentUser, UserType.REFEREE, true) && (
            <div className='sub-menu'>
              {userData.data?.accounts
                ?.filter((account) => account.id !== currentUser.id)
                .map((userItem) => {
                  return (
                    <div
                      key={userItem.id}
                      className='sub-item'
                      onClick={() => selectUser(userItem.id)}
                      onKeyDown={() => selectUser(userItem.id)}
                      role='button'
                      aria-label=''
                      tabIndex={0}
                    >
                      <div className='avatar'>
                        <div
                          className='avatar-img'
                          style={{
                            backgroundImage: `url(${
                              userItem.avatar
                                ? ConvertUtil.getMinioUrl(userItem.avatar, 100)
                                : ConvertUtil.getMinioUrl(userItem.photo, 100)
                            })`,
                          }}
                        />
                        <div
                          className='notification-circle'
                          style={{ display: 'none' }}
                        />
                      </div>
                      <div className='text-sub'>
                        <div className='name'>{`${userItem.firstName} ${userItem.lastName}`}</div>
                        <div className='role'>{`${userItem.type}`}</div>
                      </div>
                    </div>
                  );
                })}
              <div
                className='sub-item new-user'
                onClick={() => {
                  history.push('/wizard');
                }}
                onKeyDown={() => {
                  history.push('/wizard');
                }}
                role='button'
                aria-label=''
                tabIndex={0}
              >
                <div className='avatar'>
                  <div
                    className='notification-circle'
                    style={{ display: 'none' }}
                  />
                </div>
                <div className='text-sub'>+ ADD NEW USER</div>
              </div>
            </div>
          )}
      </div>
    </S.SideMenuWrapper>
  );
};

export default SideMenuFull;
