import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import MemberPoolSidebox from '../../../components/ClubDirector/MemberPoolSidebox/MemberPoolSidebox';
import RosterEdit from './RosterEdit';

const RosterEditPage: React.FunctionComponent = () => {
  const routeParams: { rosterId: string } = useParams();
  const { data: rosterDetailed } = useGetRosterByIdDetailed(
    routeParams.rosterId
  );

  const [playerPoolListOpen, setPlayerPoolListOpen] = useState(false);
  const [coachPoolListOpen, setCoachPoolListOpen] = useState(false);

  const appliedPlayersIds = useMemo(() => {
    return rosterDetailed?.players?.map((player) => player.id);
  }, [rosterDetailed]);

  const appliedCoachesIds = useMemo(() => {
    return rosterDetailed?.coaches?.map((coach) => coach.id);
  }, [rosterDetailed]);

  useEffect(() => {
    if (rosterDetailed?.status === 'SUBMITTED') {
      setPlayerPoolListOpen(false);
      setCoachPoolListOpen(false);
    }
  }, [rosterDetailed?.status]);

  return (
    <Row>
      <h2>My Rosters</h2>
      <Col span={24}>
        <div>
          <Row>
            <Col md={12} lg={14}>
              <RosterEdit
                openCoachPool={() => {
                  setPlayerPoolListOpen(false);
                  setCoachPoolListOpen(true);
                }}
                openPlayerPool={() => {
                  setPlayerPoolListOpen(true);
                  setCoachPoolListOpen(false);
                }}
              />
            </Col>
            <Col md={12} lg={10}>
              {rosterDetailed?.rosterId && (
                <>
                  <MemberPoolSidebox
                    type='PLAYERS'
                    open={playerPoolListOpen}
                    appliedMembersIds={appliedPlayersIds}
                    rosterId={routeParams.rosterId}
                  />
                  <MemberPoolSidebox
                    type='COACHES'
                    open={coachPoolListOpen}
                    appliedMembersIds={appliedCoachesIds}
                    rosterId={routeParams.rosterId}
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default RosterEditPage;
