import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import UserServices from 'services/v3/User/UserService';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';

import DateUtil from 'util/DateUtil';

import { validateMissingUserInfo } from './utils';
import { AGE_RULES } from './configs';

export const useProfile = () => {
  const dispatch = useDispatch();

  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isBaseUser = useMemo(() => {
    if (!currentUser) return false;

    return Array.isArray(currentUser?.types);
  }, [currentUser]);

  const userTypes = useMemo(() => {
    return [...(currentUser.types || []), currentUser.type || []];
  }, [currentUser]);

  const isStaff = useMemo(() => {
    return userTypes.includes(UserType.STAFF);
  }, [userTypes]);

  const isAnAdult = useMemo(() => {
    if (!currentUser) return false;

    const userBirthDate =
      typeof currentUser?.birthDate === 'string'
        ? currentUser?.birthDate?.split('T')[0]
        : moment();

    return DateUtil.isAnAdult(userBirthDate);
  }, [currentUser]);

  const checkAgePermission = useCallback(
    (userType: UserType) => {
      if (typeof currentUser.birthDate === 'string') {
        const currentAge = moment().diff(
          moment(currentUser.birthDate, 'YYYY-MM-DD'),
          'years'
        );

        return currentAge >= AGE_RULES[userType];
      }

      return false;
    },
    [currentUser]
  );

  const missingInformation = useMemo(() => {
    return validateMissingUserInfo(currentUser, isAnAdult);
  }, [currentUser, isAnAdult]);

  const updateCurrentAccount = useCallback(async () => {
    setIsLoading(true);

    const { data: updatedProfile } = await UserServices.getProfiles();

    if (isBaseUser) {
      const updatedBaseUser = updatedProfile?.baseUsers?.find(
        (u) => u.id === currentUser.id
      );

      if (updatedBaseUser) {
        dispatch(setCurrentUser(updatedBaseUser));

        const newUsers = [
          ...(updatedProfile.baseUsers || []),
          ...(updatedProfile.users || []),
        ];

        dispatch(usersFetchSuccess(newUsers));
        setIsLoading(false);
      }
    } else {
      const myNewBaseUser = updatedProfile?.baseUsers?.find((u) =>
        u.userIds?.includes(`${currentUser.id}`)
      );

      const updatedUser = updatedProfile?.users?.find(
        (u) => u.id === currentUser.id
      );

      if (myNewBaseUser) {
        dispatch(setCurrentUser(myNewBaseUser));
      } else if (updatedUser) {
        dispatch(setCurrentUser(updatedUser));
      }

      const newUsers = [
        ...(updatedProfile.baseUsers || []),
        ...(updatedProfile.users || []),
      ];

      dispatch(usersFetchSuccess(newUsers));
      setIsLoading(false);
    }
  }, [dispatch, currentUser]);

  return {
    isBaseUser,
    isStaff,
    currentUser,
    userTypes,
    isAnAdult,
    missingInformation,
    isLoading,
    updateCurrentAccount,
    checkAgePermission,
  };
};
