import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(8)};
  cursor: pointer;

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;

    &::before {
      display: none;
    }

    path {
      stroke: ${COLORS.grey400};
    }
  }
`;
