import React, {
  FC,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { ArrowLeft } from '@icon-park/react';

import { RequestStatus } from 'models/Request/RequestModel';

import { MembershipTypes } from 'services/v3/User/types';

import { Gender } from 'admin/models/Enums';

import IconButton from 'components/v3/Buttons/IconButton';
import FilledButton from 'components/v3/Buttons/FilledButton';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';

import { BodyL, BodyLBold, COLORS } from 'styles/v3/variables';

import { IFiltering } from '../../interfaces/filtering.interface';

import {
  GENDER_OPTIONS,
  MEMHERSHIP_TYPES_OPTIONS,
  DOCUMENT_STATUS_OPTIONS,
} from './configs';

import * as S from './styles';

interface IAdvancedFiltersProps {
  filtering: IFiltering;
  setFiltering: Dispatch<SetStateAction<IFiltering>>;
  onFilteringApply: () => void;
  onGoBack: () => void;
}

enum FilterType {
  Gender,
  BirthYear,
  MembershipType,
  DocumentStatus,
}

export const AdvancedFilters: FC<IAdvancedFiltersProps> = ({
  filtering,
  setFiltering,
  onFilteringApply,
  onGoBack,
}) => {
  const yearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();

    const options = Array.from({ length: currentYear - 1950 + 1 }, (_, index) => {
      const year = 1950 + index;

      return { value: year, label: year.toString() };
    });

    return [
      { value: 'default', label: 'All Years' },
      ...options,
    ]
  }, []);

  const handleFormChange = useCallback(
    (type: FilterType) => (
      optionValue: SingleValue<OptionsType> | MultiValue<OptionsType>
    ) => {
      if (!optionValue || Array.isArray(optionValue)) {
        return;
      }

      const option = optionValue as OptionsType;

      if (type === FilterType.Gender) {
        setFiltering((prevState: IFiltering) => ({
          ...prevState,
          gender: option.value as Gender | 'default',
        }));
      }

      if (type === FilterType.DocumentStatus) {
        setFiltering((prevState: IFiltering) => ({
          ...prevState,
          documentStatus: option.value as RequestStatus | 'default',
        }));
      }

      if (type === FilterType.BirthYear) {
        setFiltering((prevState: IFiltering) => ({
          ...prevState,
          birthYear: option.value as number | 'default',
        }));
      }

      if (type === FilterType.MembershipType) {
        setFiltering((prevState: IFiltering) => ({
          ...prevState,
          membershipType: option.value as MembershipTypes | 'default',
        }));
      }
    },
    [setFiltering]
  );

  return (
    <S.Container>
      <S.Header>
        <IconButton
          icon={<ArrowLeft size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: 'transparent' }}
          onClick={onGoBack}
        />
        <BodyL $color='brandPrimary' $isUpper>
          Filters
        </BodyL>
      </S.Header>
      <S.FiltersWrapper>
        <BodyLBold $color='white'>Description</BodyLBold>
        <Select
          isClearable
          placeholder='Gender'
          name='genderPlayers'
          defaultValue={GENDER_OPTIONS.find(option => option.value === filtering.gender)}
          options={GENDER_OPTIONS}
          onChange={handleFormChange(FilterType.Gender)}
        />
        <Select
          isClearable
          placeholder='Age'
          name='agePlayers'
          isSearchable
          defaultValue={yearsOptions.find(option => option.value === filtering.birthYear)}
          options={yearsOptions}
          onChange={handleFormChange(FilterType.BirthYear)}
        />
        <BodyLBold $color='white'>Managment</BodyLBold>
        <Select
          isClearable
          placeholder='Membership Type'
          name='typePlayers'
          defaultValue={MEMHERSHIP_TYPES_OPTIONS.find(option => option.value === filtering.membershipType)}
          options={MEMHERSHIP_TYPES_OPTIONS}
          onChange={handleFormChange(FilterType.MembershipType)}
        />
        <Select
          isClearable
          placeholder='Document Status'
          name='documentsPlayers'
          defaultValue={DOCUMENT_STATUS_OPTIONS.find(option => option.value === filtering.documentStatus)}
          options={DOCUMENT_STATUS_OPTIONS}
          onChange={handleFormChange(FilterType.DocumentStatus)}
        />
        <FilledButton isUpper isBold={false} onClick={onFilteringApply}>
          Apply
        </FilledButton>
      </S.FiltersWrapper>
    </S.Container>
  );
};
