import React, { FC } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'redux/store';
import { onToggle } from 'redux/v3/profileModal/actions';

import ConvertUtil from 'util/ConvertUtil';
import { formatUserName } from 'util/formatUserName';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IAvatarActionProps {
  action?: () => void;
}

const SvgIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.989 15.1713C7.15694 15.5798 8 16.6912 8 18.0022C8 19.6513 6.65 21.0006 5 21.0006C3.34 21.0006 2 19.6513 2 18.0022C2 16.6938 2.83344 15.5843 4 15.1738V11.0059C4 7.68781 6.68 5.00931 10 5.00931H10.59L9.29 3.72003V3.71903C8.89 3.31926 8.89 2.68961 9.29 2.29983C9.68 1.90006 10.31 1.90006 10.71 2.29983L13.71 5.29815C13.87 5.46805 13.97 5.68793 13.99 5.92779V6.12768C13.96 6.31758 13.88 6.50747 13.75 6.65738H13.7L10.7 9.6557L10.699 9.6547C10.239 9.96453 9.619 9.84459 9.299 9.39485C9.069 9.06503 9.059 8.62528 9.279 8.28547L10.579 6.99619H9.989C7.779 6.99619 5.989 8.78519 5.989 10.9939V15.1713ZM16 6.00955C16 7.30998 16.8333 8.42395 17.9997 8.83686V13.0051C17.9997 15.2038 16.1997 17.0028 13.9997 17.0028H13.4097L14.6997 15.7235C15.0897 15.3237 15.0897 14.6941 14.6997 14.3043C14.2997 13.9045 13.6697 13.9045 13.2797 14.3043L10.2797 17.3026C10.1097 17.4725 10.0097 17.6924 9.99969 17.9323C9.98969 17.9923 9.98969 18.0622 9.99969 18.1322C10.0197 18.3221 10.0997 18.512 10.2397 18.6619L10.2897 18.7118L13.2897 21.7102C13.4697 21.8901 13.7297 22 13.9997 22L13.9897 21.99C14.2497 21.99 14.5097 21.8801 14.6997 21.7002H14.7097C15.0997 21.3104 15.0997 20.6707 14.7097 20.291L13.4097 19.0017H13.9997C17.3097 19.0017 19.9997 16.3132 19.9997 13.0051V8.83468C21.1619 8.42089 21.9998 7.30803 22 6.00905C21.9997 4.35021 20.6498 3.01123 19 3.01123C17.34 3.01123 16 4.35048 16 6.00955ZM18.9997 7.00842L19.0238 7.0087C19.5629 6.99595 20 6.55129 20 6.00955C20 5.44986 19.55 5.01011 19 5.01011C18.44 5.01011 18 5.44986 18 6.00955C18 6.55119 18.4272 6.99579 18.9755 7.0087L18.9997 7.00842ZM4 18.0022C4 17.4426 4.44 17.0028 5 17.0028C5.55 17.0028 6 17.4426 6 18.0022C6 18.5519 5.55 19.0017 5 19.0017C4.44 19.0017 4 18.5519 4 18.0022Z'
      fill='#FBFBFA'
    />
  </svg>
);

const AvatarAction: FC<IAvatarActionProps> = ({ action }) => {
  const dispatch = useDispatch();

  const user = useSelector((state: ApplicationState) => state.currentUser.data);

  return (
    <>
      <S.Container onClick={() => dispatch(onToggle())}>
        <S.AvatarWrapper>
          <Avatar
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(user?.photo)}
          />
          <BodyM>
            {user &&
              formatUserName({
                lastName: user.lastName,
                firstName: user.firstName,
                middleName: user.middleName,
              })}
          </BodyM>
        </S.AvatarWrapper>
        {action && (
          <S.IconWrapper onClick={action}>
            <SvgIcon />
          </S.IconWrapper>
        )}
      </S.Container>
    </>
  );
};

export default AvatarAction;
