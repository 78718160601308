import React, { forwardRef, ChangeEvent, RefObject, useEffect, useState } from 'react';
import { message } from 'antd';
import Icon, { IconType } from '@icon-park/react/es/all';

import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';

import { BodyM } from 'styles/v3/variables';

import { IMAGE_MIME_TYPE, SIZE_LIMIT } from './configs';
import { convertFileSizeToMb } from './utils';

import * as S from './styles';

export interface InputFileProps {
  id: string;
  text: string;
  error?: boolean;
  isUpper?: boolean;
  className?: string;
  disabled?: boolean;
  ref?: RefObject<HTMLInputElement>;
  format?: 'square' | 'circle';
  previewUrl?: string;
  icon?: IconType;
  required?: boolean;
  acceptFormat?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, name?: string) => void;
  onClearValue?: (name: string) => void;
}

const InputFile = forwardRef<HTMLInputElement, InputFileProps>(
  (
    {
      isUpper = false,
      error = false,
      text,
      id,
      format = 'circle',
      previewUrl,
      acceptFormat,
      icon = 'AddPic',
      className,
      onChange,
      onClearValue,
      ...props
    },
    ref
  ) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileDataURL, setFileDataURL] = useState<
      string | ArrayBuffer | null
    >(null);

    const clearImageHandler = (e: any) => {
      if (ref) {
        // eslint-disable-next-line no-param-reassign
        (ref as RefObject<HTMLInputElement>).current!.value = '';
      }

      setFile(null);
      setFileDataURL(null);
      onChange?.(e, id);
    };

    const handleRemove = (e: any) => {
      confirm({
        message: 'Are you sure that you want to delete this image?',
        onOk: () => clearImageHandler(e)
      });
    } 

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line no-shadow
      const file = e.target?.files && e.target?.files[0];

      if (file) {
        if (!file.type.match(IMAGE_MIME_TYPE)) {
          const errorMessage = 'Image mime type is not valid';

          message.error({
            content: errorMessage,
            duration: 5,
          });

          return;
        }

        if (file.size > SIZE_LIMIT) {
          const errorMessage = `${convertFileSizeToMb(
            file.size
          )} file size is too big!`;

          message.error({
            content: errorMessage,
            duration: 5,
          });

          return;
        }

        setFile(file);
      }

      onChange?.(e);
    };

    useEffect(() => {
      if (previewUrl) {
        setFileDataURL(previewUrl);
      }
    }, [previewUrl]);

    useEffect(() => {
      let fileReader: FileReader;
      let isCancel = false;

      if (file) {
        fileReader = new FileReader();

        fileReader.onload = (e: ProgressEvent<FileReader>) => {
          if (e?.target) {
            const { result } = e.target;

            if (result && !isCancel) {
              setFileDataURL(result);
            }
          }
        };

        fileReader.readAsDataURL(file);
      }

      return () => {
        isCancel = true;

        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort();
        }
      };
    }, [file]);

    return (
      <S.Container
        $format={format}
        $error={error}
        className={`${className} input-file-container`}
      >
        <S.Input
          name={id}
          ref={ref}
          type='file'
          onChange={changeHandler}
          accept={acceptFormat ?? 'image/png, image/jpeg, application/pdf'}
          {...props}
        />
        {!fileDataURL && (
          <S.Label $isUpper={isUpper}>
            <Icon type={icon} size={24} />
            {text}
          </S.Label>
        )}
        {fileDataURL && typeof fileDataURL === 'string' ? (
          <S.ImagePreviewWrapper>
            <S.ImageClearWrapper
              className='image-clear-wrapper'
              onClick={handleRemove}
            >
              <BodyM>REMOVE</BodyM>
            </S.ImageClearWrapper>
            <img src={fileDataURL} alt='preview' />
          </S.ImagePreviewWrapper>
        ) : null}
      </S.Container>
    );
  }
);

InputFile.displayName = 'InputFile';

export default InputFile;
