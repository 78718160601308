import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const DivisionsForm = styled.form`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;

  div[role='group'],
  fieldset {
    padding: 0 ${toRem(30)} ${toRem(24)};
    border-bottom: 1px solid ${COLORS.grey900};

    & + div[role='group'],
    & + fieldset {
      margin-top: ${toRem(24)};
    }

    .general-rules-input {
      margin-top: 1rem;
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    .color-picker {
      margin-top: 1rem;
      width: 100%;
    }
  }
`;
export const ElementCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 0;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  & > p {
    margin-left: ${toRem(12)};
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  &:last-of-type:not(:first-child) {
    flex: 1;
  }
`;
export const DivisionsWrapper = styled.div`
  padding: ${toRem(24)} ${toRem(30)};

  .card-detail {
    & + .card-detail {
      margin-top: ${toRem(10)};
    }
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;

  margin-top: ${toRem(64)};

  display: flex;
  align-items: center;
  justify-content: center;
`;
