import { useMutation } from '@tanstack/react-query';

import { RequestStatus } from 'models/Request/RequestModel';
import ClubService from 'services/v3/Clubs/ClubService';
import { MembershipTypes } from 'services/v3/User/types';

import { Gender } from 'admin/models/Enums';

import { notification } from 'components/v3/Notification/notification';

export interface IClubInvitesParams {
  clubId: string;
  birthYear?: number[];
  gender?: Gender[];
  documentStatus?: RequestStatus[];
  membershipType?: MembershipTypes[];
  name?: string;
}

export const useGetMyClubsInvites = () => {
  return useMutation({
    mutationFn: (payload: IClubInvitesParams) => {
      const params = new URLSearchParams();

      if (payload.birthYear?.length) {
        params.set('birthYear', payload.birthYear.toString());
      }

      if (payload.gender?.length) {
        params.set('gender', payload.gender.toString());
      }

      if (payload.documentStatus?.length) {
        params.set('documentStatus', payload.documentStatus.toString());
      }

      if (payload.membershipType?.length) {
        params.set('membershipType', payload.membershipType.toString());
      }

      if (payload.name) {
        params.set('name', payload.name);
      }

      return ClubService.getMyClubsInvites(payload.clubId, params);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to filter members pool!',
        description: error?.message,
      });
    },
  });
};
