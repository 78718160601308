import { useQuery } from '@tanstack/react-query';

import { RequestStatus } from 'models/Request/RequestModel';
import ClubService from 'services/v3/Clubs/ClubService';
import { MembershipTypes } from 'services/v3/User/types';

import { Gender } from 'admin/models/Enums';

export interface IMembersPoolParams {
  clubId: string;
  birthYear?: number[];
  gender?: Gender[];
  documentStatus?: RequestStatus[];
  membershipType?: MembershipTypes[];
  name?: string;
  validateAccess?: boolean;
}

export const useGetMembersPool = (payload: IMembersPoolParams) => {
  return useQuery(['/get-members-pool-club', payload], () => {
    const params = new URLSearchParams();

    if (payload.birthYear?.length) {
      params.set('birthYear', payload.birthYear.toString());
    }

    if (payload.gender?.length) {
      params.set('gender', payload.gender.toString());
    }

    if (payload.documentStatus?.length) {
      params.set('documentStatus', payload.documentStatus.toString());
    }

    if (payload.membershipType?.length) {
      params.set('membershipType', payload.membershipType.toString());
    }

    if (payload.name) {
      params.set('name', payload.name);
    }

    if (typeof payload.validateAccess === 'boolean') {
      params.set('validateAccess', payload.validateAccess.toString());
    }

    return ClubService.fetchClubMembersPool(payload.clubId, params);
  });
};
