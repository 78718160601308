import { useMutation } from '@tanstack/react-query';

import { Gender } from 'admin/models/Enums';
import { RequestStatus } from 'models/Request/RequestModel';

import { MembershipTypes } from 'services/v3/User/types';
import RequestService from 'services/v3/Request/RequestService';

import { notification } from 'components/v3/Notification/notification';

export interface IClubApplicationsParams {
  clubId: string;
  birthYear?: number[];
  gender?: Gender[];
  documentStatus?: RequestStatus[];
  membershipType?: MembershipTypes[];
  name?: string;
  validateAccess?: boolean;
}

export const useGetClubApplications = () => {
  return useMutation({
    mutationFn: (payload: IClubApplicationsParams) => {
      const params = new URLSearchParams();

      params.set('page', '0');
      params.set('size', '200');
      params.set('status[]', RequestStatus.PENDING);

      if (payload.birthYear?.length) {
        params.set('birthYear', payload.birthYear.toString());
      }

      if (payload.gender?.length) {
        params.set('gender', payload.gender.toString());
      }

      if (payload.documentStatus?.length) {
        params.set('documentStatus', payload.documentStatus.toString());
      }

      if (payload.membershipType?.length) {
        params.set('membershipType', payload.membershipType.toString());
      }

      if (payload.name) {
        params.set('name', payload.name);
      }

      if (typeof payload.validateAccess === 'boolean') {
        params.set('validateAccess', payload.validateAccess.toString());
      }

      return RequestService.fetchClubApplications(payload.clubId, params);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to filter applications!',
        description: error?.message,
      });
    },
  });
};
