import React, {
  Dispatch,
  SetStateAction,
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm, Controller, get } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { VenueCreation } from 'admin/models/event/Venue';

import VenueServices from 'services/Venue/VenueApi';

import { notification } from 'components/v3/Notification/notification';
import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { BodyLBold } from 'styles/v3/variables';

import { CurrentPageType, MyVenueView } from './types';

import * as S from './styles';

interface IEditVenueFormProps {
  countriesOptions: OptionsType<string>[];
  stateOptions: OptionsType<string>[];
  timezoneOptions: OptionsType<string>[];
  selectedVenue: Maybe<MyVenueView>;
  setVenues: Dispatch<SetStateAction<MyVenueView[]>>;
  setCurrentPage: Dispatch<SetStateAction<CurrentPageType>>;
  onCountryChange: (option: OptionsType<string>) => void;
}

interface IFormParams {
  name: string;
  abbreviation?: string;
  addressLine1: string;
  addressLine2?: string;
  country: OptionsType<string>;
  state?: OptionsType<string>;
  timezone: OptionsType<string>;
  city: string;
  zipcode: string;
  availableCourt: number;
}

export const EditVenueForm: FC<IEditVenueFormProps> = memo(
  ({
    countriesOptions,
    stateOptions,
    timezoneOptions,
    selectedVenue,
    setVenues,
    setCurrentPage,
    onCountryChange,
  }) => {
    const { eventId } = useParams<{ eventId: string }>();

    const [isLoading, setIsLoading] = useState(false);

    const {
      control,
      formState: { errors, isValid },
      watch,
      setValue,
      handleSubmit,
    } = useForm<IFormParams>({
      mode: 'onChange',
    });

    const fields = watch(['country']);

    const handleVenueSubmit = useCallback(
      async (data: IFormParams) => {
        if (!selectedVenue) {
          return;
        }

        setIsLoading(true);

        const payload: VenueCreation = {
          name: data.name,
          ...(data.abbreviation && { abbreviation: data.abbreviation }),
          address: {
            line1: data.addressLine1,
            city: data.city,
            zipCode: data.zipcode,
            country: data.country.value,
            ...(data.addressLine2 && { line2: data.addressLine2 }),
            ...(data.state && { state: data.state.value }),
          },
          timezone: data.timezone.value,
          availableCourt: data.availableCourt,
        };

        const response = await VenueServices.updateEventVenue(
          selectedVenue.id,
          eventId,
          payload
        );

        if (response.success) {
          setVenues((prevState) => {
            return prevState.map((venue) =>
              venue.id === response.data.id ? response.data : venue
            );
          });
          setCurrentPage('Venues');

          notification.success({
            message: `The ${data.name} venue was successfully updated`,
          });

          setIsLoading(false);

          return;
        }

        notification.error({
          message: `Failed to update ${data.name} venue`,
          description: response.message,
        });

        setIsLoading(false);
      },
      [eventId, selectedVenue, setVenues, setCurrentPage]
    );

    useEffect(() => {
      onCountryChange(fields.country);
    }, [fields.country]);

    useEffect(() => {
      if (selectedVenue) {
        setValue('name', selectedVenue.name, { shouldValidate: true });
        setValue('abbreviation', selectedVenue.abbreviation || '', {
          shouldValidate: true,
        });
        setValue('addressLine1', selectedVenue.address.line1, {
          shouldValidate: true,
        });
        setValue('addressLine2', selectedVenue.address.line2 || '', {
          shouldValidate: true,
        });
        setValue('city', selectedVenue.address.city, { shouldValidate: true });
        setValue('zipcode', selectedVenue.address.zipCode, {
          shouldValidate: true,
        });
        setValue('availableCourt', selectedVenue.availableCourt, {
          shouldValidate: true,
        });

        const countryOption = countriesOptions.find(
          (option) => option.value === selectedVenue.address.country
        );

        const stateOption = stateOptions.find(
          (option) => option.value === selectedVenue.address.state
        );

        const timezoneOption = timezoneOptions.find(
          (option) => option.value === selectedVenue.timezone
        );

        if (countryOption) {
          setValue('country', countryOption, { shouldValidate: true });
        }

        if (stateOption) {
          setValue('state', stateOption, { shouldValidate: true });
        }

        if (timezoneOption) {
          setValue('timezone', timezoneOption, { shouldValidate: true });
        }
      }
    }, [selectedVenue]);

    return (
      <S.NewVenuesFormWrapper>
        <S.VenuesForm onSubmit={handleSubmit(handleVenueSubmit)}>
          <Controller
            control={control}
            name='name'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <InputText
                id='name'
                label='Venue Name'
                placeholder='Venue Name'
                type='text'
                isRequired
                className='general-event-input'
                error={!!errors.name}
                errorMessage={get(errors, 'name.message')}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='abbreviation'
            render={({ value, onChange }) => (
              <InputText
                id='abbreviation'
                label='Abbreviation'
                placeholder='Abbreviation'
                type='text'
                className='general-event-input'
                value={value}
                onChange={onChange}
              />
            )}
          />
          <BodyLBold $color='white'>Address</BodyLBold>
          <Controller
            control={control}
            name='addressLine1'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <InputText
                isRequired
                id='line1'
                label='Address 1'
                placeholder='Address 1'
                type='text'
                className='general-event-input'
                error={!!errors.addressLine1}
                errorMessage={get(errors, 'addressLine1.message')}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='addressLine2'
            render={({ value, onChange }) => (
              <InputText
                id='line2'
                label='Address 2'
                placeholder='Address 2'
                type='text'
                className='general-event-input'
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='country'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <Select
                required
                placeholder='Country'
                name='country'
                isSearchable
                options={countriesOptions}
                value={value}
                onChange={onChange}
                error={!!errors.country}
                errorMessage={get(errors, 'country.message')}
              />
            )}
          />
          {!!stateOptions.length && (
            <Controller
              control={control}
              name='state'
              rules={{ required: 'This field is required' }}
              render={({ value, onChange }) => (
                <Select
                  placeholder='State'
                  name='state'
                  required
                  isSearchable
                  options={stateOptions}
                  value={value}
                  onChange={onChange}
                  error={!!errors.state}
                  errorMessage={get(errors, 'state.message')}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name='timezone'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <Select
                placeholder='Location and timezone'
                name='timezone'
                required
                isSearchable
                options={timezoneOptions}
                value={value}
                onChange={onChange}
                error={!!errors.timezone}
                errorMessage={get(errors, 'timezone.message')}
              />
            )}
          />
          <Controller
            control={control}
            name='city'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <InputText
                isRequired
                id='city'
                label='City'
                placeholder='City'
                type='text'
                className='general-event-input'
                value={value}
                onChange={onChange}
                error={!!errors.city}
                errorMessage={get(errors, 'city.message')}
              />
            )}
          />
          <Controller
            control={control}
            name='zipcode'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <InputText
                isRequired
                id='zipCode'
                label='Zip Code'
                placeholder='Zip Code'
                type='text'
                className='general-event-input'
                value={value}
                onChange={onChange}
                error={!!errors.zipcode}
                errorMessage={get(errors, 'zipCode.message')}
              />
            )}
          />
          <BodyLBold $color='white'>Courts</BodyLBold>
          <Controller
            control={control}
            name='availableCourt'
            rules={{
              required: 'This field is required',
              validate: (availableCourt: string) => {
                const court = Number(availableCourt);

                if (court < 1 || court > 99) {
                  return 'The court value must be between 1 and 99';
                }

                return true;
              },
            }}
            render={({ value, onChange }) => (
              <InputText
                type='number'
                isRequired
                id='availableCourt'
                label='Number of courts'
                placeholder='Number of courts'
                className='general-event-input'
                value={value}
                onChange={onChange}
                error={!!errors.availableCourt}
                errorMessage={get(errors, 'availableCourt.message')}
              />
            )}
          />
          <FilledButton
            type='submit'
            disabled={isLoading || !isValid}
            color='primary'
            isUpper
          >
            Save {isLoading && '...'}
          </FilledButton>
        </S.VenuesForm>
      </S.NewVenuesFormWrapper>
    );
  }
);

EditVenueForm.displayName = 'EditVenueForm';
