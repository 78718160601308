import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'redux/store';

import { color } from 'styles/styleVariables';

import alert_icon from 'assets/imgs/icons/exclamation.svg';

const WarningBox: FC = () => {
  const user = useSelector((state: ApplicationState) => state.currentUser);

  return (
    <>
      {(user.data.ageVerified === 'DECLINED' ||
        user.data.photoVerified === 'DECLINED' ||
        user.data.documentationVerified === 'DECLINED') && (
        <WrapperBox>
          <div style={{ width: '30px', marginRight: '20px' }}>
            <img src={alert_icon} alt='' />
          </div>
          <div>
            Seems you have documents that were not accepted. Click{' '}
            <Link to='/my-settings'>
              <b>here</b>
            </Link>{' '}
            and to resend it.
          </div>
        </WrapperBox>
      )}
    </>
  );
};

const WrapperBox = styled.div`
  padding: 20px;
  width: 100%;
  font-weight: 300;
  background-color: ${color.orange};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default WarningBox;
