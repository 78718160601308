import React, { FC } from 'react';
import { ModalBodyProps } from './types';

import * as C from './components';

import * as S from './styles';

const ModalBody: FC<ModalBodyProps> = ({ user, onClose, onRemoveFromClub }) => {
  return (
    <S.Container>
      <C.Header user={user} />
      <C.UpgradeButton user={user} />
      <C.Permissions user={user} />
      <S.HR />
      <C.Documents user={user} />
      <S.HR />
      <C.Clubs user={user} />
      <S.HR />
      <C.Actions
        user={user}
        onClose={onClose}
        onRemoveFromClub={onRemoveFromClub}
      />
    </S.Container>
  );
};

export default ModalBody;
