import { HeadsetOne, SoccerOne, UserBusiness, People } from '@icon-park/react';

import { getUserTypeName } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { JoinClubRole } from 'models/v3/Club/ClubModel';

import { AGE_RULES } from 'hooks/v3/profile/useProfile/configs';

export const ALLOWED_ROLES = [
  {
    title: 'Coach',
    name: UserType.COACH,
    icon: HeadsetOne,
    slug: 'coach' as JoinClubRole,
    selected: false,
  },
  {
    title: 'Player',
    name: UserType.PLAYER,
    icon: SoccerOne,
    slug: 'player' as JoinClubRole,
    selected: false,
  },
  {
    title: 'Team Manager',
    name: UserType.TEAM_MANAGER,
    icon: People,
    slug: 'team-manager' as JoinClubRole,
    selected: false,
  },
  {
    title: 'Club Director',
    name: UserType.CLUB_DIRECTOR,
    icon: UserBusiness,
    slug: 'club-director' as JoinClubRole,
    selected: false,
  },
];

export const DISABLE_REASONS = {
  application: 'Application is closed for joining',
  ageRules: (role: UserType) => `To join as a ${getUserTypeName(role)} you should be over ${AGE_RULES[role]}`,
  applied: (role: UserType) => `You are already joined to the club as a ${getUserTypeName(role)}`,
}
