import { Col, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import MemberPoolSidebox from '../../../components/ClubDirector/MemberPoolSidebox/MemberPoolSidebox';
import RosterEdit from '../../../pages/ClubDirector/Rosters/RosterEdit';

const StaffRosterEdit = () => {
  const routeParams: { rosterId: string } = useParams();
  const { data: rosterDetailed } = useGetRosterByIdDetailed(
    routeParams.rosterId
  );
  const [playerPoolListOpen, setPlayerPoolListOpen] = useState(false);
  const [coachPoolListOpen, setCoachPoolListOpen] = useState(false);

  const appliedPlayersIds = useMemo(() => {
    return rosterDetailed?.players?.map((player) => player.id);
  }, [rosterDetailed]);

  const appliedCoachesIds = useMemo(() => {
    return rosterDetailed?.coaches?.map((coach) => coach.id);
  }, [rosterDetailed]);

  return (
    <Row>
      <Col span={24}>
        <h1>Edit Rosters</h1>
        <Row>
          <Col md={12} lg={14}>
            {rosterDetailed && (
              <RosterEdit
                openCoachPool={() => {
                  setPlayerPoolListOpen(false);
                  setCoachPoolListOpen(true);
                }}
                openPlayerPool={() => {
                  setPlayerPoolListOpen(true);
                  setCoachPoolListOpen(false);
                }}
                staffUnlock
              />
            )}
          </Col>
          <Col md={12} lg={10}>
            <>
              <MemberPoolSidebox
                type='PLAYERS'
                open={playerPoolListOpen}
                rosterId={routeParams.rosterId}
                appliedMembersIds={appliedPlayersIds}
                staffUnlock
              />
              <MemberPoolSidebox
                type='COACHES'
                open={coachPoolListOpen}
                appliedMembersIds={appliedCoachesIds}
                rosterId={routeParams.rosterId}
                staffUnlock
              />
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StaffRosterEdit;
