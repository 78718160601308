import React, { useEffect, useMemo, useState } from 'react';
import { CloseSmall } from '@icon-park/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import OrderService from 'services/v3/Order/OrderService';

import { ApplicationState } from 'redux/store';
import { clubDashboardSetSelectedMemberPoolUser } from 'redux/v3/clubDashboard/actions';

import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';

import FilledButton from 'components/v3/Buttons/FilledButton';
import { notification } from 'components/v3/Notification/notification';

import { BodyXL, BodyXLBold } from 'styles/v3/variables';

import * as S from './styles';

export const SelectedBox = () => {
  const { clubId } = useParams<{ clubId: string }>();

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const {
    data: membersResponse,
    mutateAsync: fetchMembersPool,
  } = useGetClubMembersPool();

  const members = useMemo(() => {
    return membersResponse?.data || [];
  }, [membersResponse]);

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const selectedIds = useMemo(() => {
    return (
      members?.filter((m) => selectedUserIds.includes(m.id)).map((m) => m.id) ||
      []
    );
  }, [selectedUserIds, members]);

  const handleClose = () => {
    dispatch(clubDashboardSetSelectedMemberPoolUser([]));
  };

  const handleCheckout = async () => {
    if (selectedIds.length === 0) return;

    setIsLoading(true);

    OrderService.getMembershipCheckoutStripeUrl({
      userIds: selectedIds,
      cancelUrl: `/v3/clubs/${clubId}/dashboard?checkout=error`,
      successUrl: `/v3/clubs/${clubId}/dashboard?checkout=success`,
    })
      .then((res) => {
        if (res.httpCode === 200) {
          window.location.href = res.data.paymentUrl;
        } else {
          notification.error({
            duration: 3,
            message: 'Error',
            description:
              res.message ||
              'Error on checkout check selected users and try again',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchMembersPool({
      clubId,
      params: {
        validateAccess: true,
      },
    });
  }, [clubId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('checkout')) {
      const checkoutParam = queryParams.get('checkout');
      if (checkoutParam === 'success') {
        notification.success({
          duration: 8,
          message: 'Success',
          description: 'User upgrade completed',
        });
      } else if (checkoutParam === 'error') {
        notification.info({
          duration: 8,
          message: 'Payment was not completed',
          description: 'Please try again',
        });
      }
      queryParams.delete('checkout');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);

  if (selectedIds.length === 0) {
    return <div />;
  }

  return (
    <S.ContainerWrapper>
      <S.Container>
        <S.Header>
          <S.Title>
            <BodyXLBold>{selectedIds.length}</BodyXLBold>
            <BodyXL>selected members</BodyXL>
          </S.Title>
          <CloseSmall onClick={handleClose} />
        </S.Header>
        <S.Body>
          <FilledButton disabled={isLoading} onClick={handleCheckout}>
            {isLoading
              ? 'Processing...'
              : `UPGRADE ${selectedIds.length} MEMBERS`}
          </FilledButton>
        </S.Body>
      </S.Container>
    </S.ContainerWrapper>
  );
};
