import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const CheckboxWrapper = styled.div`
  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }
`;
