import { Col as ColAnt, Row as RowAntd } from 'antd';
import { ColProps } from 'antd/lib/col';
import { RowProps } from 'antd/lib/row';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CardBox from '../../components/base/CardBox/CardBox';
import GroupWrapper from '../../components/base/GroupWrapper/GroupWrapper';
import LightBox from '../../components/base/LightBox/LightBox';
import WarningBox from '../../components/base/WarningBox/WarningBox';
import ClubCard from '../../components/ClubCard/ClubCard';
import EditInfo from '../../components/EditInfo/EditInfo';
import EventsBox from '../../components/EventsBox/EventsBox';
import JoinClubBox from '../../components/JoinClubBox/JoinClubBox';
import MyInfoGroup from '../../components/MyInfoGroup/MyInfoGroup';
import NewSafesportBox from '../../components/NewSafesportBox/NewSafesportBox';
import NewSeasonBox from '../../components/NewSeasonBox/NewSeasonBox';
import NewSeasonAndSafesportBox from '../../components/NewSeasonAndSafesportBox/NewSeasonAndSafesportBox';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import Button from '../../components/uielements/Button/Button';
import UserType from '../../models/User/UserTypeModel';
import { ApplicationState } from '../../redux/store';

interface CustomRowProps extends RowProps {
  centered?: boolean;
}
interface CustomColProps extends ColProps {
  centered?: boolean;
}

export const Row = styled(RowAntd)<CustomRowProps>``;

export const Col = styled(ColAnt)<CustomColProps>`
  padding: 0 10px;
  margin: 0 auto;
`;

const IndexPage: React.FunctionComponent = () => {
  const club = useSelector((state: ApplicationState) => state.club);
  const user = useSelector((state: ApplicationState) => state.currentUser);
  const [openJoinClubPopUp, setOpenJoinClubPopUp] = useState(false);
  const [openEditInfo, setOpenEditInfo] = useState(false);
  const [newSeasonPopup, setNewSeasonPopup] = useState(false);
  const [newSafesportPopup, setNewSafesportPopup] = useState(false);
  const [newSeasonAndSafesportPopup, setSeasonAndNewSafesportPopup] = useState(
    false
  );
  const SEASON_YEAR = 2024;

  useEffect(() => {
    if (club.loading) {
      setOpenJoinClubPopUp(false);
    }
    // If user is not in any club or requested or clicked on "No thanks"
    // TODO: get my-request data, and hide Popup when use requested to be part of a club
    else if (
      club.data.clubs.length ||
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      JSON.parse(localStorage.getItem('vault_preferences')!)?.clubPopupHide
    ) {
      setOpenJoinClubPopUp(false);
    } else {
      setOpenJoinClubPopUp(true);
    }
  }, [club]);

  useEffect(() => {
    if (!localStorage.getItem(`new_season_${SEASON_YEAR}_popup`)) {
      setNewSeasonPopup(true);
    }
    if (!localStorage.getItem(`new_safesport_${SEASON_YEAR}_popup`)) {
      setNewSafesportPopup(true);
    }
    if (
      !localStorage.getItem(`new_season_and_safesport_${SEASON_YEAR}_popup`)
    ) {
      setSeasonAndNewSafesportPopup(true);
    }
  }, []);

  const closeNewSeasonPopup = () => {
    localStorage.setItem(`new_season_${SEASON_YEAR}_popup`, 'true');
    setNewSeasonPopup(false);
  };

  const closeNewSafesportPopup = () => {
    localStorage.setItem(`new_safesport_${SEASON_YEAR}_popup`, 'true');
    setNewSeasonPopup(false);
  };

  const closeNewSeasonAndSafesportPopup = () => {
    localStorage.setItem(
      `new_season_and_safesport_${SEASON_YEAR}_popup`,
      'true'
    );
    setSeasonAndNewSafesportPopup(false);
  };

  return (
    <Row>
      <WarningBox />
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 8, order: 2 }}
        xl={7}
        xxl={7}
      >
        {user.data.documentationVerified === 'DECLINED' &&
          user.data.type === UserType.CLUB_DIRECTOR && (
            <LightBox
              isOpen={newSafesportPopup}
              onClose={() => closeNewSafesportPopup()}
            >
              <NewSafesportBox onClose={() => closeNewSafesportPopup()} />
            </LightBox>
          )}

        {user.data.documentationVerified === 'DECLINED' &&
          (user.data.type === UserType.COACH ||
            user.data.type === UserType.REFEREE) && (
            <LightBox
              isOpen={newSeasonAndSafesportPopup}
              onClose={() => closeNewSeasonAndSafesportPopup()}
            >
              <NewSeasonAndSafesportBox
                onClose={() => closeNewSeasonAndSafesportPopup()}
              />{' '}
            </LightBox>
          )}

        {user.data.type !== undefined &&
          [UserType.COACH, UserType.REFEREE, UserType.CLUB_DIRECTOR].includes(
            user.data.type
          ) === false && (
            <LightBox
              isOpen={newSeasonPopup}
              onClose={() => closeNewSeasonPopup()}
            >
              <NewSeasonBox onClose={() => closeNewSeasonPopup()} />
            </LightBox>
          )}

        {!newSeasonPopup && !newSafesportPopup && !newSeasonAndSafesportPopup && (
          <LightBox
            isOpen={
              user.data.type !== UserType.CLUB_DIRECTOR &&
              user.data.type !== UserType.STAFF &&
              openJoinClubPopUp
            }
            onClose={() => setOpenJoinClubPopUp(false)}
          >
            <JoinClubBox onClose={() => setOpenJoinClubPopUp(false)} />
          </LightBox>
        )}

        <LightBox isOpen={openEditInfo}>
          <EditInfo
            onSave={() => setOpenEditInfo(false)}
            onClose={() => setOpenEditInfo(false)}
          />
        </LightBox>

        <GroupWrapper title='MY INFO'>
          <MyInfoGroup />
          <Button onClick={() => setOpenEditInfo(true)}>Edit info</Button>
        </GroupWrapper>
        <GroupWrapper title='FEATURED VIDEOS'>
          <CardBox
            style={{ marginTop: '-30px' }}
            title='United Futsal® Futures™'
            link='https://vimeo.com/394735246'
            featureImage='https://i.vimeocdn.com/video/860731221-b6b38b8c8ec6e82e48404cfac16fc0595d679f57c394c7c74a36a5bbfcd9b5ac-d_1280x720'
            video
          />

          <CardBox
            style={{ marginTop: '-30px' }}
            title='Champions Cup Series'
            link='https://vimeo.com/315294825'
            featureImage='https://i.vimeocdn.com/video/774160929-10290978b0460323019e32e4a3d517f1e4a56495906b27085af3f5d24dae9c60-d_1280x720'
            video
          />

          <CardBox
            style={{ marginTop: '-30px' }}
            title='WFC VIII Day 4 Highlights'
            link='https://vimeo.com/382050502'
            featureImage='https://i.vimeocdn.com/video/843033964-7c1a068854f38cd1a803512d4e1941c233b90802a59682b57158f9cbddc0d9c4-d_1280x720'
            video
          />
        </GroupWrapper>
        {/* <GroupWrapper title='FEATURED NEWS'>
          <FeaturedNews />
        </GroupWrapper> */}
      </Col>

      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 16, order: 2 }}
        xl={11}
        xxl={10}
        span={11}
      >
        <GroupWrapper title='MY PROFILE'>
          <ProfileCard />
        </GroupWrapper>
        {club.data.clubs[0] && (
          <GroupWrapper title='MY CLUB'>
            <ClubCard />
          </GroupWrapper>
        )}
        {/*           
          <GroupWrapper title='MY TEAM'>
            <TeamCard />
            <TeamCard secondary />
          </GroupWrapper>
          <GroupWrapper title='MY MATCHES'>
            <MatchCard />
            <MatchCard />
            <MatchCard />
          </GroupWrapper> */}
      </Col>

      <Col
        xs={{ span: 24, order: 3 }}
        sm={{ span: 24, order: 3 }}
        md={{ span: 12, order: 3 }}
        xl={6}
        xxl={6}
        span={6}
      >
        <GroupWrapper>
          <EventsBox />
        </GroupWrapper>
      </Col>
    </Row>
  );
};

export default IndexPage;
