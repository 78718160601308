import React, {
  FC,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import ProgressBar from '@ramonak/react-progress-bar';
import _ from 'lodash';

import {
  EventInfoModel,
  GeneralRulesModel,
  MatchSettingsModel,
} from 'models/v3/setupEvent';

import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { ApplicationState } from 'redux/store';

import { EventPublishedStatus } from 'admin/models/event/Event';

import { InfoEventEdit } from 'components/v3/Cards/InfoEventEdit';
import FilledButton from 'components/v3/Buttons/FilledButton';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import SetupEventModal from 'components/v3/SetupEvent/Modal';
import Loading from 'components/v3/Loading/Loading';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';

import { TitleH2, BodyL, COLORS, TitleH4 } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

import { SettingInfo } from './types';
import { SETTING_INFO_ARRAY } from './settingInfoArray';
import { STATUS_OBJECT} from './configs';

import {
  EditContainer,
  HeaderEdit,
  HeaderTitle,
  EventStatusCrumb,
  EditContentList,
  PublishEventContainer,
  Container,
  EventSetupAndProgressBarContainer,
} from './styles';

interface IEditEventProps {
  auth: Keycloak.KeycloakInstance | undefined;
}

type NestedObject = { [key: string]: any };

const EditEvent: FC<IEditEventProps> = ({ auth }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isOpen } = useSelector((state: ApplicationState) => state.sideModal);
  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const { eventId = ''} = useParams<{ eventId?: string }>();

  const { isStaff } = useProfile();
  const { data: currentEvent } = useGetEventById(eventId);
  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const [totallyInfos, setTotallyInfos] = useState<number>(0);
  const [infosToEdit, setInfosToEdit] = useState<SettingInfo[]>(
    SETTING_INFO_ARRAY
  );
  const [checkedSettings, setCheckedSettings] = useState<string[]>([]);

  const handleBack = useCallback(() => {
    const locationState: any = history.location.state || {};

    if (locationState?.from === 'dashboard') {
      history.goBack();
    } else {
      history.push('/v3/events?tab=created-by-me')
    }
  }, []);

  const isObjectFullPopulated = (obj: NestedObject): boolean => {
    let allValid = true;

    function checkValue(value: any) {
      if (_.isNull(value) || _.isUndefined(value) || value === '') {
        allValid = false;
      } else if (_.isObject(value) && !_.isArray(value)) {
        _.forOwn(value, (v, k) => {
          if (!allValid) return;
          checkValue(v);
        });
      }
    }

    _.forOwn(obj, (value) => {
      if (!allValid) return;
      checkValue(value);
    });

    return allValid;
  };

  const handlePublishEvent = async () => {
    try {
      const payload = {
        data: {
          eventPublished: EventPublishedStatus.PENDING,
        },
      };

      await mutateAsync({ id: eventId, data: payload });

      history.push('/v3/events?tab=created-by-me');
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const saveCheckedSettings = (value: string) => {
    setCheckedSettings([...checkedSettings, value]);
  };

  const allowPublishEvent = useMemo(() => {
    const eventPublished = eventData?.data?.eventPublished;

    return (
      !eventPublished || eventPublished === EventPublishedStatus.UNPUBLISHED
    );
  }, [eventData]);

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(eventId));
  }, [eventId, dispatch]);

  useEffect(() => {
    if (!isOpen && eventData) {
      const eventInfoStatus: EventInfoModel = {
        name: eventData.name,
        description: eventData.description,
        startDate: eventData.startDate as Date,
        endDate: eventData.endDate as Date,
        logo: eventData.logo,
        data: {
          eventInfo: {
            emailContact: eventData.data?.eventInfo?.emailContact,
            phoneContact: eventData.data?.eventInfo?.phoneContact,
          },
        },
      };

      const generalRulesStatus: GeneralRulesModel = {
        minimumPlayersNumber: eventData.minimumPlayersNumber,
        maximumPlayersNumber: eventData.maximumPlayersNumber,
        minimumCoachesNumber: eventData.minimumCoachesNumber,
        maximumCoachesNumber: eventData.maximumCoachesNumber,
        roasterLockDate: eventData.data?.generalRules?.rosterLockDate as Date,
        refereePair:
          eventData.data?.generalRules?.refereeConditions?.refereePair,
        refereeSolo:
          eventData.data?.generalRules?.refereeConditions?.refereeSolo,
        scoreKeeper:
          eventData.data?.generalRules?.refereeConditions?.scoreKeeper,
      };

      const matchSettingsStatus: MatchSettingsModel = {
        pointsToWin: eventData.data?.matchSettings?.pointsToWin,
        pointsToDraw: eventData.data?.matchSettings?.pointsToDraw,
        pointsToLose: eventData.data?.matchSettings?.pointsToLose,
        pointsToShutout: eventData.data?.matchSettings?.pointsToShutout,
        recordForForfeit: eventData.data?.matchSettings?.recordForForfeit,
        poolGamesLength: eventData.data?.matchSettings?.poolGamesLength,
        bracketGamesLength: eventData.data?.matchSettings?.bracketGamesLength,
        maxTimeBetweenGames: eventData.data?.matchSettings?.maxTimeBetweenGames,
        minTimeBetweenGames: eventData.data?.matchSettings?.minTimeBetweenGames,
        maxGamesPerTeam: eventData.data?.matchSettings?.maxGamesPerTeam,
      };

      const { ageDivisions, venues, data } = eventData;
      const multipliers = data?.multipliers;
      const tiebrakers = data?.tieBreakers;
      const pricing = data?.paymentInfo;
      const courts = venues?.map((venue) => venue?.subVenues);

      const infosTitles: string[] = [];
      const drafredInfosTitles: string[] = [];
      const isFilledAllRequiredEventInfoFields = isObjectFullPopulated(
        eventInfoStatus
      );
      const isFilledAllEventInfoFields = isObjectFullPopulated({
        ...eventInfoStatus,
        data: { eventInfo: { ...eventData.data?.eventInfo } },
      });
      const isFilledAllRequiredGeneralRules = isObjectFullPopulated(
        generalRulesStatus
      );
      const isFilledAllGeneralRules = isObjectFullPopulated({
        ...generalRulesStatus,
        refereeConditions: {
          ...eventData.data?.generalRules?.refereeConditions,
        },
      });

      if (isFilledAllRequiredEventInfoFields && !isFilledAllEventInfoFields) {
        drafredInfosTitles.push('Event Info');
      } else if (
        isFilledAllRequiredEventInfoFields &&
        isFilledAllEventInfoFields
      ) {
        infosTitles.push('Event Info');
      }

      if (isFilledAllRequiredGeneralRules && !isFilledAllGeneralRules) {
        drafredInfosTitles.push('General Rules');
      } else if (isFilledAllRequiredGeneralRules && isFilledAllGeneralRules) {
        infosTitles.push('General Rules');
      }
      isObjectFullPopulated(matchSettingsStatus) &&
        infosTitles.push('Match Setting');

      ageDivisions && ageDivisions.length > 0 && infosTitles.push('Divisions');
      venues && venues.length > 0 && infosTitles.push('Venues');
      courts && courts?.length > 0 &&
        courts.every((courtArray) => courtArray && courtArray?.length > 0) &&
        infosTitles.push('Courts');
      pricing && isObjectFullPopulated(pricing) && infosTitles.push('Pricing');

      multipliers && multipliers.length > 0 && infosTitles.push('Multipliers');
      tiebrakers && tiebrakers.length > 0 && infosTitles.push('Tiebreaker');

      setInfosToEdit((prev) => {
        return prev.map((item) => {
          if (!!drafredInfosTitles && drafredInfosTitles.includes(item.title)) {
            return {
              ...item,
              color: COLORS.grey200,
            };
          }
          if (!!infosTitles && infosTitles.includes(item.title)) {
            return {
              ...item,
              color: COLORS.brandPrimary,
            };
          }
          if (!!checkedSettings && checkedSettings.includes(item.title)) {
            return {
              ...item,
              color: COLORS.supportError,
            };
          }

          return { ...item, color: COLORS.grey50 };
        });
      });

      setTotallyInfos(infosTitles.length);
    }
  }, [isOpen, eventData, checkedSettings]);

  if (!currentEvent) return <Loading />;

  if (!currentEvent?.owner && !isStaff) {
    if (currentEvent?.draft) {
      history.push(`/v3/events`);
    } else {
      history.push(`/v3/event/${currentEvent.id}`);
    }
  }

  return (
    <Container>
      <EditContainer>
        <HeaderEdit>
          <TextButtonWithIcon
            color='primary'
            align='flex-end'
            reverse
            icon='back'
            onClick={handleBack}
          >
            Go back
          </TextButtonWithIcon>
          <HeaderTitle>
            <TitleH2 style={{ lineHeight: 0 }}>{eventData?.name || ''}</TitleH2>
            <EventStatusCrumb>
              {eventData?.data?.eventPublished || 'UNPUBLISHED'}
            </EventStatusCrumb>
          </HeaderTitle>
          <TitleH4>Event Settings</TitleH4>
        </HeaderEdit>
        <EditContentList>
          {infosToEdit.map((info) => (
            <InfoEventEdit
              status={STATUS_OBJECT[info.color]}
              key={info.title}
              title={info.title}
              setCheckedSettings={saveCheckedSettings}
              selectedModal={info.selectedModal}
            >
              {cloneElement(info.component, { fill: info.color })}
            </InfoEventEdit>
          ))}
        </EditContentList>
        <SetupEventModal auth={auth} />
      </EditContainer>
      <PublishEventContainer>
        <EventSetupAndProgressBarContainer>
          <BodyL $color='brandPrimary'>Event Setup</BodyL>
          <ProgressBar
            completed={totallyInfos}
            maxCompleted={9}
            bgColor={COLORS.brandPrimary}
            baseBgColor={COLORS.grey600}
            labelColor={COLORS.brandPrimary}
            width={`${toRem(441)}`}
            height={`${toRem(4)}`}
            borderRadius={`${toRem(25)}`}
            customLabel=''
          />
          <BodyL $color='brandPrimary'>
            {totallyInfos === 0 ? '' : totallyInfos + 1}0%
          </BodyL>
        </EventSetupAndProgressBarContainer>
        <FilledButton
          color='white-dark'
          isLoading={isLoading}
          onClick={handlePublishEvent}
          disabled={totallyInfos < 9 || !allowPublishEvent}
        >
          Publish Event
        </FilledButton>
      </PublishEventContainer>
    </Container>
  );
};

export default EditEvent;
