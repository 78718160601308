import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  legend {
    padding: 0;
    display: table;
    margin: 0;
  }

  span[class^="i-icon"] {
    &.reset {
      &::before {
        display: none;
      }
    }
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }
`;
