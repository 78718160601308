import { Country, State } from 'country-state-city';

import { ClubView } from 'admin/models/club/Club';
import UserType from 'models/User/UserTypeModel';
import { JoinClubRole } from 'models/v3/Club/ClubModel';

import { OptionsType } from 'components/v3/Forms/Select/Select';

export const getCountriesOptions = (): OptionsType[] => {
  const countries = Country.getAllCountries();

  return countries.map((country) => ({
    label: `${country.name} ${country.flag}`,
    value: country.isoCode,
  }));
};

export const getCountryStatesOptions = (countryCode: string): OptionsType[] => {
  const states = State.getStatesOfCountry(countryCode);

  return states.map((state) => ({
    label: state.name,
    value: state.isoCode,
  }));
};

export const getClubsOptions = (clubs: ClubView[]): OptionsType[] => {
  return clubs.map((club) => ({
    label: club.name,
    value: club.id,
  }));
};

export const convertUserTypesToClubRole = (
  userType: UserType
): Maybe<JoinClubRole> => {
  if (userType === UserType.PLAYER) {
    return 'player';
  }

  if (userType === UserType.COACH) {
    return 'coach';
  }

  if (userType === UserType.TEAM_MANAGER) {
    return 'team-manager';
  }

  if (userType === UserType.CLUB_DIRECTOR) {
    return 'club-director';
  }

  return null;
};
