import React, { FC, memo, useMemo } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import * as S from './styles';

interface ITooltipAppearsProps {
  enabled?: boolean;
  title: string;
  fullWidth?: boolean;
  offsetBottom?: boolean;
  placement?: TooltipPlacement;
  children: JSX.Element;
}

export const TooltipAppears: FC<ITooltipAppearsProps> = memo(
  ({
    enabled = false,
    title,
    placement = 'top',
    fullWidth = false,
    offsetBottom = false,
    children,
  }) => {
    const classes = useMemo(() => {
      let classesList = '';

      if (fullWidth) {
        classesList += 'full-width ';
      }

      if (offsetBottom) {
        classesList += 'offset-bottom ';
      }

      return classesList;
    }, [fullWidth, offsetBottom]);

    if (enabled) {
      return (
        <Tooltip placement={placement} title={title}>
          <S.Wrapper className={classes}>{children}</S.Wrapper>
        </Tooltip>
      );
    }

    return children;
  }
);

TooltipAppears.displayName = 'TooltipAppears';
