import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';
import { COLORS, BodyM } from 'styles/v3/variables';

export const Container = styled.div`
  max-width: ${toRem(636)};
  width: 100%;
  margin: 0 auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: ${toRem(24)};
  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey950};
`;

export const Subtitle = styled(BodyM)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  gap: 12px;
  text-transform: uppercase;
  color: ${COLORS.brandPrimary};

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.grey800};
  }
`;

export const GroupFields = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;

  & > div {
    width: 100%;
  }
`;
