import React, { FC, useRef, useState, useMemo } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { sideModalCloseModal } from 'redux/sideModal/actions';
import { ApplicationState } from 'redux/store';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';

import { EventMatchSettings } from 'admin/models/event/Event';
import FilledButton from 'components/v3/Buttons/FilledButton';
import InputText from 'components/v3/Forms/InputText/InputText';
import { InputFormat } from 'components/v3/Forms/InputFormat/InputFormat';
import { TooltipAppears } from 'components/TooltipAppears/TooltipAppears';

import { BodyLBold, BodyMBold, BodyS } from 'styles/v3/variables';

import { pushSubmitErrorNotification } from '../utils';

import * as S from './styles';

const MatchSettings: FC = () => {
  const params: { eventId: string } = useParams();

  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const formRef = useRef<HTMLFormElement>(null);

  const dispatch = useDispatch();

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const [errors, setErrors] = useState({
    pointsToWin: false,
    pointsToDraw: false,
    pointsToLose: false,
    pointsToShutout: false,
    recordForForfeit: false,
    poolGamesLength: false,
    bracketGamesLength: false,
    maxTimeBetweenGames: false,
    minTimeBetweenGames: false,
    maxGamesPerTeam: false,
  });

  const isPublishedEvent = useMemo(() => {
    return !eventData.draft;
  }, [eventData]);

  const matchSettingsData = useMemo(() => {
    return {
      data: {
        matchSettings: {
          pointsToWin: eventData?.data?.matchSettings?.pointsToWin ?? 3,
          pointsToDraw: eventData?.data?.matchSettings?.pointsToDraw ?? 1,
          pointsToLose: eventData?.data?.matchSettings?.pointsToLose ?? 0,
          pointsToShutout:
            eventData?.data?.matchSettings?.pointsToShutout ?? undefined,
          recordForForfeit:
            eventData?.data?.matchSettings?.recordForForfeit ?? undefined,
          poolGamesLength:
            eventData?.data?.matchSettings?.poolGamesLength ?? undefined,
          bracketGamesLength:
            eventData?.data?.matchSettings?.bracketGamesLength ?? undefined,
          maxTimeBetweenGames:
            eventData?.data?.matchSettings?.maxTimeBetweenGames ?? undefined,
          minTimeBetweenGames:
            eventData?.data?.matchSettings?.minTimeBetweenGames ?? undefined,
          maxGamesPerTeam:
            eventData?.data?.matchSettings?.maxGamesPerTeam ?? undefined,
        },
      },
    };
  }, [eventData]);

  const validateNumbers = (formData: FormData) => {
    let isValid = true;
    
    const erorrObject = {
      pointsToWin: !(!!formData.get('pointsToWin') && Number(formData.get('pointsToWin')) > 0),
      pointsToDraw: !(!!formData.get('pointsToDraw') && Number(formData.get('pointsToDraw')) > 0),
      pointsToLose: !(!!formData.get('pointsToLose') && Number(formData.get('pointsToLose')) > -1),
      pointsToShutout: !(!!formData.get('pointsToShutout') && Number(formData.get('pointsToShutout')) > 0),
      recordForForfeit: !(!!formData.get('recordForForfeit') && Number(formData.get('recordForForfeit')) > 0),
      poolGamesLength: !(!!formData.get('poolGamesLength') && Number(formData.get('poolGamesLength')) > 5),
      bracketGamesLength: !(!!formData.get('bracketGamesLength') && Number(formData.get('bracketGamesLength')) > 5),
      maxTimeBetweenGames: !(!!formData.get('maxTimeBetweenGames') && Number(formData.get('maxTimeBetweenGames')) > 5),
      minTimeBetweenGames: !(!!formData.get('minTimeBetweenGames') && Number(formData.get('minTimeBetweenGames')) > 5),
      maxGamesPerTeam: !(!!formData.get('maxGamesPerTeam') && Number(formData.get('maxGamesPerTeam')) > 0),
    };

    const values = Object.values(erorrObject)
    isValid = values.every((item) => !item);
    setErrors(erorrObject);
    return isValid;
  };

  const handleSave = async () => {
    try {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        const matchSettings: Partial<EventMatchSettings> = {};

        const isValid = validateNumbers(formData);

        if (!isValid) {
          pushSubmitErrorNotification();
          return;
        }

        formData.forEach((value, key) => {
          if (Object.keys(matchSettingsData.data.matchSettings).includes(key)) {
            matchSettings[key as keyof EventMatchSettings] = value
              ? Number(value)
              : undefined;
          }
        });

        const payload = {
          data: {
            matchSettings,
          },
        };

        await mutateAsync({ id: eventId, data: payload });

        dispatch(sideModalCloseModal());
      }
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  return (
    <S.MatchSettingsWrapper>
      <S.MatchSettingsFormWrapper>
        <S.MatchSettingsForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <BodyLBold $color='white'>Pointing System</BodyLBold>
          <fieldset>
            <InputFormat
              id='pointsToWin'
              label='Points for Win'
              placeholder='Points for Win'
              error={errors.pointsToWin}
              errorMessage='Points to win must be greater than 0'
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToWin ?? ''
              }
              key={`pointsToWin_${String(
                matchSettingsData.data.matchSettings.pointsToWin ?? ''
              )}`}
              min={1}
              className='general-event-input'
              allowLeadingZeros={false}
              allowNegative={false}
              prefix=''
            />
          </fieldset>
          <fieldset>
            <InputFormat
              id='pointsToDraw'
              label='Points for Draw'
              placeholder='Points for Draw'
              error={errors.pointsToDraw}
              errorMessage='Points to draw must be greater than 0'
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToDraw ?? ''
              }
              key={`pointsToDraw_${String(
                matchSettingsData.data.matchSettings.pointsToDraw ?? ''
              )}`}
              min={1}
              className='general-event-input'
              allowNegative={false}
              prefix=''
            />
          </fieldset>
          <fieldset>
            <InputFormat
              id='pointsToLose'
              label='Points for Lose'
              placeholder='Points for Lose'
              errorMessage='Points to lose is required'
              error={errors.pointsToLose}
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToLose ?? ''
              }
              key={`pointsToLose_${String(
                matchSettingsData.data.matchSettings.pointsToLose ?? ''
              )}`}
              className='general-event-input'
              min={1}
              allowNegative={false}
              prefix=''
            />
          </fieldset>
          <fieldset>
            <InputText
              id='pointsToShutout'
              label='Points for Shutout'
              placeholder='Points for Shutout'
              type='number'
              errorMessage='Points for shutout must be greater than 0'
              error={errors.pointsToShutout}
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToShutout ?? ''
              }
              key={`pointsToShutout_${String(
                matchSettingsData.data.matchSettings.pointsToShutout ?? ''
              )}`}
              className='general-event-input'
            />
          </fieldset>
          <fieldset>
            <InputText
              id='recordForForfeit'
              label='Record for Forfeit (W.O.)'
              placeholder='Record for Forfeit (W.O.)'
              errorMessage='Record for Forfeit must be greater than 0'
              error={errors.recordForForfeit}
              type='number'
              defaultValue={
                matchSettingsData.data.matchSettings.recordForForfeit ?? ''
              }
              key={`recordForForfeit_${String(
                matchSettingsData.data.matchSettings.recordForForfeit ?? ''
              )}`}
              className='general-event-input'
            />
          </fieldset>
          <BodyLBold $color='white'>Schedule</BodyLBold>
          <BodyMBold $color='grey200'>Game length</BodyMBold>
          <S.MatchSettingsFieldset>
            <fieldset>
              <TooltipAppears
                enabled={isPublishedEvent}
                placement='top'
                title='This field cannot be changed when the event is published'
              >
                <InputText
                  id='poolGamesLength'
                  label='Pool Games'
                  placeholder='Pool Games'
                  type='number'
                  error={errors.poolGamesLength}
                  errorMessage='Pool Games length must be greater than 5'
                  key={`poolGamesLength_${String(
                    matchSettingsData.data.matchSettings.poolGamesLength ?? ''
                  )}`}
                  defaultValue={
                    matchSettingsData.data.matchSettings.poolGamesLength ?? ''
                  }
                />
              </ TooltipAppears>
              <BodyS className='input-info'>In minutes</BodyS>
            </fieldset>
            <fieldset>
              <TooltipAppears
                enabled={isPublishedEvent}
                placement='top'
                title='This field cannot be changed when the event is published'
              >
                <InputText
                  id='bracketGamesLength'
                  label='Bracket Games'
                  placeholder='Bracket Games'
                  type='number'
                  error={errors.bracketGamesLength}
                  errorMessage='Bracket Games length must be greater than 5'
                  key={`bracketGamesLength_${String(
                    matchSettingsData.data.matchSettings.bracketGamesLength ?? ''
                  )}`}
                  defaultValue={
                    matchSettingsData.data.matchSettings.bracketGamesLength ?? ''
                  }
                />
              </TooltipAppears>
              <BodyS className='input-info'>In minutes</BodyS>
            </fieldset>
          </S.MatchSettingsFieldset>
          <BodyMBold $color='grey200'>Time between games</BodyMBold>
          <fieldset>
            <TooltipAppears
              enabled={isPublishedEvent}
              placement='top'
              fullWidth
              title='This field cannot be changed when the event is published'
            >
              <InputText
                id='maxTimeBetweenGames'
                label='Max time between games per team'
                placeholder='Max time between games per team'
                error={errors.maxTimeBetweenGames}
                errorMessage='Max time between games per team must be greater than 5'
                type='number'
                key={`maxTimeBetweenGames_${String(
                  matchSettingsData.data.matchSettings.maxTimeBetweenGames ?? ''
                )}`}
                defaultValue={
                  matchSettingsData.data.matchSettings.maxTimeBetweenGames ?? ''
                }
              />
            </TooltipAppears>
            <BodyS className='input-info'>In minutes</BodyS>
          </fieldset>
          <fieldset>
            <TooltipAppears
              enabled={isPublishedEvent}
              placement='top'
              fullWidth
              title='This field cannot be changed when the event is published'
            >
              <InputText
                id='minTimeBetweenGames'
                label='Min time between games per team'
                error={errors.minTimeBetweenGames}
                errorMessage='Min time between games per team must be greater than 5'
                type='number'
                key={`minTimeBetweenGames_${String(
                  matchSettingsData.data.matchSettings.minTimeBetweenGames ?? ''
                )}`}
                defaultValue={
                  matchSettingsData.data.matchSettings.minTimeBetweenGames ?? ''
                }
              />
            </TooltipAppears>
            <BodyS className='input-info'>In minutes</BodyS>
          </fieldset>
          <fieldset>
            <TooltipAppears
              enabled={isPublishedEvent}
              placement='top'
              fullWidth
              title='This field cannot be changed when the event is published'
            >
              <InputFormat
                id='maxGamesPerTeam'
                label='Max games per team per day'
                placeholder='Max games per team per day'
                key={`maxGamesPerTeam_${String(
                  matchSettingsData.data.matchSettings.maxGamesPerTeam ?? ''
                )}`}
                defaultValue={
                  matchSettingsData.data.matchSettings.maxGamesPerTeam ?? ''
                }
                allowNegative={false}
                error={errors.maxGamesPerTeam}
                errorMessage='Max games per team per day must be greater than 0'
                prefix=''
              />
            </TooltipAppears>
          </fieldset>
          <FilledButton
            isUpper
            color='primary'
            onClick={handleSave}
            isLoading={isLoading}
          >
            Save {isLoading && '...'}
          </FilledButton>
        </S.MatchSettingsForm>
      </S.MatchSettingsFormWrapper>
    </S.MatchSettingsWrapper>
  );
};

export default withRouter(MatchSettings);
