import { Col, Row } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MembershipSelection from '../../components/MembershipSelection/MembershipSelection';
import {
  ErrorValidationBox,
  NextButtonsWrapper,
  TopHeader,
} from '../Wizard/WizardComponents.style';
import { membershipsFetchRequest } from '../../redux/memberships/actions';
import { userFetchRequest } from '../../redux/user/actions';
import { getUserId } from '../../util/UsersUtil';

import { ApplicationState } from '../../redux/store';
import { UserType } from '../../redux/user/types';
import { newUserReset } from '../../redux/newUser/actions';

const MembershipUpgrade: React.FunctionComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routeParams: { userId?: string } = useParams();

  const [validationError, setValidationError] = useState(false);
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;

  const currentMembership = useSelector(
    (state: ApplicationState) => state.currentUser.data.membershipId
  );

  useEffect(() => {
    dispatch(newUserReset());
    dispatch(membershipsFetchRequest());
    const userId = routeParams.userId || getUserId();
    if (userId) {
      dispatch(userFetchRequest(userId));
    }
  }, [dispatch]);

  // Trick to remove error message
  function handleClick() {
    setValidationError(false);
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const submit = () => {
    if (
      newUser?.type === UserType.COACH ||
      newUser?.type === UserType.CLUB_DIRECTOR
    ) {
      if (
        newUser?.membershipId &&
        newUser?.membershipPrice !== 0 &&
        currentMembership !== newUser?.membershipId
      ) {
        history.push(
          `/membership-upgrade-extra-info/${routeParams.userId || ''}`
        );
      } else {
        setValidationError(true);
      }
    } else if (
      newUser?.membershipId &&
      newUser?.membershipPrice !== 0 &&
      currentMembership !== newUser?.membershipId
    ) {
      history.push(`/membership-upgrade-payment/${routeParams.userId || ''}`);
    } else {
      setValidationError(true);
    }
  };

  return (
    <div>
      <TopHeader>
        <h5>UPGRADE YOUR MEMBERSHIP!</h5>
        <h1>UPGRADE YOUR MEMBERSHIP</h1>
      </TopHeader>
      <Row>
        <Col
          xxl={18}
          lg={18}
          md={24}
          className='column-wizard'
          style={{ maxWidth: '1200px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <MembershipSelection upgradeMembership />
          </div>
          <ErrorValidationBox
            style={{ textAlign: 'center', marginTop: '20px' }}
          >
            {validationError && 'You must select a membership'}
          </ErrorValidationBox>
          <NextButtonsWrapper>
            <div
              className='link'
              onClick={() => {
                submit();
              }}
              onKeyDown={() => {
                submit();
              }}
              role='button'
              aria-label='Submit'
              tabIndex={0}
            >
              NEXT ►
            </div>
          </NextButtonsWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default MembershipUpgrade;
