import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px 12px;
  border-bottom: 1px solid ${COLORS.grey900};
`;

export const Logotype = styled.div`
  width: 45px;
  height: 45px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const LoginButton = styled.button`
  font-family: 'Boxed Medium';
  font-size: 24px;
  color: ${COLORS.brandPrimary};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
