import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 36px 0;
`;

export const Title = styled.span`
  width: fit-content;
  font-family: 'Boxed Regular Bold';
  font-size: 126px;
  line-height: 126px;
`;

export const Subtitle = styled.span`
  width: fit-content;
  font-family: 'Boxed Regular';
  font-size: 18px;
  padding-bottom: 22px;
`;

export const RedirectButton = styled.button`
  min-width: 180px;
  padding: 10px 0;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: 'Boxed Medium';
  color: ${COLORS.grey1000};
  background-color: ${COLORS.brandPrimary};
  cursor: pointer;
`;
