import React, { FC, useState, useEffect } from 'react';

import ColorPicker from 'components/base/ColorPicker/ColorPicker';

import { BodyM } from 'styles/v3/variables';

import InputText, { InputTextProps } from '../InputText/InputText';

import * as S from './styles';

interface IInputColorProps extends InputTextProps {
  defaultColor: string;
  showHex?: boolean;
}

const InputColor: FC<IInputColorProps> = ({
  defaultColor,
  showHex = true,
  placeholder,
  disabled = false,
  ...props
}) => {
  const [color, setColor] = useState<string>(defaultColor);

  useEffect(() => {
    if (defaultColor) {
      setColor(defaultColor);
    }
  }, [defaultColor]);

  return (
    <ColorPicker
      className='color-picker'
      selectedColor={color}
      onSelectColor={(c) => setColor(c)}
      disabled={disabled}
    >
      <S.InputWrapper>
        <InputText
          {...props}
          autoComplete='false'
          placeholder={color ? '' : placeholder}
          label='Pick a color'
          value={color}
        />
        <S.SelectedColor $color={color} />
        {showHex && <BodyM>{color}</BodyM>}
      </S.InputWrapper>
    </ColorPicker>
  );
};

export default InputColor;
