import React, { FC, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MembershipModel from 'models/Membership/MembershipModel';

import MembershipsServices from 'services/Memberships/MembershipsServices';

import {
  newUserUpdateMembership,
  newUserUpdateType,
} from 'redux/newUser/actions';
import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';

import MembershipSelectionWrapper from './MembershipSelection.style';

interface IMembershipSelectionProps {
  upgradeMembership?: boolean;
}

const MembershipSelection: FC<IMembershipSelectionProps> = ({
  upgradeMembership,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [memberships, setMemberships] = useState<MembershipModel[]>([]);

  const user = useSelector((state: ApplicationState) => state.currentUser).data;

  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;

  const currentMembership = useSelector(
    (state: ApplicationState) => state.currentUser.data?.membershipId
  );

  const membershipId = useSelector((state: ApplicationState) => state.newUser)
    .data.newUser?.membershipId;

  useEffect(() => {
    dispatch(newUserUpdateMembership({}));
  }, [dispatch]);

  useEffect(() => {
    if (upgradeMembership) {
      dispatch(newUserUpdateType(user.type ? user.type : UserType.PLAYER));
      dispatch(
        newUserUpdateMembership({
          id: user.membershipId,
          price: user.membershipPrice,
        })
      );
    }
  }, [user, dispatch, upgradeMembership]);

  useEffect(() => {
    if (membershipId) {
      dispatch(
        newUserUpdateMembership({
          id: membershipId,
          price: memberships.find((e: any) => e.id === membershipId)?.price,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships, membershipId, dispatch]);

  const selectMembership = useCallback(
    (membershipType: string, membershipPrice: number) => {
      dispatch(
        newUserUpdateMembership({
          id: membershipType,
          price: membershipPrice,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (newUser?.type === UserType.CLUB_DIRECTOR) {
      selectMembership(' 5f9307f0666e7034d719f1f5', 0);
      history.push('/wizard/terms');
    } else {
      MembershipsServices.getMemberships().then((res) => {
        const filtred =
          res.data.content.filter(
            (membership: MembershipModel) =>
              membership.userType === newUser?.type &&
              membership.country === newUser?.address?.country
          ).length > 0
            ? res.data.content.filter(
                (membership: MembershipModel) =>
                  membership.userType === newUser?.type &&
                  membership.country === newUser?.address?.country
              )
            : // If there is no Membership from current Country, show the US ones
              res.data.content.filter(
                (membership: MembershipModel) =>
                  membership.userType === newUser?.type &&
                  membership.country === 'US'
              );

        setMemberships(filtred);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser?.type, newUser?.address?.country, history, selectMembership]);

  const isCurrentMembership = useCallback(
    (membership: MembershipModel) => {
      const currentMembershipObj = memberships?.find(
        (memship) => memship.id === currentMembership
      );

      if (!currentMembershipObj) {
        return false;
      }

      return (
        currentMembershipObj.price > 0 &&
        membership.price > currentMembershipObj.price
      );
    },
    [memberships]
  );

  return (
    <MembershipSelectionWrapper data-testid='membership-select'>
      <div className='type-wrapper'>
        {memberships
          .sort((a, b) => (a.price > b.price ? 1 : -1))
          .map(
            (membership) =>
              // If it is upgradeMembership, does not display FREE memberships
              (!upgradeMembership ||
                (upgradeMembership && membership.price !== 0)) && (
                <div
                  className={`type-box ${
                    newUser?.membershipId === membership.id ? 'active' : ''
                  } ${
                    membership.id === currentMembership && upgradeMembership
                      ? 'current'
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      membership.id !== currentMembership ||
                      !upgradeMembership
                    ) {
                      selectMembership(membership.id, membership.price);
                    }
                  }}
                  onKeyDown={() => {
                    if (
                      membership.id !== currentMembership ||
                      !upgradeMembership
                    ) {
                      selectMembership(membership.id, membership.price);
                    }
                  }}
                  role='button'
                  aria-label='Select Membership'
                  tabIndex={0}
                  key={membership.id}
                >
                  <div className='top'>
                    {membership.name} <span>MEMBERSHIP</span>
                  </div>
                  <div className='content'>
                    {membership.price === 0 ? (
                      'FREE'
                    ) : (
                      <>
                        {isCurrentMembership(membership) ? (
                          <>
                            <span>
                              <span
                                style={{
                                  color: '#ccc',
                                  textDecoration: 'line-through',
                                }}
                              >
                                ${membership.price.toFixed(2)}{' '}
                              </span>
                            </span>
                            <span>
                              $
                              {(
                                membership.price -
                                (memberships.find(
                                  (e: any) => e.id === currentMembership
                                )?.price || 0)
                              ).toFixed(2)}
                            </span>{' '}
                            PER YEAR
                          </>
                        ) : (
                          <>
                            <span>${membership.price.toFixed(2)}</span> PER YEAR
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )
          )}
      </div>
      {newUser?.type === UserType.PLAYER && (
        <div className='description-box'>
          <li
            className={
              newUser?.membershipPrice === 0 ||
              newUser?.membershipPrice === 25 ||
              newUser?.membershipPrice === 60
                ? 'active'
                : ''
            }
          >
            <ul>Vault Player Profile.</ul>
            <ul>View upcoming events.</ul>
            <ul>Access to photos and media from United Futsal events</ul>
          </li>
          <li
            className={
              newUser?.membershipPrice === 25 || newUser?.membershipPrice === 60
                ? 'active'
                : ''
            }
          >
            <ul>Discounts on United Futsal Merchandise</ul>
            <ul>Industry - Leading Insurance</ul>
            <ul>
              Participate in any Officially Sanctioned United Futsal Leagues and
              Tournaments
            </ul>
            <ul>Earn XP to LEVEL UP your player profile</ul>
            <ul>StatTracker+TM System for tracking goals and other metrics.</ul>
          </li>
        </div>
      )}
      {newUser?.type === UserType.COACH && (
        <div className='description-box'>
          <li
            className={
              newUser?.membershipPrice === 0 || newUser?.membershipPrice === 25
                ? 'active'
                : ''
            }
          >
            <ul>Vault Coach Profile</ul>
            <ul>View upcoming events.</ul>
            <ul>Access to photos and media from United Futsal events</ul>
          </li>
          <li className={newUser?.membershipPrice === 25 ? 'active' : ''}>
            <ul>Discounts on United Futsal Merchandise</ul>
            <ul>Industry - Leading Insurance</ul>
            <ul>
              Participate in any Officially Sanctioned United Futsal Leagues and
              Tournaments
            </ul>
            <ul>Earn XP to LEVEL UP your Coach profile</ul>
          </li>
        </div>
      )}
    </MembershipSelectionWrapper>
  );
};

export default MembershipSelection;
