import React, { FC, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ApplicationState } from 'redux/store';

import usePrimaryClub from 'hooks/usePrimaryClub/usePrimaryClub';

import DateUtil from 'util/DateUtil';

import CustomTransition from '../base/CustomTransition/CustomsTransition';
import InfoProfileItem from '../base/InfoProfileItem/InfoProfileItem';

const MyInfoGroup: FC = () => {
  const user = useSelector((state: ApplicationState) => state.currentUser);
  const club = useSelector((state: ApplicationState) => state.club);

  const [countItems, setCountItems] = useState(0);

  const primaryClub = usePrimaryClub();

  useEffect(() => {
    let t = 0;

    if (primaryClub?.id) {
      t += 1;
    }

    if (user.data.birthDate) {
      t += 1;
    }

    if (user.data.playerInfo?.preferredShirtNumber) {
      t += 1;
    }

    if (user.data.playerInfo?.height) {
      t += 1;
    }

    setCountItems(t);
  }, [user, club, primaryClub]);

  const birthDay = useMemo(() => {
    if (typeof user.data.birthDate === 'string') {
      return new Date(user.data.birthDate.toString());
    }

    return new Date();
  }, [user]);

  return (
    <Wrapper height={countItems * 125}>
      {primaryClub && (
        <CustomTransition transitionIn={!!primaryClub}>
          <InfoProfileItem
            title='club'
            primaryText={`${primaryClub.city}, ${primaryClub.country}`}
            mainText={primaryClub.name}
          />
        </CustomTransition>
      )}
      {user.data?.birthDate && (
        <CustomTransition transitionIn={!!user.data.birthDate}>
          <InfoProfileItem
            title='age'
            primaryText={DateUtil.getDayYear(birthDay).toString()}
            mainText={DateUtil.getAge(birthDay).toString()}
            secondaryText={[new Date(birthDay).getUTCFullYear().toString()]}
          />
        </CustomTransition>
      )}
      {user.data.playerInfo?.height && (
        <CustomTransition transitionIn={!!user.data.playerInfo?.height}>
          <InfoProfileItem
            title='height'
            primaryText={`${user.data.address?.city}, ${user.data.address?.country}`}
            mainText={`${user.data.playerInfo?.height.toString()} m`}
          />
        </CustomTransition>
      )}
      {user.data.playerInfo?.preferredShirtNumber && (
        <CustomTransition
          transitionIn={!!user.data.playerInfo?.preferredShirtNumber}
        >
          <InfoProfileItem
            title='position'
            primaryText=''
            mainText={user.data.playerInfo?.preferredShirtNumber?.toString()}
            secondaryText={[
              user.data.playerInfo?.preferredPosition
                ? user.data.playerInfo?.preferredPosition
                : '',
            ]}
          />
        </CustomTransition>
      )}
    </Wrapper>
  );
};

const Wrapper =
  styled.div <
  { height: number } >
  `
  transition: all 1s;
  overflow: hidden;
  height: ${(props) => (props.height ? `${props.height}px` : '0px')};
`;

export default MyInfoGroup;
