import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import goldLogotype from 'assets/imgs/logos/logo-vault-green.png';

import * as S from './styles';

const NonRegisteredHeader = memo(() => {
  const history = useHistory();

  const handleLoginRedirect = useCallback(() => {
    history.replace('/v3/login');
  }, [history]);

  return (
    <S.Header>
      <S.Logotype>
        <img src={goldLogotype} alt='Vault' />
      </S.Logotype>
      <S.LoginButton onClick={handleLoginRedirect}>Login</S.LoginButton>
    </S.Header>
  );
});

NonRegisteredHeader.displayName = 'NonRegisteredHeader';

export default NonRegisteredHeader;
