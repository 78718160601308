import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';

import { roleCheck } from 'util/RoleCheck';

const TournamentPage: FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const history = useHistory();

  const user = useSelector((state: ApplicationState) => state.currentUser);

  if (roleCheck(user.data, UserType.REFEREE, true)) {
    history.push(`/admin-panel/tournaments/${eventId}/games`);
  }

  if (roleCheck(user.data, UserType.STAFF)) {
    history.push(`/admin-panel/tournaments/${eventId}/team-applications`);
  }

  return <></>;
};

export default TournamentPage;
