import React, { FC } from 'react';
import { Delete as DeleteIcon, Edit as EditIcon } from '@icon-park/react';

import { BodyLBold, BodyM } from 'styles/v3/variables';

import RoundedCard, { RoundedCardProps } from '../RoundedCard/RoundedCard';

import * as S from './styles';

interface IModalCardProps extends RoundedCardProps {
  title: string;
  description: string;
  stripeColor?: string;
  isPurchased?: boolean;
  handleDelete?: (e: any) => void;
  handleEdit?: (e: any) => void;
}

const ModalCard: FC<IModalCardProps> = ({
  title,
  description,
  size,
  orientation,
  children,
  stripeColor,
  isPurchased = false,
  handleDelete,
  handleEdit,
  ...props
}) => {
  return (
    <S.ModalCardWrapper className='card-detail'>
      <RoundedCard {...props} size={size} orientation={orientation}>
        <S.Stripe $color={stripeColor} />
        <S.InfoContainer>
          <BodyLBold>{title}</BodyLBold>
          <BodyM>{description}</BodyM>
        </S.InfoContainer>
        <S.IconsWrapper>
          {handleDelete && (
            <S.BgIcon $disabled={isPurchased} onClick={handleDelete}>
              <DeleteIcon className='reset' size='24px' />
            </S.BgIcon>
          )}
          {handleEdit && (
            <S.BgIcon onClick={handleEdit}>
              <EditIcon className='reset' size='24px' />
            </S.BgIcon>
          )}
        </S.IconsWrapper>
      </RoundedCard>
    </S.ModalCardWrapper>
  );
};

export default ModalCard;
