import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  flex: 1 0 0;
`;

export const StyledHeader = styled.div`
  padding-top: 10px;
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 37px;

  &:hover {
    cursor: pointer;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 100%;

  & #select {
    min-width: 200px;
  }
`;

export const TableWrapper = styled.div`
  max-width: 1035px;
  min-width: 800px;

  tbody .ant-table-cell {
    color: ${COLORS.white} !important; // Due to library nesty
  }
`;

export const GameWrapper = styled.div``;
