import { useMutation } from '@tanstack/react-query';

import ClubService from 'services/v3/Clubs/ClubService';

import { IFiltering } from 'pages/V3/Roster/RosterEditPage/components/Modal/RosterSidebarSelection/interfaces/filtering.interface';
import { notification } from 'components/v3/Notification/notification';

export interface IPayloadClubMembersPool {
  clubId: string;
  params?: IFiltering & { validateAccess?: boolean };
}

export const useGetClubMembersPool = () => {
  return useMutation({
    mutationFn: async (payload: IPayloadClubMembersPool) => {
      const { clubId, params = {} } = payload;

      const queryParams = new URLSearchParams();

      const validateAccess = params.validateAccess || false;

      queryParams.set('validateAccess', `${validateAccess}`);

      if (params.gender && params.gender !== 'default') {
        queryParams.set('gender', params.gender);
      }

      if (params.membershipType && params.membershipType !== 'default') {
        queryParams.set('membershipType', params.membershipType);
      }

      if (params.birthYear && params.birthYear !== 'default') {
        queryParams.set('birthYear', params.birthYear.toString());
      }

      if (params.documentStatus && params.documentStatus !== 'default') {
        queryParams.set('documentStatus', params.documentStatus);
      }

      if (params.search?.length) {
        queryParams.set('name', params.search);
      }

      return ClubService.getClubMembersPool(clubId, queryParams)
    },
    onError: async (error) => {
      const { message } = error as Error;

      notification.error({
        message: 'An error occurs!',
        description: message || 'Unexpected error.',
      });
    }
  });
};
