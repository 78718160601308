import React, { FC } from 'react';
import { MatchEventModel, MatchStatus } from 'models/Match/MatchModel';
import { BodyM, BodyXLBold } from 'styles/v3/variables';
import InputSelect from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import moment, { Moment } from 'moment';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useUpdateMatchesEvent } from 'hooks/v3/matches/useUpdateMatchesEvent/useUpdateMatchesEvent';
import { GridStack } from 'gridstack';
import { MatchMainInfo } from 'services/v3/Match/types';
import { useLocation } from 'react-router-dom';
import {
  StyledBottom,
  StyledButton,
  StyledCloseSmall,
  StyledContainer,
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledTimePicker,
} from './styles';
import { CalendarCourt } from '../../types';
import { useGetGameData } from './useGetGameData';
import { ScheduleRules } from '../Scheduler/types';
import { countTeamAppearances } from '../../utils';

type Option = {
  label: string,
  value: string,
};

type FormData = {
  court: Option,
  date: string,
  startTime: Moment,
  homeTeam: Option,
  awayTeam: Option,
};

interface EditGameModalProps {
  days: string[];
  dayStartTime?: string;
  courts: CalendarCourt[];
  selectedMatch: MatchEventModel;
  scheduleRules: ScheduleRules;
  matches: MatchEventModel[];
  grid: GridStack | null;
  matchLength: number;
  divisionColors: { [key: string]: string };
  handleCloseGameEditor: () => void;
  mapPositionToTime: (position: number, stepperIntervals: number) => string;
  getHomeTeamName: (match: MatchEventModel) => string;
  getAwayTeamName: (match: MatchEventModel) => string;
  getMatchTitle: (match: MatchEventModel) => string | string[] | undefined;
}

const EditGameModal: FC<EditGameModalProps> = ({
  days,
  dayStartTime,
  courts,
  selectedMatch,
  matches,
  scheduleRules,
  grid,
  matchLength,
  handleCloseGameEditor,
  getHomeTeamName,
  getAwayTeamName,
  divisionColors,
  getMatchTitle,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const day = params.get('day');
  const scheduledMatch = matches.filter(
    (match) => match.status !== MatchStatus.UNSCHEDULED
  );
  const { mutateAsync: updateSchedule } = useUpdateMatchesEvent();
  const {
    initialAwayTeam,
    awayClubs,
    homeClubs,
    courtsOptions,
    initialCourtSelected,
    initialHomeTeam,
    startTime,
    rostersData,
    initialDateSelected,
    updateWidgets,
    removeWidget,
  } = useGetGameData({
    grid,
    days,
    dayStartTime,
    courts,
    selectedMatch,
    getHomeTeamName,
    getAwayTeamName,
    divisionColors,
    getMatchTitle,
  });

  // TO DO ENG-127 - move get/HomeT.../AwayT/... to utils;
  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      court: initialCourtSelected,
      date: initialDateSelected,
      startTime,
      homeTeam: initialHomeTeam,
      awayTeam: initialAwayTeam,
    },
  });

  const startTimeValue = watch('startTime');
  const date = watch('date')

  // ENG-127 TODO make updates with subm
  const submit = async (data: FormData) => {
    try {
      const { date, court, startTime } = data;

      if (
        !(
          moment(date) >= moment(days[0]) &&
          moment(date) <= moment(days[days.length - 1])
        )
      ) {
        setError('date', {
          message: `Invalid date. Date should be in range ${moment(
            days[0]
          ).format('YYYY-MM-DD')} - ${moment(days[days.length - 1]).format(
            'YYYY-MM-DD'
          )}`,
        });
      } else {
        const time = startTime.format('HH:mm A');

        const newDate = moment(`${date} ${time}`, 'YYYY-MM-DD h:mm A').format(
          'YYYY-MM-DDTHH:mm'
        );

        if (selectedMatch) {
          let matchToUpdateWidget = { ...selectedMatch };

          const { homeTeam, awayTeam } = data;

          let matchInfo: MatchMainInfo = {
            matchId: selectedMatch?.matchId ?? '',
            date: newDate ?? selectedMatch?.date ?? '',
            subVenue: court.value ?? selectedMatch.subVenue ?? '',
          };

          if (homeTeam?.value && homeTeam?.label) {
            matchInfo = {
              ...matchInfo,
              homeTeam: {
                poolId: selectedMatch?.pools[0]?.id,
                teamId: selectedMatch?.homePlaceHolder?.id,
                rosterId: homeTeam?.value ?? selectedMatch.homeTeam.id,
                label: homeTeam?.label ?? selectedMatch.homeTeam.clubName,
              },
            };

            !!matchInfo.homeTeam &&
              (matchToUpdateWidget = {
                ...matchToUpdateWidget,
                homeTeam: {
                  ...matchToUpdateWidget.homeTeam,
                  ...matchInfo.homeTeam,
                  clubName: matchInfo.homeTeam.label,
                },
              });
          }

          if (awayTeam?.value && awayTeam?.label) {
            matchInfo = {
              ...matchInfo,
              awayTeam: {
                poolId: selectedMatch?.pools[0]?.id,
                teamId: selectedMatch?.awayPlaceHolder?.id,
                rosterId: awayTeam?.value ?? selectedMatch.awayTeam.id,
                label: awayTeam?.label ?? selectedMatch.awayTeam.clubId,
              },
            };

            !!matchInfo.awayTeam &&
              (matchToUpdateWidget = {
                ...matchToUpdateWidget,
                awayTeam: {
                  ...matchToUpdateWidget.awayTeam,
                  ...matchInfo.awayTeam,
                  clubName: matchInfo.awayTeam.label,
                },
              });
          }

          if (moment(newDate).format('YYYY-MM-DD') === day) {
            updateWidgets(matchToUpdateWidget, newDate, court.value);
          } else {
            removeWidget(matchToUpdateWidget);
          }

          await updateSchedule([matchInfo]);

          handleCloseGameEditor();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validateTeam = (teamOption: Option) => {
    let isBlocked = false;
    
    if (!teamOption?.value) {
      return 'Team is required';
    }

    const matchesCurrentDivision = scheduledMatch.filter(
      (match) =>
        match?.ageDivisionId === selectedMatch.ageDivisionId &&
        selectedMatch.matchId !== match?.matchId
    );
    const clubId = rostersData?.find(
      (roster) => roster.id === teamOption?.value
    )?.club?.id;


    const matchInfo = countTeamAppearances(matchesCurrentDivision);

    if (!isBlocked && clubId) {
      for (const key in matchInfo) {
        if (clubId === key) {
          if (!isBlocked) {
            isBlocked = matchInfo[key] + 1 > scheduleRules.maxGamesPerTeam;
          }
        }
      }
    }
    
    if (isBlocked) {
      return `Maximum is ${scheduleRules.maxGamesPerTeam} games per team per day`;
    }

    const currentDayMatches = matchesCurrentDivision.filter(
      (match) => day && moment(match?.date).format('YYYY-MM-DD') === day
    );

    if (!isBlocked) {
      currentDayMatches.forEach((match) => {
        const matchStartTime = moment(match?.date ?? '');
        const matchEndTime = matchStartTime
          .clone()
          .add(scheduleRules?.poolGamesLength, 'minutes');

        const currentMatchStartTime = moment(startTimeValue);
        const currentMatchEndTime = currentMatchStartTime
          .clone()
          .add(scheduleRules?.poolGamesLength, 'minutes');

        const isOverlapping =
          currentMatchStartTime.isBefore(matchEndTime) &&
          currentMatchEndTime.isAfter(matchStartTime);

        const timeDifference = Math.abs(
          currentMatchStartTime.diff(matchEndTime, 'minutes')
        );
        const isTooClose = timeDifference < scheduleRules.minTimeBetweenGames;

        if (isOverlapping || isTooClose) {
          isBlocked = true;
        }
      });
    }
    if(isBlocked) {
      return `You need to set at least ${scheduleRules.minTimeBetweenGames} minutes between team matches`;
    }
    return true;
  };

  return (
    <StyledModal>
      <StyledModalHeader>
        <BodyXLBold $color='brandPrimary'>Update Game</BodyXLBold>
        <StyledCloseSmall size='20px' onClick={handleCloseGameEditor} />
      </StyledModalHeader>

      <form onSubmit={handleSubmit(submit)} noValidate>
        <StyledModalBody>
          <StyledContainer>
            <BodyM $color='white'>Location</BodyM>
            <Controller
              control={control}
              name='court'
              render={({ value, onChange }) => (
                <InputSelect
                  placeholder='Location'
                  options={courtsOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Date</BodyM>
            <Controller
              name='date'
              control={control}
              render={({ value, onChange }) => (
                <InputDate
                  id='matchScheduledDate'
                  placeholder='Date'
                  value={value}
                  error={!!errors?.date}
                  errorMessage={errors?.date?.message ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Start time</BodyM>
            <Controller
              name='startTime'
              control={control}
              render={({ value, onChange }) => (
                <StyledTimePicker
                  value={value}
                  use12Hours
                  format='h:mm a'
                  minuteStep={5}
                  showNow={false}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>End time</BodyM>
            <StyledTimePicker
              use12Hours
              format='h:mm a'
              minuteStep={5}
              value={moment(startTimeValue).add(matchLength / 60, 'hours')}
              disabled
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Home team</BodyM>
            <Controller
              control={control}
              name='homeTeam'
              rules={{
                required: true,
                validate: validateTeam,
              }}
              render={({ value, onChange }) => (
                <div>
                  <InputSelect
                    placeholder='Home team'
                    options={homeClubs}
                    value={value}
                    error={!!errors?.homeTeam}
                    onChange={onChange}
                  />
                  {errors?.homeTeam && (
                    <BodyM $color='supportError'>
                      {(errors?.homeTeam as FieldError).message}
                    </BodyM>
                  )}
                </div>
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Away team</BodyM>
            <Controller
              control={control}
              name='awayTeam'
              rules={{
                required: true,
                validate: validateTeam,
              }}
              render={({ value, onChange }) => (
                <div>
                  <InputSelect
                    placeholder='Away team'
                    options={awayClubs}
                    value={value}
                    error={!!errors?.awayTeam}
                    onChange={onChange}
                  />
                  {errors?.awayTeam && (
                    <BodyM $color='supportError'>
                      {(errors?.awayTeam as FieldError).message}
                    </BodyM>
                  )}
                </div>
              )}
            />
          </StyledContainer>
          <StyledBottom>
            <StyledButton type='submit'>
              <BodyM>Save</BodyM>
            </StyledButton>
            <StyledButton type='button'>
              <BodyM onClick={handleCloseGameEditor}>Cancel</BodyM>
            </StyledButton>
          </StyledBottom>
        </StyledModalBody>
      </form>
    </StyledModal>
  );
};

export default EditGameModal;
