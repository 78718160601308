import * as React from 'react';
import Select, { Props } from 'react-select';
import AsyncSelect from 'react-select/async';

import { BodySBold } from 'styles/v3/variables';
import * as S from './styles';

export interface OptionsType<T = string | number> {
  readonly label: string;
  readonly value: T;
}

type Option = OptionsType;

interface SelectProps extends Props<Option> {
  error?: boolean;
  isSearchable?: boolean;
  isAsync?: boolean;
  info?: string;
  loadOptions?: (v: string) => void;
  errorMessage?: string;
}

function InputSelect<Option>(props: SelectProps) {
  const {
    required,
    error,
    isAsync,
    loadOptions,
    isSearchable,
    isDisabled,
    info,
    errorMessage,
    ...rest
  } = props;

  const renderContainer = () => (
    <S.SelectWrapper $required={required} $error={error}>
      {isAsync ? (
        <AsyncSelect
          {...rest}
          isDisabled={isDisabled}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          isSearchable={isSearchable ?? false}
          classNamePrefix='vault-select'
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      ) : (
        <Select
          {...rest}
          isDisabled={isDisabled}
          isSearchable={isSearchable ?? false}
          classNamePrefix='vault-select'
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      )}
    </S.SelectWrapper>
  );

  return (
    <S.ContainerWrapper>
      {renderContainer()}
      {error && errorMessage && (
        <BodySBold $color='supportError'>{errorMessage}</BodySBold>
      )}
    </S.ContainerWrapper>
  );
}

export default InputSelect;
