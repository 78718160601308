import { Gender } from 'admin/models/Enums';
import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { BaseUser } from 'redux/v3/baseUsers/types';

export enum MembershipTypes {
  Community = 'COMMUNITY',
  Official = 'OFFICIAL',
}

export interface GetProfiles {
  baseUsers: BaseUser[];
  users: User[];
}

export interface IAdvancedFilters {
  birthYears: string[];
  countries: string[];
  cities: string[];
  states: string[];
}

export interface IFilteredUserParameters {
  gender?: Gender[];
  birthYear?: number[];
  country?: string[];
  state?: string[];
  city?: string[];
  membershipType?: MembershipTypes[];
  name?: string;
  userType?: UserType;
}
