import { UserType } from 'redux/user/types';

export const AGE_RULES: Record<UserType, number> = {
  [UserType.PLAYER]: 0,
  [UserType.COACH]: 13,
  [UserType.CLUB_DIRECTOR]: 16,
  [UserType.REFEREE]: 13,
  [UserType.TOURNAMENT_DIRECTOR]: 0,
  [UserType.STAFF]: 0,
  [UserType.TEAM_MANAGER]: 13,
};
