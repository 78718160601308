import React, { useEffect, useRef } from 'react';
import { BodySBold } from 'styles/v3/variables';
import moment from 'moment';
import { TableContainer } from './styles';
import { TableProps } from './types';
import { useGetTimeData } from './hooks/useGetTimeData';
import {  stepperIntervals } from './lib';
import InfoTooltip from './InfoTooltip';
import ErrorTooltip from './ErrorTooltip';
import useScheduleTableConfig from './hooks/useScheduleTableConfig';

function Table({
  page,
  source,
  cellHeight,
  error,
  errorElement,
  visibleCourts,
  scheduledMatch,
  grid,
  pagesCount,
  setEditModalOpen,
  setSelectedMatch,
  divisionColors,
  currentDailyEventTime,
}: TableProps) {
  const timeoutIdRef = useRef<number | null>(null);
  const timeoutErrorIdRef = useRef<number | null>(null);
  const { fromDayStart, countOfLines } = useGetTimeData(
    currentDailyEventTime
  );

  const {
    tableRef,
    infoTooltipRef,
    errorTooltipRef,
    variant,
    info,
    setInfo,
    setTooltipCoordinates,
    handleClick,
  } = useScheduleTableConfig({
    divisionColors,
    grid,
    source,
    scheduledMatch,
    setEditModalOpen,
    setSelectedMatch,
  });

  const printTime = (index: number, intervals: number) => {
    if (index === 0) return '';
    if (index % (60 / intervals) === 0) {
      const hour = moment(`${fromDayStart + index / (60 / intervals)}:00`, [
        'HH',
      ]).format('h A');
      return hour;
    }
    return '';
  };

  const startTime = moment(
    currentDailyEventTime?.startTime ?? '', 'HH:mm:ss'
  ).format('h A');
  const endTime = moment(currentDailyEventTime?.endTime ?? '', 'HH:mm:ss').format('h A');

  const mouseEnterMoveHandler = (e: React.MouseEvent<HTMLElement>) => {
    const widget = e.target as HTMLDivElement | HTMLParagraphElement;
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    const id = widget.closest('.card')?.id;
    if (id && !errorElement) {
      timeoutIdRef.current = setTimeout(() => {
        const element = document.getElementById(id);
        const match = scheduledMatch.find((match) => match.matchId === id);
        if (match) {
          if (match.sourceType === source) {
            setInfo({
              home:
                match?.homeTeam?.clubName ??
                match?.homePlaceHolder?.name ??
                'TBA',
              away:
                match?.awayTeam?.clubName ??
                match?.awayPlaceHolder?.name ??
                'TBA',
              time: moment(match?.date ?? '').format('hh:mm A'),
            });
          } else {
            setInfo({
              home: '',
              away: '',
              time: '',
              message:
                'For schedule this match you should switch the games filter',
            });
          }
        }
        element && setTooltipCoordinates('info', element);
      }, [500]);
    } else {
      const tooltip = infoTooltipRef.current;
      if (tooltip) {
        tooltip.style.display = 'none';
      }
    }
  };

  const mouseLeaveHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    const tooltip = infoTooltipRef.current;
    if (tooltip) {
      tooltip.style.display = 'none';
    }
  };

  useEffect(() => {
    const tooltip = errorTooltipRef.current;
    if (timeoutErrorIdRef.current) {
      clearTimeout(timeoutErrorIdRef.current);
      timeoutErrorIdRef.current = null;
    }

    if (errorElement) {
      setTooltipCoordinates('error', errorElement);
      timeoutErrorIdRef.current = setTimeout(() => {
        if (tooltip) {
          tooltip.style.display = 'none';
        }
      }, [5000]);
    } else if (!errorElement) {
      if (tooltip) {
        tooltip.style.display = 'none';
      }
    }
  }, [errorElement]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo({ top: 700 });
    }
  }, []);

  return (
    <TableContainer
      ref={tableRef}
      onClick={handleClick}
      onMouseEnter={mouseEnterMoveHandler}
      onMouseMove={mouseEnterMoveHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      {Array.from({ length: pagesCount }).map((_, index) => (
        <div
          key={index}
          className={`grid-stack dnd-grid-${index}`}
          style={{
            display: index === page ? 'block' : 'none',
          }}
        >
          <div className='bg-schedule'>
            {Array.from({ length: countOfLines }).map((_, index) => (
              <div
                key={index}
                className={`line ${
                  index % 6 === 0 || index === countOfLines - 1 ? 'dashed' : ''
                }`}
                style={{
                  height: `${cellHeight}px`,
                }}
              >
                {index === 0 && (
                  <BodySBold className='lineTime firstLineTime'>
                    {startTime}
                  </BodySBold>
                )}
                <BodySBold className='lineTime'>
                  {printTime(index, stepperIntervals)}
                </BodySBold>
                {index === countOfLines - 1 && (
                  <BodySBold className='lineTime'>{endTime}</BodySBold>
                )}
              </div>
            ))}
          </div>
          <div
            className='bg-schedule2'
            style={{
              height: `${cellHeight * countOfLines}px`,
            }}
          >
            {visibleCourts.map((court, index) => (
              <div
                key={court.id + court.name}
                className='line'
                style={{
                  width: `calc(((100% - 1px) / ${visibleCourts.length}) )`,
                }}
              >
                <div className='lineTime'>
                  {printTime(index, stepperIntervals)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <InfoTooltip ref={infoTooltipRef} variant={variant} info={info} />
      <ErrorTooltip ref={errorTooltipRef} variant={variant} error={error} />
    </TableContainer>
  );
}

export default Table;
