import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import DraftEventBody from 'components/v3/DraftEvent/Body';
import DraftEventHeader from 'components/v3/DraftEvent/Header';
import SideModalHeader from 'components/v3/SideModal/Header';
import SideModalBody from 'components/v3/SideModal/Body';
import SetupEventHeader from 'components/v3/SetupEvent/Header';
import SetupEventBody from 'components/v3/SetupEvent/Body';
import EventDashboardBody from 'components/v3/EventDashboardLayout/Body';
import Footer from 'components/v3/SetupEvent/Footer';
import EditEventBody from 'components/v3/EditEvent/Body';
import { DraftEventContentWrapper } from 'components/v3/DraftEvent/styles';
import { SideModalContentWrapper } from 'components/v3/SideModal/styles';
import { SetupEventContentWrapper } from 'components/v3/SetupEvent/styles';
import HeaderMenu from 'components/HeaderMenu/HeaderMenu';
import SideMenuFull from 'components/SideMenuFull/SideMenuFull';
import MobileMenu from 'components/MobileMenu/MobileMenu';
import bgImage from 'components/../assets/imgs/bg.png';
import ScrollToTop from 'components/uielements/ScrollToTop/ScrollToTop';
import NonRegisteredHeader from 'components/base/NonRegisteredHeader/NonRegisteredHeader';

import { LayoutsName } from 'routes/enums/layouts-name.enum';

import { color } from 'styles/styleVariables';

import LayoutBaseAdmin from './LayoutBaseAdmin';

import * as S from './styles';

interface ILayoutsProps {
  layout?: LayoutsName;
  auth?: Keycloak.KeycloakInstance | undefined;
  noPaddingOnMobile?: boolean;
}

const Layouts: FC<ILayoutsProps> = ({
  auth,
  children,
  layout,
  noPaddingOnMobile,
}) => {
  const isAuthenticated = useMemo(() => !!auth?.authenticated, [auth]);

  switch (layout) {
    case LayoutsName.Base:
      return <S.BaseWrapper className={layout}>{children}</S.BaseWrapper>;
    case LayoutsName.Error404:
      if (isAuthenticated) {
        return (
          <SetupEventContentWrapper>
            <SetupEventHeader auth={auth} />
            <SetupEventBody noPaddingOnMobile={noPaddingOnMobile}>
              {children}
            </SetupEventBody>
            <Footer />
          </SetupEventContentWrapper>
        );
      }

      return (
        <S.NonRegisterWrapper>
          <NonRegisteredHeader />
          {children}
        </S.NonRegisterWrapper>
      );
    case LayoutsName.BaseDesktop:
      return (
        <S.BaseDesktopWrapper className={layout}>
          {children}
        </S.BaseDesktopWrapper>
      );
    case LayoutsName.DraftEvent:
      return (
        <>
          <DraftEventContentWrapper className={layout}>
            <DraftEventHeader auth={auth} />
            <DraftEventBody>{children}</DraftEventBody>
          </DraftEventContentWrapper>
        </>
      );
    case LayoutsName.SidebarModal:
      return (
        <SideModalContentWrapper className={layout}>
          <SideModalHeader />
          <SideModalBody>{children}</SideModalBody>
        </SideModalContentWrapper>
      );
    case LayoutsName.SetupEvent:
      return (
        <>
          <SetupEventContentWrapper className={layout}>
            <SetupEventHeader auth={auth} />
            <SetupEventBody noPaddingOnMobile={noPaddingOnMobile}>
              {children}
            </SetupEventBody>
            <Footer />
          </SetupEventContentWrapper>
        </>
      );
    case LayoutsName.EditEvent:
      return (
        <SetupEventContentWrapper className={layout}>
          <SetupEventHeader auth={auth} />
          <EditEventBody>{children}</EditEventBody>
        </SetupEventContentWrapper>
      );
    case LayoutsName.Print:
      return <S.PrintWrapper>{children}</S.PrintWrapper>;
    case LayoutsName.Profile:
      return (
        <>
          <SetupEventContentWrapper className={layout}>
            <SetupEventHeader auth={auth} hasMenu={false} />
            <SetupEventBody>{children}</SetupEventBody>
            <Footer />
          </SetupEventContentWrapper>
        </>
      );
    case LayoutsName.EventDashboard:
      return (
        <SetupEventContentWrapper className={layout}>
          <SetupEventHeader auth={auth} />
          <EventDashboardBody>{children}</EventDashboardBody>
          <Footer />
        </SetupEventContentWrapper>
      );
    case LayoutsName.MenuEmpty:
      return (
        <>
          <ScrollToTop />
          <div>
            <HeaderMenu empty />
          </div>
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
    case LayoutsName.NoMenu:
      return (
        <>
          <ScrollToTop />
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
    case LayoutsName.Admin:
      return (
        <>
          <ScrollToTop />
          <div>
            <HeaderMenu />
          </div>
          <div>
            <SideMenuFull />
            <MobileMenu />
          </div>
          <ContentWrapper className={layout}>
            <LayoutBaseAdmin>{children}</LayoutBaseAdmin>
          </ContentWrapper>
        </>
      );
    case LayoutsName.None:
      return (
        <>
          <ScrollToTop />
          <ContentFullEmpty>{children}</ContentFullEmpty>
        </>
      );
    default:
      return (
        <>
          <div>
            <HeaderMenu />
          </div>
          <div>
            <SideMenuFull />
            <MobileMenu />
          </div>
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
  }
};

const ContentWrapper = styled.div`
  padding-left: 72px;
  padding-top: 72px;
  min-height: 100vh;
  background-image: url(${bgImage});
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 80px;
  }

  &.no-menu {
    padding-left: 0;
    padding-top: 0;
  }
`;

const ContentFullEmpty = styled.div`
  min-height: 100vh;
  padding-left: 0;
  padding-top: 0;
  background: #fff;
  color: ${color.greyDark};
`;

export default Layouts;
