import React from 'react';
import {
  Court,
  Dollar,
  Error,
  LinkBreak,
  ListTop,
  MapDraw,
  Ruler,
  SettingTwo,
  Sport,
} from '@icon-park/react';

import { COLORS } from 'styles/v3/variables';

import { SettingInfo } from './types';

export const SETTING_INFO_ARRAY: SettingInfo[] = [
  {
    title: 'Event Info',
    selectedModal: 'eventInfo',
    component: <ListTop size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'General Rules',
    selectedModal: 'generalRules',
    component: <Ruler size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Match Setting',
    selectedModal: 'matchSettings',
    component: <SettingTwo size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Divisions',
    selectedModal: 'divisions',
    component: <Sport size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Venues',
    selectedModal: 'venues',
    component: <MapDraw size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Courts',
    selectedModal: 'courts',
    component: <Court size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Pricing',
    selectedModal: 'pricing',
    component: <Dollar size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Multipliers',
    selectedModal: 'multipliers',
    component: <Error size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Tiebreaker',
    selectedModal: 'tiebreakers',
    color: COLORS.grey50,
    component: <LinkBreak size={28} />,
  },
];
