import React, { FC, useState, useEffect } from 'react';
import { CloseSmall } from '@icon-park/react';

import { BodyM } from 'styles/v3/variables';

import ClickOutsideHandle from '../ClickOutsideHandle/ClickOutsideHandle';

import * as S from './styles';

import colors from './colors.json';

interface IColorPickerProps {
  onSelectColor: (color: string) => void;
  selectedColor?: string;
  className?: string;
  disabled?: boolean;
}

const ColorPicker: FC<IColorPickerProps> = ({
  onSelectColor,
  className,
  selectedColor,
  children,
  disabled,
}) => {
  const [display, setDisplay] = useState<boolean>(false);
  const [localColor, setLocalColor] = useState<string>(selectedColor ?? '');

  useEffect(() => {
    if (localColor) onSelectColor(localColor);
  }, [localColor]);

  useEffect(() => {
    if (selectedColor) setLocalColor(selectedColor);
  }, [selectedColor]);

  return (
    <ClickOutsideHandle
      className={className}
      onClickOutside={() => setDisplay(false)}
    >
      <S.ColorPickeContainer className={disabled ? 'disabled' : ''}>
        <div role='presentation' onClick={() => setDisplay(true)}>
          {children}
        </div>
        <S.ColorPickerWrapper className={display ? 'show' : ''}>
          <S.ColorPickerHeader>
            <BodyM>Select a color</BodyM>
            <CloseSmall size={24} onClick={() => setDisplay(false)} />
          </S.ColorPickerHeader>
          <S.ColorPickerGrid>
            {colors.map((color, index) => (
              <S.ColorElement
                key={`${color}-${index}`}
                color={color}
                className={`color ${color === localColor ? 'active' : ''}`}
                onClick={() => setLocalColor(color)}
              />
            ))}
          </S.ColorPickerGrid>
        </S.ColorPickerWrapper>
      </S.ColorPickeContainer>
    </ClickOutsideHandle>
  );
};

export default ColorPicker;
