import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { RequestWithClubAndUser } from 'models/Request/RequestModel';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import { useGetClubApplications } from 'hooks/v3/requests/useGetClubApplications/useGetClubApplications';

import DateUtil from 'util/DateUtil';

import { Table } from 'components/v3/Table/Table';
import RightDrawer from 'components/v3/RightDrawer';

import { BodyLBold } from 'styles/v3/variables';

import {
  getPermissionType,
  getReadableGender,
} from '../../../MembersPoolTab/components/MembersPoolTable/utils';
import { FilterModalBody } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/FilterModalBody';
import { InvitationFiltersType } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/types';

import { ApproveRejectModal } from './components/Modal/ApproveRejectModal/ApproveRejectModal';
import { Filters } from './components';
import { columns } from './columns';

export const ApplicationsTable = () => {
  const { clubId } = useParams<{ clubId: string }>();

  const { data: currentClub } = useGetClubById(clubId);

  const {
    data: members,
    mutateAsync: fetchClubApplications,
  } = useGetClubApplications();

  const [searchValue, setSearchValue] = useState('');
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [currentIds, setCurrentIds] = useState<string[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [advancedFilter, setAdvancedFilter] = useState<InvitationFiltersType>({
    age: [],
    documentStatus: [],
    gender: [],
    membership: [],
  });

  const fetchBySearch = useCallback(
    debounce((value: string) => {
      fetchClubApplications({
        clubId,
        birthYear: advancedFilter.age,
        gender: advancedFilter.gender,
        documentStatus: advancedFilter.documentStatus,
        membershipType: advancedFilter.membership,
        name: value,
      });
    }, 300),
    [clubId, advancedFilter]
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);

    fetchBySearch(value);
  };

  const membersAdapter = useMemo(() => {
    if (!currentClub || !members?.data?.content.length) {
      return [];
    }

    return members.data.content.map(
      ({ user, club, id }: RequestWithClubAndUser) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        gender: getReadableGender(user.gender),
        age: DateUtil.getYearFromDateString(user.birthDate?.toString()),
        numberOfClubs: user.clubs.length,
        userTypes: [user.type],
        ageStatus: user.ageVerified,
        photoStatus: user.photoVerified,
        safeSportStatus: user.documentationVerified,
        permission: getPermissionType(club, user.id, user.type),
        photo: user.photo,
        openApproveModal: () => handleOpenRequest([id], 'approve'),
        openRejectModal: () => handleOpenRequest([id], 'reject'),
      })
    );
  }, [members, currentClub]);

  const handleOpenRequest = (
    requestIds: string[],
    type: 'approve' | 'reject'
  ) => {
    setCurrentIds(requestIds);

    if (type === 'approve') {
      setIsApproveModalOpen(true);
    } else {
      setIsRejectModalOpen(true);
    }
  };

  const handleOpenFilterModal = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handleApplicationsFetch = useCallback(() => {
    fetchClubApplications({
      clubId,
      birthYear: advancedFilter.age,
      gender: advancedFilter.gender,
      documentStatus: advancedFilter.documentStatus,
      membershipType: advancedFilter.membership,
      name: searchValue,
    });
  }, [clubId, searchValue, advancedFilter]);

  const handleSetAdvancedFilter = (filters: InvitationFiltersType) => {
    setAdvancedFilter(filters);

    fetchClubApplications({
      clubId,
      birthYear: filters.age,
      gender: filters.gender,
      documentStatus: filters.documentStatus,
      membershipType: filters.membership,
      name: searchValue,
    });

    setIsFilterOpen(false);
  };

  useEffect(() => {
    fetchClubApplications({ clubId });
  }, [clubId]);

  return (
    <>
      <Filters
        handleOpenFilterModal={handleOpenFilterModal}
        handleChange={handleSearch}
      />
      <Table columns={columns} dataSource={membersAdapter} />
      <ApproveRejectModal
        type='approve'
        isOpen={isApproveModalOpen}
        requestIds={currentIds}
        setModalState={setIsApproveModalOpen}
        onApplicationFetch={handleApplicationsFetch}
      />
      <ApproveRejectModal
        type='reject'
        isOpen={isRejectModalOpen}
        requestIds={currentIds}
        setModalState={setIsRejectModalOpen}
        onApplicationFetch={handleApplicationsFetch}
      />
      <RightDrawer
        isOpen={isFilterOpen}
        handleCloseModal={handleCloseFilterModal}
        headerComponent={<BodyLBold $color='brandPrimary'>Filters</BodyLBold>}
      >
        <FilterModalBody
          filters={advancedFilter}
          handleChange={handleSetAdvancedFilter}
        />
      </RightDrawer>
    </>
  );
};
