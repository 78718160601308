import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${toRem(16)};
  margin-bottom: ${toRem(12)};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(24)};
`;
