import React, { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { CloseSmall } from '@icon-park/react';

import { useApproveOrRejectRequests } from 'hooks/v3/clubs/useApproveOrRejectRequests/useApproveOrRejectRequests';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { BodyL, BodyLBold } from 'styles/v3/variables';

import * as S from './styles';

interface IApproveRejectModalProps {
  isOpen: boolean;
  requestIds: string[];
  type: 'approve' | 'reject';
  setModalState: Dispatch<SetStateAction<boolean>>;
  onApplicationFetch: () => void;
}

export const ApproveRejectModal = ({
  isOpen,
  requestIds,
  type,
  setModalState,
  onApplicationFetch,
}: IApproveRejectModalProps) => {
  const { clubId } = useParams<{ clubId: string }>();

  const {
    mutateAsync: processRequests,
    isLoading: isRequesting,
  } = useApproveOrRejectRequests([
    ['get-request-by-club-status', clubId],
    ['get-club-members-pool', clubId],
  ]);

  const approveRejectTitle = type === 'approve' ? 'APPROVE' : 'DENY';

  const handleApproveReject = async () => {
    if (isRequesting) return;

    processRequests({
      action: type === 'approve' ? 'APPROVE' : 'REJECT',
      requestIds,
    }).finally(() => {
      setModalState(false);
      onApplicationFetch();
    });
  };

  return (
    <S.StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={() => setModalState(false)}
      onCancel={() => setModalState(false)}
    >
      <S.Container>
        <S.HeaderWrapper>
          {type === 'approve' ? (
            <S.HeaderTitle>
              <BodyL>Are you sure you want to</BodyL>
              <BodyLBold>APPROVE</BodyLBold>
              <BodyL>this player?</BodyL>
            </S.HeaderTitle>
          ) : (
            <S.HeaderTitle>
              <BodyL>Are you sure you want to</BodyL>
              <BodyLBold>DENY</BodyLBold>
              <BodyL>this player?</BodyL>
            </S.HeaderTitle>
          )}
          <S.CloseButton onClick={() => setModalState(false)}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.HeaderWrapper>
        <FilledButton
          disabled={isRequesting}
          isUpper
          onClick={handleApproveReject}
          color={type === 'approve' ? 'primary' : 'support-error'}
        >
          {isRequesting ? 'Processing...' : approveRejectTitle}
        </FilledButton>
      </S.Container>
    </S.StyledModal>
  );
};
