import moment from 'moment';

import { User } from 'models/User/UserModel';
import { PlayerRosterItem } from 'models/Roster/RosterModel';
import UserType from 'models/User/UserTypeModel';
import { RequestStatus } from 'models/Request/RequestModel';

import { NewRoaster } from 'services/v3/Rosters/RosterService';

import { AGE_RULES } from 'hooks/v3/profile/useProfile/configs';

import DateUtil from 'util/DateUtil';

import { IUserRosterError, IRosterErrors } from '../types';

const checkLocalAgePermission = (userType: UserType, birthDate: string) => {
  const currentAge = moment().diff(moment(birthDate, 'YYYY-MM-DD'), 'years');

  return currentAge >= AGE_RULES[userType];
};

const getUnapprovedDocuments = (
  userId: string,
  users: Maybe<User[]>,
) => {
  const member = users?.find((user) => user.id === userId);

  const isAnAdult = DateUtil.isAnAdult(member?.birthDate);

  const unapprovedPhoto = member?.photoVerified !== RequestStatus.APPROVED;
  const unapprovedAge = member?.ageVerified !== RequestStatus.APPROVED;
  const unapprovedSS = member?.documentationVerified !== RequestStatus.APPROVED;

  if (member?.type === UserType.PLAYER && (unapprovedPhoto || unapprovedAge)) {
    return {
      userId: member.id,
      documents: true,
    };
  }

  if (
    member?.type === UserType.COACH &&
    ((unapprovedSS && isAnAdult) || unapprovedPhoto)
  ) {
    return {
      userId: member.id,
      documents: true,
    };
  }

  return null;
};

const getUnofficialMemberships = (userId: string, users: Maybe<User[]>) => {
  const member = users?.find((user) => user.id === userId);

  if (member?.id && member.membership?.type !== 'OFFICIAL') {
    return {
      userId: member.id,
      officialMembership: true,
    };
  }

  return null;
};

const getInvalidBirthDay = (
  userId: string,
  users: Maybe<User[]>
) => {
  const member = users?.find((user) => user.id === userId);

  if (
    member?.birthDate &&
    member.type &&
    !checkLocalAgePermission(member.type, member.birthDate as string)
  ) {
    return {
      userId: member.id,
      ageRule: true,
    };
  }

  return null;
};

const getPlayersWithoutJersey = (users: Maybe<PlayerRosterItem[]>) => {
  return users?.filter((user) => typeof user.number !== 'number');
};

export const validateUser = (
  roster: Maybe<NewRoaster>,
  users: Maybe<User[]>,
): IUserRosterError[] => {
  const errors: IUserRosterError[] = [];

  const members = roster?.coaches.concat(roster.players);

  members?.forEach((member) => {
    const unofficialMember = getUnofficialMemberships(member.id, users);
    const unapprovedDocuments = getUnapprovedDocuments(
      member.id,
      users,
    );
    const invalidBirthDay = getInvalidBirthDay(
      member.id,
      users,
    );

    if (unofficialMember) {
      errors.push(unofficialMember);
    }

    if (unapprovedDocuments) {
      errors.push(unapprovedDocuments);
    }

    if (invalidBirthDay) {
      errors.push(invalidBirthDay);
    }
  });

  const playersWithoutJersey = getPlayersWithoutJersey(roster?.players ?? null);

  playersWithoutJersey?.forEach((player) => {
    errors.push({
      userId: player.id,
      jerseyNumber: true,
    });
  });

  const mergedErrors: IUserRosterError[] = Object.values(
    errors.reduce((acc: any, item) => {
      const currentAcc = acc;

      const { userId, ...rest } = item;

      if (!currentAcc[userId]) {
        currentAcc[userId] = { userId, ...rest };
      } else {
        currentAcc[userId] = { ...acc[userId], ...rest };
      }

      return currentAcc;
    }, {})
  );

  return mergedErrors;
};

export const validateRoster = (roster: Maybe<NewRoaster>): IRosterErrors => {
  const errors: IRosterErrors = {
    coachesLimit: false,
    playersLimit: false,
  };

  const coachesLength = roster?.coaches.length ?? 0;
  const playersLength = roster?.players.length ?? 0;

  const minCoachesNumber = roster?.event?.minimumCoachesNumber ?? 0;
  const maxCoachesNumber = roster?.event?.maximumCoachesNumber ?? 0;

  const minPlayersNumber = roster?.event?.minimumPlayersNumber ?? 0;
  const maxPlayersNumber = roster?.event?.maximumPlayersNumber ?? 0;

  if (minCoachesNumber > coachesLength || maxCoachesNumber < coachesLength) {
    errors.coachesLimit = true;
  }

  if (minPlayersNumber > playersLength || maxPlayersNumber < playersLength) {
    errors.playersLimit = true;
  }

  return errors;
};
