import { Col, DatePicker, message, Row, Select, Upload } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Action, Location } from 'history';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import pdf_icon from '../../assets/imgs/icons/pdf_file.svg';
import EditPhoto from '../../components/base/EditPhoto/EditPhoto';
import LightBox from '../../components/base/LightBox/LightBox';
import LoadingIcon from '../../components/base/LoadingIcon/LoadingIcon';
import Button from '../../components/uielements/Button/Button';
import { countries, states } from '../../models/LocaleList';
import { User } from '../../models/User/UserModel';
import UserType from '../../models/User/UserTypeModel';
import { ApplicationState } from '../../redux/store';
import { userUpdate } from '../../redux/user/actions';
import DownloadService from '../../services/download/DownloadService';
import UploadService from '../../services/upload/UploadService';
import UserServices from '../../services/User/UserApi';
import { color } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';

const { Option } = Select;

const MySettings: React.FunctionComponent = (props) => {
  const [certificateImage, setCertificateImage] = useState<string>('');
  const [ageProofImage, setAgeProofImage] = useState<string>('');
  const [confirmationSaved, setConfirmationSaved] = useState(false);
  const [editedImg, setEditedImg] = useState('');
  const [editPhotoOpen, setEditPhotoOpen] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);

  const [formNotSaved, setFormNotSaved] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState({
    boxVisible: false,
    locationNext: '',
  });
  const [documents, setDocuments] = useState<{
    photo?: string;
    ageProof?: string;
    certificate?: string;
  }>({});

  const { register, setValue, getValues, control } = useForm<User>();
  const user = useSelector((state: ApplicationState) => state.currentUser).data;
  const dispatch = useDispatch();
  const history = useHistory();

  const onFormChange = () => {
    // Some input was changed
    setFormNotSaved(true);
  };

  const handleBlockedNavigation = (nextLocation: Location, action: Action) => {
    if (formNotSaved) {
      setConfirmLeave({
        boxVisible: true,
        locationNext: nextLocation.pathname,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!formNotSaved && confirmLeave.boxVisible) {
      history.push(confirmLeave.locationNext);
    }
  }, [formNotSaved, history, confirmLeave]);

  const saveAndLeave = () => {
    onSubmit();
    setFormNotSaved(false);
  };

  function onSubmit() {
    const data = getValues();

    dispatch(
      userUpdate({
        ...user as User,
        ...(data.address && {
          address: {
            city: data.address?.city ? data.address?.city : '',
            country: data.address?.country ? data.address?.country : '',
            line1: data.address?.line1 ? data.address?.line1 : '',
            line2: data.address?.line2 ? data.address?.line2 : '',
            state: data.address?.state ? data.address?.state : '',
            zipCode: data.address?.zipCode ? data.address?.zipCode : '',
          },
        }),
        ...(data.guardian1 && {
          guardian1: {
            address: {
              city: data.guardian1?.address?.city
                ? data.guardian1.address.city
                : '',
              country: data.guardian1?.address?.country
                ? data.guardian1.address.country
                : '',
              line1: data.guardian1?.address?.line1
                ? data.guardian1.address.line1
                : '',
              line2: data.guardian1?.address?.line2
                ? data.guardian1.address.line2
                : '',
              state: data.guardian1?.address?.state
                ? data.guardian1.address.state
                : '',
              zipCode: data.guardian1?.address?.zipCode
                ? data.guardian1.address.zipCode
                : '',
            },
          },
        }),
        ...(data.guardian2 && {
          guardian2: {
            address: {
              city: data.guardian2?.address?.city
                ? data.guardian2.address.city
                : '',
              country: data.guardian2?.address?.country
                ? data.guardian2.address.country
                : '',
              line1: data.guardian2?.address?.line1
                ? data.guardian2.address.line1
                : '',
              line2: data.guardian2?.address?.line2
                ? data.guardian2.address.line2
                : '',
              state: data.guardian2?.address?.state
                ? data.guardian2.address.state
                : '',
              zipCode: data.guardian2?.address?.zipCode
                ? data.guardian2.address.zipCode
                : '',
            },
          },
        }),
        ...(data.firstName && {
          firstName: data.firstName,
        }),
        ...(data.lastName && {
          lastName: data.lastName,
        }),
        ...(data.middleName && {
          middleName: data.middleName,
        }),
        ...(data.suffix && {
          suffix: data.suffix,
        }),
        phoneNumber: data.phoneNumber,
        email: data.email,
        photo: editedImg || (documents.photo ? documents.photo : user.photo),
        ...(documents.ageProof && {
          ageProof: documents.ageProof,
        }),
        birthDate: (data.birthDate as moment.Moment)?.format('YYYY-MM-DD'),
        extraInfo: {
          ...user.extraInfo,
          certificate: documents.certificate
            ? documents.certificate
            : user.extraInfo?.certificate,
        },
      })
    );
    setConfirmationSaved(true);
    setFormNotSaved(false);
  }

  useEffect(() => {
    if (user.id) {
      UserServices.getUserDocuments(user.id).then((res) => {
        if (res.success) {
          fetch(ConvertUtil.getPrivateMinioUrl(res.data.ageProof))
            .then((response) => response.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setAgeProofImage(pdf_icon);
              } else {
                setAgeProofImage(
                  ConvertUtil.getPrivateMinioUrl(res.data.ageProof)
                );
              }
            });

          fetch(ConvertUtil.getPrivateMinioUrl(res.data.certificate))
            .then((response) => response.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setCertificateImage(pdf_icon);
              } else {
                setCertificateImage(
                  ConvertUtil.getPrivateMinioUrl(res.data.certificate)
                );
              }
            });
        }
      });
    }
    // Manually setting initial values for Controlled Components
    setValue('address.country', user.address?.country);
    setValue('address.state', user.address?.state);
    setValue('gender', user.gender);

    setValue('guardian1.address.country', user.guardian1?.address?.country);
    setValue('guardian1.address.state', user.guardian1?.address?.state);

    setValue('guardian2.address.country', user.guardian2?.address?.country);
    setValue('guardian2.address.state', user.guardian2?.address?.state);
  }, [user, setValue]);

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('File must smaller than 20MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadAgeProof = (event: any) => {
    upload(event, 'ageProof');
  };
  const uploadPhoto = (event: any) => {
    upload(event, 'photo');
  };
  const uploadCertificate = (event: any) => {
    upload(event, 'certificate');
  };

  const uploadEdited = (file: File) => {
    const type = 'photo';
    setIsLoadingImg(true);
    UploadService.upload({
      file,
      prefix: 'user',
      public: type === 'photo',
    }).then((response) => {
      if (!response.success) {
        return;
      }
      const url = response.data.id;

      if (type === 'photo') {
        setEditedImg(url);
      }
      setIsLoadingImg(false);
    });
  };

  const upload = (event: any, type: string) => {
    setIsLoadingImg(true);
    UploadService.upload({
      file: event.file,
      prefix: 'user',
      public: type === 'photo',
    }).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }
      const url = response.data.id;

      if (type === 'ageProof') {
        setDocuments({ ...documents, ageProof: url });
        DownloadService.download(url).then((res) => {
          if (res.success) {
            // Verify if file is an IMAGE or PDF to correctly show icon on preview
            fetch(ConvertUtil.getPrivateMinioUrl(res.data))
              .then((blobResp) => blobResp.blob())
              .then((blob) => {
                if (blob.type === 'application/pdf') {
                  setAgeProofImage(pdf_icon);
                } else {
                  setAgeProofImage(ConvertUtil.getPrivateMinioUrl(res.data));
                }
              });
          }
        });
      }
      if (type === 'photo') {
        setEditedImg(''); // Clear Edited img
        setDocuments({ ...documents, photo: url });
        setEditPhotoOpen(true);
      }
      if (type === 'certificate') {
        setDocuments({ ...documents, certificate: url });
        DownloadService.download(url).then((res) => {
          if (res.success) {
            // Verify if file is an IMAGE or PDF to correctly show icon on preview
            fetch(ConvertUtil.getPrivateMinioUrl(res.data))
              .then((blobResp) => blobResp.blob())
              .then((blob) => {
                if (blob.type === 'application/pdf') {
                  setCertificateImage(pdf_icon);
                } else {
                  setCertificateImage(ConvertUtil.getPrivateMinioUrl(res.data));
                }
              });
          }
        });
      }
      setIsLoadingImg(false);
    });
  };

  const copyIdToClipboard = () => {
    const el = document.createElement('textarea');

    el.value = user.id ?? '';
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.info({
      content: 'Account Number copied to your clipboard',
      duration: 3,
    });
  };

  return (
    <div style={{ margin: '20px' }}>
      <h3>MY SETTINGS</h3>
      <BoxWrapper>
        <AccountNumberBox
          className=''
          style={{ fontSize: '16px', margin: '10px', cursor: 'pointer' }}
          onClick={copyIdToClipboard}
        >
          My User Account Number: <b>{user.id}</b>{' '}
          <span
            style={{ marginLeft: '10px' }}
            className='icon clear icon-copy'
          />
        </AccountNumberBox>
        <Row gutter={16}>
          <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
            <div className='form-group'>
              <label htmlFor='firstName'>
                First Name
                <div>
                  <span className='icon icon-user' />
                  {user.firstName && (
                    <input
                      disabled={user.ageVerified !== 'DECLINED'}
                      id='firstName'
                      name='firstName'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.firstName}
                    />
                  )}
                </div>
              </label>
            </div>
          </Col>
          <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
            <div className='form-group'>
              <label htmlFor='middleName'>
                Middle Name
                <div>
                  <span className='icon icon-user' />
                  <input
                    disabled={user.ageVerified !== 'DECLINED'}
                    id='middleName'
                    name='middleName'
                    ref={register({ required: true })}
                    onChange={onFormChange}
                    className='icon'
                    type='text'
                    defaultValue={user.middleName}
                  />
                  {/* <ErrorValidationBox>
                  {errors.gender && 'Gender is required'}
                </ErrorValidationBox> */}
                </div>
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
            <div className='form-group'>
              <label htmlFor='lastName'>
                Last Name
                <div>
                  <span className='icon icon-user' />
                  {user.lastName && (
                    <input
                      disabled={user.ageVerified !== 'DECLINED'}
                      id='lastName'
                      name='lastName'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.lastName}
                    />
                  )}
                </div>
              </label>
            </div>
          </Col>
          <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
            <div className='form-group'>
              <label htmlFor='suffix'>
                Suffix
                <div>
                  <span className='icon icon-user' />
                  <input
                    disabled={user.ageVerified !== 'DECLINED'}
                    id='suffix'
                    name='suffix'
                    ref={register({ required: true })}
                    onChange={onFormChange}
                    className='icon'
                    type='text'
                    defaultValue={user.suffix}
                  />
                  {/* <ErrorValidationBox>
                  {errors.gender && 'Gender is required'}
                </ErrorValidationBox> */}
                </div>
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
            <div className='form-group'>
              <label htmlFor='birthDate'>
                Birth Date
                <div>
                  <span className='icon icon-user' />
                  {user.birthDate && (
                    <Controller
                      render={(properties) => (
                        <DatePicker
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...properties}
                          disabled={user.ageVerified !== 'DECLINED'}
                          onChange={(e) => {
                            properties.onChange(e);
                            onFormChange();
                          }}
                          format='YYYY/DD/MM'
                          className='icon'
                        />
                      )}
                      id='birthDate'
                      name='birthDate'
                      control={control}
                      defaultValue={typeof user?.birthDate === 'string' ? moment(user.birthDate) : ''}
                    />
                  )}
                </div>
              </label>
            </div>
          </Col>
          <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
            <div className='form-group'>
              <label htmlFor='gender'>
                Gender {user?.gender}
                <div>
                  <Controller
                    onChange={onFormChange}
                    id='gender'
                    name='gender'
                    rules={{ required: true }}
                    control={control}
                    defaultValue={user?.gender}
                    render={(properties) => (
                      <Select
                        {...properties}
                        disabled={user.ageVerified !== 'DECLINED'}
                        onChange={(e) => {
                          properties.onChange(e);
                          onFormChange();
                        }}
                      >
                        <Option key='MALE' value='MALE'>
                          Male
                        </Option>
                        <Option key='FEMALE' value='FEMALE'>
                          Female
                        </Option>
                        <Option key='OTHER' value='OTHER'>
                          Other
                        </Option>
                      </Select>
                    )}
                  />
                  {/* <ErrorValidationBox>
                  {errors.gender && 'Gender is required'}
                </ErrorValidationBox> */}
                </div>
              </label>
            </div>
          </Col>
        </Row>
        {!user.guardian1 && (
          <Row gutter={16}>
            <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
              <div className='form-group'>
                <label htmlFor='address.line1'>
                  Address Line 1
                  <div>
                    <span className='icon icon-house' />
                    <input
                      id='address.line1'
                      name='address.line1'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.address?.line1}
                    />
                    {/* <ErrorValidationBox>
                  {errors.firstName && 'First name is required'}
                </ErrorValidationBox> */}
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='address.line2'>
                  Address Line 2
                  <div>
                    <span className='icon icon-house' />
                    <input
                      id='address.line2'
                      name='address.line2'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.address?.line2}
                    />
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <div className='label'>Country</div>
                <div>
                  <span className='icon icon-flag' />

                  <Controller
                    id='address.country'
                    name='address.country'
                    control={control}
                    defaultValue={user.address?.country}
                    onChange={onFormChange}
                    render={(properties) => (
                      <Select
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...properties}
                        onChange={(e) => {
                          properties.onChange(e);
                          onFormChange();
                        }}
                        className='icon'
                        placeholder='Country'
                      >
                        {countries.map((item) => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  />
                </div>
              </div>

              <div className='form-group'>
                <div className='label'>State</div>
                <div>
                  <span className='icon icon-flag' />
                  <Controller
                    id='address.state'
                    name='address.state'
                    control={control}
                    defaultValue={user.address?.state}
                    onChange={onFormChange}
                    render={(properties) => (
                      <Select
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...properties}
                        onChange={(e) => {
                          properties.onChange(e);
                          onFormChange();
                        }}
                        className='icon'
                        placeholder='State'
                      >
                        {states.map((item) => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
              <div className='form-group'>
                <label htmlFor='address.city'>
                  City
                  <div>
                    <span className='icon icon-city' />
                    <input
                      id='address.city'
                      name='address.city'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.address?.city}
                    />
                    {/* <ErrorValidationBox>
                  {errors.firstName && 'First name is required'}
                </ErrorValidationBox> */}
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='address.zipCode'>
                  Zipcode
                  <div>
                    <span className='icon icon-zip' />
                    <input
                      id='address.zipCode'
                      name='address.zipCode'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.address?.zipCode}
                    />
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='phoneNumber'>
                  Phone Number
                  <div>
                    <span className='icon icon-phone' />
                    <input
                      id='phoneNumber'
                      name='phoneNumber'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.phoneNumber}
                    />
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='email'>
                  Email
                  <div>
                    <span className='icon icon-email' />
                    <input
                      id='email'
                      name='email'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.email}
                    />
                  </div>
                </label>
              </div>
            </Col>
          </Row>
        )}

        {user.guardian1 && (
          <Row className='form-group-row'>
            <Col span={24} style={{ padding: '10px 20px 20px 20px' }}>
              <div className='bold text-orange'>
                Guardian&apos;s Information:
              </div>
            </Col>

            <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
              <div className='form-group'>
                <label htmlFor='guardian1.address.line1'>
                  Address Line 1
                  <div>
                    <span className='icon icon-house' />
                    <input
                      id='guardian1.address.line1'
                      name='guardian1.address.line1'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.guardian1.address?.line1}
                    />
                    {/* <ErrorValidationBox>
                  {errors.firstName && 'First name is required'}
                </ErrorValidationBox> */}
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='guardian1.address.line2'>
                  Address Line 2
                  <div>
                    <span className='icon icon-house' />
                    <input
                      id='guardian1.address.line2'
                      name='guardian1.address.line2'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.guardian1.address?.line2}
                    />
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <div className='label'>Country</div>
                <div>
                  <span className='icon icon-flag' />

                  <Controller
                    id='guardian1.address.country'
                    name='guardian1.address.country'
                    control={control}
                    defaultValue={user.guardian1.address?.country}
                    onChange={onFormChange}
                    as={
                      <Select className='icon' placeholder='Country'>
                        {countries.map((item) => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    }
                  />
                </div>
              </div>

              <div className='form-group'>
                <div className='label'>State</div>
                <div>
                  <span className='icon icon-flag' />
                  <Controller
                    id='guardian1.address.state'
                    name='guardian1.address.state'
                    control={control}
                    defaultValue={user.guardian1.address?.state}
                    onChange={onFormChange}
                    as={
                      <Select className='icon' placeholder='State'>
                        {states.map((item) => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    }
                  />
                </div>
              </div>
            </Col>
            <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
              <div className='form-group'>
                <label htmlFor='guardian1.address.city'>
                  City
                  <div>
                    <span className='icon icon-city' />
                    <input
                      id='guardian1.address.city'
                      name='guardian1.address.city'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.guardian1.address?.city}
                    />
                    {/* <ErrorValidationBox>
                  {errors.firstName && 'First name is required'}
                </ErrorValidationBox> */}
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='guardian1.address.zipCode'>
                  Zipcode
                  <div>
                    <span className='icon icon-zip' />
                    <input
                      id='guardian1.address.zipCode'
                      name='guardian1.address.zipCode'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.guardian1.address?.zipCode}
                    />
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='guardian1.phoneNumber'>
                  Phone Number
                  <div>
                    <span className='icon icon-phone' />
                    <input
                      is='guardian1.phoneNumber'
                      name='guardian1.phoneNumber'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.guardian1.phoneNumber}
                    />
                  </div>
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor='guardian1.email'>
                  Email
                  <div>
                    <span className='icon icon-email' />
                    <input
                      id='guardian1.email'
                      name='guardian1.email'
                      ref={register({ required: true })}
                      onChange={onFormChange}
                      className='icon'
                      type='text'
                      defaultValue={user.guardian1.email}
                    />
                  </div>
                </label>
              </div>
            </Col>
            {user.guardian2 && (
              <>
                <Col span={24} style={{ padding: '10px 20px 20px 20px' }}>
                  <div className='bold text-orange'>
                    Second Guardian&apos;s Information:
                  </div>
                </Col>

                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='guardian2.address.line1'>
                      Address Line 1
                      <div>
                        <span className='icon icon-house' />
                        <input
                          id='guardian2.address.line1'
                          name='guardian2.address.line1'
                          ref={register({ required: true })}
                          onChange={onFormChange}
                          className='icon'
                          type='text'
                          defaultValue={user.guardian2.address?.line1}
                        />
                        {/* <ErrorValidationBox>
                  {errors.firstName && 'First name is required'}
                </ErrorValidationBox> */}
                      </div>
                    </label>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='guardian2.address.line2'>
                      Address Line 2
                      <div>
                        <span className='icon icon-house' />
                        <input
                          id='guardian2.address.line2'
                          name='guardian2.address.line2'
                          ref={register({ required: true })}
                          onChange={onFormChange}
                          className='icon'
                          type='text'
                          defaultValue={user.guardian2.address?.line2}
                        />
                      </div>
                    </label>
                  </div>

                  <div className='form-group'>
                    <div className='label'>Country</div>
                    <div>
                      <span className='icon icon-flag' />

                      <Controller
                        name='guardian2.address.country'
                        control={control}
                        defaultValue={user.guardian2.address?.country}
                        onChange={onFormChange}
                        as={
                          <Select className='icon' placeholder='Country'>
                            {countries.map((item) => {
                              return (
                                <Option key={item.code} value={item.code}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        }
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <div className='label'>State</div>
                    <div>
                      <span className='icon icon-flag' />
                      <Controller
                        name='guardian2.address.state'
                        control={control}
                        defaultValue={user.guardian2.address?.state}
                        onChange={onFormChange}
                        as={
                          <Select className='icon' placeholder='State'>
                            {states.map((item) => {
                              return (
                                <Option key={item.code} value={item.code}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='guardian2.address.city'>
                      City
                      <div>
                        <span className='icon icon-city' />
                        <input
                          id='guardian2.address.city'
                          name='guardian2.address.city'
                          ref={register({ required: true })}
                          onChange={onFormChange}
                          className='icon'
                          type='text'
                          defaultValue={user.guardian2.address?.city}
                        />
                        {/* <ErrorValidationBox>
                  {errors.firstName && 'First name is required'}
                </ErrorValidationBox> */}
                      </div>
                    </label>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='guardian2.address.zipCode'>
                      Zipcode
                      <div>
                        <span className='icon icon-zip' />
                        <input
                          id='guardian2.address.zipCode'
                          name='guardian2.address.zipCode'
                          ref={register({ required: true })}
                          onChange={onFormChange}
                          className='icon'
                          type='text'
                          defaultValue={user.guardian2.address?.zipCode}
                        />
                      </div>
                    </label>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='guardian2.phoneNumber'>
                      Phone Number
                      <div>
                        <span className='icon icon-phone' />
                        <input
                          id='guardian2.phoneNumber'
                          name='guardian2.phoneNumber'
                          ref={register({ required: true })}
                          onChange={onFormChange}
                          className='icon'
                          type='text'
                          defaultValue={user.guardian2.phoneNumber}
                        />
                      </div>
                    </label>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='guardian2.email'>
                      Email
                      <div>
                        <span className='icon icon-email' />
                        <input
                          id='guardian2.email'
                          name='guardian2.email'
                          ref={register({ required: true })}
                          onChange={onFormChange}
                          className='icon'
                          type='text'
                          defaultValue={user.guardian2.email}
                        />
                      </div>
                    </label>
                  </div>
                </Col>
              </>
            )}
          </Row>
        )}

        <Row>
          <Col span={24} style={{ padding: '0 12px' }}>
            <div
              style={{ margin: '10px 0', color: '#878b93', fontWeight: 500 }}
            >
              Official Documentation
            </div>

            <DocumentsWrapper>
              <div className='box-picture'>
                <div className='title'>Official Picture</div>

                <div className='image'>
                  {isLoadingImg && <LoadingIcon text="Uploading..." />}
                  {!isLoadingImg && (
                    <>
                      <img
                        style={{
                          width: '100%',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                        }}
                        src={`${ConvertUtil.getMinioUrl(
                          documents.photo ? documents.photo : user.photo
                        )}`}
                      />
                      {editedImg && (
                        <div className='image' style={{ position: 'absolute' }}>
                          <img
                            style={{ width: '100%' }}
                            src={`${ConvertUtil.getMinioUrl(editedImg)}`}
                          />
                        </div>
                      )}
                      <div
                        className='edit-image i-icon-edit'
                        onClick={() => setEditPhotoOpen(true)}
                      />

                      <EditPhoto
                        imgURL={`${ConvertUtil.getMinioUrl(
                          documents.photo ? documents.photo : user.photo,
                          1000
                        )}?t=rand`}
                        afterEdit={uploadEdited}
                        open={editPhotoOpen}
                        onClose={() => setEditPhotoOpen(false)}
                      />
                    </>
                  )}
                </div>
                <div className='status'>
                  {user.photoVerified === 'APPROVED' && (
                    <Approved className='i-icon-check-green'>Approved</Approved>
                  )}

                  {user.photoVerified === 'PENDING' && 'Waiting for approval'}
                  {user.photoVerified === 'DECLINED' && 'Rejected'}
                </div>

                <Upload
                  showUploadList={false}
                  accept='image/*'
                  onChange={onFormChange}
                  customRequest={uploadPhoto}
                  beforeUpload={beforeUpload}
                >
                  <Button
                    style={{
                      fontSize: '9px',
                    }}
                  >
                    Change Image
                  </Button>
                </Upload>
              </div>
              {user.type === UserType.PLAYER && (
                <div className='box-picture'>
                  <div className='title'>Your Proof of Age Documentation</div>
                  <div
                    className='image'
                    style={{
                      backgroundImage: `url(${ageProofImage})`,
                    }}
                  />
                  <div className='status'>
                    {user.ageVerified === 'APPROVED' && (
                      <Approved className='i-icon-check-green'>
                        Approved
                      </Approved>
                    )}

                    {user.ageVerified === 'PENDING' && 'Waiting for approval'}

                    {user.ageVerified === 'DECLINED' && 'Rejected'}
                  </div>
                  {user.ageVerified === 'DECLINED' && (
                    <Upload
                      showUploadList={false}
                      accept='image/*,application/pdf'
                      onChange={onFormChange}
                      customRequest={uploadAgeProof}
                      beforeUpload={beforeUpload}
                    >
                      <Button
                        style={{
                          maxWidth: '180px',
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'inherit',
                          margin: '0 auto',
                        }}
                      >
                        Resend your document
                      </Button>
                    </Upload>
                  )}
                </div>
              )}

              {user.type !== UserType.PLAYER && (
                <>
                  <div className='box-picture'>
                    <div className='title'>SafeSport Certificate</div>
                    <div
                      className='image'
                      style={{
                        backgroundImage: `url(${certificateImage})`,
                      }}
                    />
                    <div className='status'>
                      {user.documentationVerified === 'APPROVED' && (
                        <Approved className='i-icon-check-green'>
                          Approved
                        </Approved>
                      )}

                      {user.documentationVerified === 'PENDING' &&
                        'Waiting for approval'}

                      {user.documentationVerified === 'DECLINED' && 'Rejected'}
                    </div>

                    {user.documentationVerified === 'DECLINED' && (
                      <Upload
                        showUploadList={false}
                        accept='image/*,application/pdf'
                        onChange={onFormChange}
                        customRequest={uploadCertificate}
                        beforeUpload={beforeUpload}
                      >
                        <Button
                          style={{
                            maxWidth: '180px',
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            textTransform: 'inherit',
                            margin: '0 auto',
                          }}
                        >
                          Resend your document
                        </Button>
                      </Upload>
                    )}
                  </div>
                </>
              )}
            </DocumentsWrapper>
          </Col>
        </Row>

        <Button onClick={() => onSubmit()}>Save Edits</Button>
      </BoxWrapper>

      <LightBox
        timeout={2000}
        isOpen={confirmationSaved}
        onClose={() => setConfirmationSaved(false)}
      >
        {/* TODO: Handle with dispatch error */}
        <ConfirmBox style={{ display: 'flex' }}>
          <h3>My Settings updated!</h3>
        </ConfirmBox>
      </LightBox>

      <Prompt when={formNotSaved} message={handleBlockedNavigation} />

      <LightBox
        isOpen={confirmLeave.boxVisible}
        onClose={() => setConfirmLeave({ boxVisible: false, locationNext: '' })}
      >
        {confirmLeave.boxVisible && (
          <ConfirmBox style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '18px', marginBottom: '10px' }}>
              You have unsaved changes.
              <br />
              Are you sure want to leave?
            </h2>
            <div>
              <ConfirmBt
                onClick={() => {
                  setFormNotSaved(false);
                }}
              >
                Leave
              </ConfirmBt>
              <ConfirmBt
                onClick={() =>
                  setConfirmLeave({ boxVisible: false, locationNext: '' })
                }
              >
                Cancel
              </ConfirmBt>
              <ConfirmBt className='save-leave' onClick={saveAndLeave}>
                Save changes and leave
              </ConfirmBt>
            </div>
          </ConfirmBox>
        )}
      </LightBox>
    </div>
  );
};

const Approved = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  &::before {
    margin-right: 5px;
    padding-top: 6px;
  }
`;

const BoxWrapper = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: ${color.greyLight};
  width: 100%;
`;

const DocumentsWrapper = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: ${color.greyDark};
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  text-align: center;
  .box-picture {
    padding: 0 20px;
    .title {
      margin: 10px 0;
    }
    .image {
      width: 180px;
      height: 180px;
      border-radius: 10px;
      background-color: #202020;
      position: relative;
      overflow: hidden;
      align-items: center;
      display: flex;
      margin: 0 auto;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .edit-image {
      width: 40px;
      height: 40px;
      padding: 12px;
      border-radius: 20px;
      background-color: #fff;
      position: absolute;
      bottom: 10px;
      right: 10px;
      box-shadow: 0 0 3px 3px #20202038;
    }
    .status {
      color: ${color.silverLight};
      margin: 10px 0;
      font-weight: 600;
    }
  }
`;
const ConfirmBox = styled.div`
  padding: 30px 50px;
  border-radius: 20px;
  background-color: ${color.orange};
  width: 100%;
`;
const ConfirmBt = styled.div`
  display: inline-block;
  font-weight: 600;
  margin: 10px 10px;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  &.save-leave {
    background-color: #fff;
    color: ${color.orange};
  }
  &:hover {
    opacity: 0.8;
  }
`;

const AccountNumberBox = styled.div`
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  /* color: #e5502e; */
  border: solid 1px #e5502e;
  padding: 10px 15px;
  border-radius: 5px;
  opacity: 0.5;
  display: inline-block;
`;

export default MySettings;
